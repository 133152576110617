/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingMeeting: false,
  meetings: [],
  meetingsCount: 0,
  meeting: null,
};

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    meetingLoading(state, action) {
      const draft = state;
      console.log('meetingLoading | action.payload:', action.payload);
      draft.isLoadingMeeting = action.payload.isLoading;
    },
    meetingsSucess: (state, action) => {
      const draft = state;
      console.log('meetingsSucess | action.payload:', action.payload);

      draft.meetings = action.payload.meetings;
      draft.isLoadingMeeting = false;
    },
    changeMeetingsCount: (state, action) => {
      const draft = state;
      console.log('changeMeetingsCount | action.payload:', action.payload);

      draft.meetingsCount = action.payload.count;
    },
    meetingSucess: (state, action) => {
      const draft = state;
      console.log('meetingsSucess | action.payload:', action.payload);

      draft.meeting = action.payload.meeting;
      draft.isLoadingMeeting = false;
    },
  },
});

export default meetingSlice.reducer;
