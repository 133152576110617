/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import HeaderRedirect from '../../components/HeaderRedirect';
import RecordChartBar from '../../components/RecordChartBar';

import { changeMessageWarning } from '../../store/user/actions';
import { RecordsInLastYearByIndicatorFetch } from '../../store/result/actions';
import { IndicatorByIdFetch } from '../../store/indicator/actions';

export default function RecordChart({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
  } = useSelector((state) => state.user);
  const { isLoadingResult, records } = useSelector((state) => state.result);
  const { isLoadingIndicator, indicator } = useSelector((state) => state.indicator);
  const isMatches = useMediaQuery('(min-width:795px)');
  const size = useWindowSize();

  const graphWidth = (size.width) * (isMatches ? 0.5 : 0.82);
  console.log('graphWidth: ', graphWidth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indicatorId } = useParams();

  const currentMonth = ((new Date()).getMonth()) + 1;

  useEffect(() => {
    async function getRecordsInLastYearByIndicator() {
      try {
        dispatch(RecordsInLastYearByIndicatorFetch(token, indicatorId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getIndicatorById() {
      try {
        dispatch(IndicatorByIdFetch(token, indicatorId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    if (indicatorId !== undefined) {
      getRecordsInLastYearByIndicator();
      getIndicatorById();
    }
  }, [history]);

  const redirectToResultPdf = () => {
    const baseUrl = process.env.REACT_APP_API;
    const redirectUrl = `${baseUrl}/results/result_pdf_by_id?indicator_id=${indicatorId}`;
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle="Gráfico Anual">
      <ContainerPaper>
        <HeaderRedirect
          title="Informações do gráfico anual"
          onBack={() => navigate('/results/indicators', { replace: true })}
          onRedirect={redirectToResultPdf}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} pb={3}>
            {indicator !== undefined && indicator !== null
              ? (
                <RecordChartBar
                  indicatorParam={indicator}
                  dataParam={records}
                  chartWith={graphWidth}
                  chartMonth={currentMonth}
                />
              ) : null}
          </Grid>
        </Grid>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingResult || isLoadingIndicator} />
    </MiniDrawer>
  );
}
