/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingIndicator: false,
  indicatorsCount: 0,
  indicators: [],
  indicator: null,
};

export const indicatorSlice = createSlice({
  name: 'indicator',
  initialState,
  reducers: {
    indicatorLoading(state, action) {
      const draft = state;
      // console.log('indicatorLoading | action.payload:', action.payload);
      draft.isLoadingIndicator = action.payload.isLoading;
    },
    changeIndicatorsCount: (state, action) => {
      const draft = state;
      console.log('changeIndicatorsCount | action.payload:', action.payload);

      draft.indicatorsCount = action.payload.count;
    },
    indicatorsSucess: (state, action) => {
      const draft = state;
      // console.log('indicatorsSucess | action.payload:', action.payload);

      draft.indicators = action.payload.indicators;
      draft.isLoadingIndicator = false;
    },
    indicatorSucess: (state, action) => {
      const draft = state;
      // console.log('indicatorSucess | action.payload:', action.payload);

      draft.indicator = action.payload.indicator;
      draft.isLoadingIndicator = false;
    },
  },
});

export default indicatorSlice.reducer;
