/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import calendarDateMask from '../../utils/masks/calendarDateMask';

export default function GoalChips({
  chipsGoal,
  onUpdate,
}) {
  const onGoalDelete = (goalToDelete) => () => {
    console.log('onGoalDelete | goalToDelete: ', goalToDelete);
    const goalsUptaded = chipsGoal.filter((goalCycle) => goalCycle !== goalToDelete);
    onUpdate(goalsUptaded);
  };

  return (
    chipsGoal.length >= 1
      ? (
        <Grid item xs={12}>
          {chipsGoal.map((goalLoop) => {
            const goalStartDate = new Date(goalLoop.start_date);
            const goalEndDate = new Date(goalLoop.end_date);
            const goalStartDateMask = calendarDateMask(goalStartDate.toISOString());
            const goalEndDateMask = calendarDateMask(goalEndDate.toISOString());

            const labelGoal = `Meta: ${goalLoop.goal} Data de Início: ${goalStartDateMask} Data de Termino: ${goalEndDateMask}`;
            return (
              <Chip
                sx={{ mt: 0.7, ml: 1 }}
                label={labelGoal}
                color="primary"
                onDelete={onGoalDelete(goalLoop)}
              />
            );
          })}
        </Grid>
      ) : null
  );
}
