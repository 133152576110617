/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import 'dayjs/locale/pt-br';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ContainerSearch from '../../components/ContainerSearch';
import PickerMonthOutlined from './components/PickerMonthOutlined';
import TableList from '../../components/TableList';
import DecisionItems from './components/DecisionItems';
import ButtonTransparent from '../../components/ButtonTransparent';

import { changeMessageWarning } from '../../store/user/actions';
import {
  DecisionsByPageFetch,
  DecisionBySearchFetch,
  DecisionByDateFetch,
} from '../../store/decision/actions';

export default function Decision({ history }) {
  const { typeWarning, messageWarning, token } = useSelector((state) => state.user);
  const { isLoadingDecision, decisions, decisionsCount } = useSelector((state) => state.decision);

  const [searchText, setSearchText] = useState('');
  const [decisionsPerPage, setDecisionsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [monthSelected, setMonthSelected] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getDecisionsByPage(currentPage) {
    try {
      dispatch(DecisionsByPageFetch(token, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function clearSearchFilter() {
    setSearchText('');
    setPage(0);
  }

  function clearMonthFilter() {
    setMonthSelected(null);
    setPage(0);
  }

  async function getDecisionsBySearch(currentPage) {
    try {
      dispatch(DecisionBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getDecisionsByDate(currentPage, dateStateChosen, dateEndChosen) {
    try {
      dispatch(DecisionByDateFetch(token, dateStateChosen, dateEndChosen, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getDecisionsByPage(page);
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getDecisionsBySearch(newPage);
    } else if (monthSelected) {
      console.log('handleChangePage | newPage, monthSelected: ', newPage, monthSelected);
      getDecisionsByDate(newPage, monthSelected);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getDecisionsByPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setDecisionsPerPage(+event.target.value);
    setPage(0);
  };

  function validateDecisionsSearch() {
    console.log('validateDecisionsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de decisão por descrição';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de decisão por descrição é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function onSearchDecisions() {
    const responsevalidateDecisionsSearch = await validateDecisionsSearch();
    console.log('onSearchDecisions | responsevalidateDecisionsSearch: ', responsevalidateDecisionsSearch);

    if (responsevalidateDecisionsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateDecisionsSearch.name,
        type: 'warning',
      }));
    } else {
      clearMonthFilter();

      getDecisionsBySearch(0);
    }
  }

  const onDecisionsByFilterMonth = (dateSelected) => {
    console.log('onDecisionsByFilterMonth | dateSelected: ', dateSelected);
    clearSearchFilter();
    setMonthSelected(dateSelected);

    const seletedDatetime = new Date(dateSelected);
    const firstDayDateTimeSelected = new Date(seletedDatetime.getFullYear(), seletedDatetime.getMonth(), 1);
    const lastDayDateTimeSelected = new Date(seletedDatetime.getFullYear(), seletedDatetime.getMonth() + 1, 0);
    setPage(0);
    getDecisionsByDate(
      0,
      firstDayDateTimeSelected.toISOString(),
      lastDayDateTimeSelected.toISOString(),
    );
  };

  const onChangeSearchText = (textValue) => {
    if (textValue === '') {
      clearSearchFilter();
      getDecisionsByPage(0);
    }
    setSearchText(textValue);
  };

  const redirectToDecisionsPdf = () => {
    const baseUrl = process.env.REACT_APP_API;

    let decisionsIdQueryUrl = '';
    decisions.forEach((actionCycle, index) => {
      const isMoreParameter = index > 0 ? '&' : '';
      decisionsIdQueryUrl += `${isMoreParameter}decisions_id=${actionCycle.id}`;
    });

    const redirectUrl = `${baseUrl}/decisions/decisions_pdf_by_id?${decisionsIdQueryUrl}`;
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle="Decisões">
      <ContainerPaper>
        <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: 3 }}>
          <ButtonTransparent
            label="RELATÓRIO"
            onAction={redirectToDecisionsPdf}
            icon={<SaveAltIcon />}
          />
        </Box>
        <ContainerSearch
          placeholderSearch="Pesquisar por decisão"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchDecisions()}
        />
        <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: 2 }}>
          <PickerMonthOutlined
            inputTestID="PickerMonthID"
            inputPlaceholder="Filtro por mês"
            value={monthSelected}
            onChangeInput={onDecisionsByFilterMonth}
            isDisable={false}
          />
        </Box>
        <TableList
          tableTestID="TableMemberID"
          tableMessageNoItem="Não há registro de decisão"
          tableCells={['DECISÕES TOMADAS', 'DATA DE REGISTRO', 'RESPONSÁVEL']}
          countItems={decisions.length}
        >
          <DecisionItems
            itemsDecision={decisions}
            onAction={(decisionIdSeleted) => navigate(`/meetings/decisions/${decisionIdSeleted}/edit`, { replace: true })}
          />
        </TableList>
        <TablePagination
          rowsPerPageOptions={[decisionsPerPage]}
          component="div"
          count={decisionsCount}
          rowsPerPage={decisionsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
        onCloseSnack={() => dispatch(changeMessageWarning({ message: '', type: '' }))}
      />
      <BackDropLoad isVisible={isLoadingDecision} />
    </MiniDrawer>
  );
}
