const {
  ON_PRIMARY_COLOR,
  SECONDARY_COLOR,
  ON_SECONDARY_COLOR,
  TERTIARY_COLOR,
} = require('./colors');

const imageLogoAlvoBackground = require('../assets/logo_alvo_background.png');

const themeColors = {
  authenticated: {
    palette: {
      background: { default: TERTIARY_COLOR },
      primary: {
        main: SECONDARY_COLOR,
        contrastText: ON_SECONDARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
        contrastText: ON_SECONDARY_COLOR,
      },
    },
  },
  unauthenticated: {
    palette: {
      background: { default: ON_SECONDARY_COLOR, paper: 'transparent' },
      primary: {
        main: SECONDARY_COLOR,
        contrastText: ON_SECONDARY_COLOR,
      },
      secondary: {
        main: ON_PRIMARY_COLOR,
        contrastText: ON_SECONDARY_COLOR,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundImage: `url(${imageLogoAlvoBackground})`,
            backgroundRepeat: 'no-repeat',
          },
        },
      },
    },
  },
};

export default themeColors;
