/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function NotificationTableList({
  tableTestID,
  tableMessageNoItem,
  countItems,
  children,
}) {
  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label={tableTestID}>
          <TableHead />
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sm={10.25} mb={2}>
        {countItems === 0 || countItems === undefined ? (
          <Typography
            variant="body2"
            fontWeight="bold"
            color="text.secondary"
            align="center"
            sx={{ mt: 3.5 }}
          >
            {tableMessageNoItem}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
