/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useWindowSize } from '@uidotdev/usehooks';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import MiniDrawer from '../../components/MiniDrawer';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import RecordChartBar from '../../components/RecordChartBar';
import PickerYearOutlined from '../../components/PickerYearOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import SelectCheckboxOutlined from '../../components/SelectCheckboxOutlined';
import ButtonTransparent from '../../components/ButtonTransparent';

import themeColors from '../../styles/theme_colors';
import { ON_SECONDARY_COLOR, TERTIARY_COLOR } from '../../styles/colors';

import { changeMessageWarning } from '../../store/user/actions';
import { IndicatorsFetch } from '../../store/indicator/actions';
import {
  StatisticsByIndicatorsFetch,
  StatisticsByDateFetch,
  changeStatistics,
  changeYearSelected,
  changeAreaSelected,
  changeIndicatorsSelected,
} from '../../store/result/actions';

export default function RecordChart({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    area,
  } = useSelector((state) => state.user);
  const {
    isLoadingResult,
    statistics,
    yearSelected,
    areaSelected,
    indicatorsSelected,
  } = useSelector((state) => state.result);
  const { isLoadingIndicator, indicators } = useSelector((state) => state.indicator);
  const { isLoadingArea, areas } = useSelector((state) => state.area);

  const [indicatorsFilteredByArea, setIndicatorsFilteredByArea] = useState([]);
  const [areasByTypeUser, setAreasByTypeUser] = useState([]);

  const dispatch = useDispatch();
  const isMatches = useMediaQuery('(min-width:795px)');
  const isMatch1200 = useMediaQuery('(min-width:1200px)');
  const size = useWindowSize();
  const theme = createTheme(themeColors.authenticated, ptBR);

  const graphWidth = (size.width) * (isMatches ? 0.16 : 0.65);
  const currentMonth = ((new Date()).getMonth()) + 1;

  function validateStatistics() {
    console.log('validateStatistics | indicatorsSelected: ', indicatorsSelected);
    let message = '';

    if (indicatorsSelected === undefined || indicatorsSelected.length === 0) {
      message = 'Selecione no mínimo um indicador';
    }

    return { isInvalid: !!message, message };
  }

  async function getStatisticsByIndicators(indicatorsSelectedId) {
    try {
      console.log('getStatisticsByIndicators | indicatorsSelectedId: ', indicatorsSelectedId);
      const responseValidateStatistics = await validateStatistics();
      console.log('getStatisticsByIndicators | responseValidateStatistics ', responseValidateStatistics);

      if (responseValidateStatistics.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateStatistics.message,
          type: 'warning',
        }));
        dispatch(changeStatistics({ statistics: [] }));
      } else {
        dispatch(StatisticsByIndicatorsFetch(token, indicatorsSelectedId));
      }
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getStatisticsByDate(indicatorsSelectedId, dateSelected) {
    try {
      console.log('getStatisticsByDate | indicatorsSelectedId, dateSelected: ', indicatorsSelectedId, dateSelected);
      const responseValidateStatistics = await validateStatistics();
      console.log('getStatisticsByDate | responseValidateStatistics ', responseValidateStatistics);

      if (responseValidateStatistics.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateStatistics.message,
          type: 'warning',
        }));
      } else {
        dispatch(StatisticsByDateFetch(
          token,
          indicatorsSelectedId,
          dateSelected.toISOString(),
        ));
      }
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getIndicators() {
    try {
      dispatch(IndicatorsFetch(token));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getIndicators();

    if (type === 'admin') {
      const areasOptions = areas.map((areaLoop) => areaLoop.name);
      setAreasByTypeUser(areasOptions);
    } else {
      const areaFound = areas.filter((areaCycle) => areaCycle.name === area);
      const areasOptions = areaFound.map((areaLoop) => areaLoop.name);
      console.log('handleActionsFilterResponsible | areasOptions: ', areasOptions);
      setAreasByTypeUser(areasOptions);
    }
  }, [history]);

  useEffect(() => {
    function searchIndicators() {
      try {
        console.log(
          'searchIndicators | yearSelected, indicatorsSelected: ',
          yearSelected,
          indicatorsSelected,
        );
        const indicatorsNames = indicators.filter(
          (indicatorTwister) => indicatorsSelected.includes(indicatorTwister.name),
        );
        console.log('searchIndicators | indicatorsNames: ', indicatorsNames);
        const indicatorsIds = indicatorsNames.map(
          (indicatorsNameLoop) => indicatorsNameLoop.id,
        );
        console.log('searchIndicators | indicatorsIds: ', indicatorsIds);
        if (yearSelected !== null && yearSelected !== undefined) {
          const seletedDatetime = new Date(yearSelected);

          const yearDateTimeSelected = new Date(
            seletedDatetime.getFullYear(),
            12,
            0,
          );
          console.log('useEffect | yearDateTimeSelected: ', yearDateTimeSelected);
          getStatisticsByDate(indicatorsIds, yearDateTimeSelected);
        } else {
          getStatisticsByIndicators(indicatorsIds);
        }
      } catch (error) {
        console.log('searchIndicators | error: ', error);
      }
    }
    searchIndicators();
  }, [indicatorsSelected]);

  useEffect(() => {
    function fillIndicatorFromArea() {
      try {
        console.log('fillIndicatorFromArea | areaSelected, indicators: ', areaSelected, indicators);
        const indicatorsSelectedFromArea = indicators.filter((indicatorCycle) => indicatorCycle.area.name === areaSelected);
        console.log('fillIndicatorFromArea | indicatorsSelectedFromArea: ', indicatorsSelectedFromArea);
        const indicatorOptions = indicatorsSelectedFromArea.map(
          (indicatorPhase) => indicatorPhase.name,
        );
        console.log('fillIndicatorFromArea | indicatorOptions: ', indicatorOptions);
        setIndicatorsFilteredByArea(indicatorOptions);
      } catch (error) {
        console.log('fillIndicatorFromArea | error: ', error);
      }
    }
    fillIndicatorFromArea();
  }, [areaSelected]);

  function clearSearch() {
    dispatch(changeYearSelected({ year: null }));
    dispatch(changeAreaSelected({ area: '' }));
    dispatch(changeIndicatorsSelected({ indicators: [] }));
  }

  const onFilterStatisticsByYear = (dateSelected) => {
    try {
      console.log('onFilterStatisticsByYear | dateSelected: ', dateSelected);
      dispatch(changeYearSelected({ year: dateSelected }));
      const seletedDatetime = new Date(dateSelected);

      const yearDateTimeSelected = new Date(
        seletedDatetime.getFullYear(),
        12,
        0,
      );
      console.log('onFilterStatisticsByYear | yearDateTimeSelected: ', yearDateTimeSelected);

      const indicatorsNames = indicators.filter(
        (indicatorTwister) => indicatorsSelected.includes(indicatorTwister.name),
      );
      console.log('onFilterStatisticsByYear | indicatorsNames: ', indicatorsNames);
      const indicatorsIds = indicatorsNames.map(
        (indicatorsNameLoop) => indicatorsNameLoop.id,
      );
      console.log('onFilterStatisticsByYear | indicatorsIds: ', indicatorsIds);

      getStatisticsByDate(indicatorsIds, yearDateTimeSelected);
    } catch (error) {
      console.log('onFilterStatisticsByYear | error: ', error);
    }
  };

  const onChangeIndicators = (optionValue) => {
    console.log('onChangeIndicators | optionValue: ', optionValue);
    const indicatorsSelectedUpdated = typeof optionValue === 'string' ? optionValue.split(',') : optionValue;
    console.log('onChangeIndicators | indicatorsSelectedUpdated: ', indicatorsSelectedUpdated);
    dispatch(changeIndicatorsSelected({
      indicators: indicatorsSelectedUpdated,
    }));
  };

  const redirectToStaticPdf = (pendencyID) => {
    const baseUrl = process.env.REACT_APP_API;
    console.log('redirectToStaticPdf | yearSelected: ', yearSelected);
    const yearSelectedISO = yearSelected !== null ? (new Date(yearSelected)).toISOString() : (new Date()).toISOString();
    console.log('redirectToStaticPdf | yearSelectedISO: ', yearSelectedISO);
    const redirectUrl = `${baseUrl}/results/report_by_indicator?indicator_id=${pendencyID}&date=${yearSelectedISO}`;
    console.log('redirectToStaticPdf | redirectUrl: ', redirectUrl);
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          fixed
          component="main"
          maxWidth={false}
          sx={{ mb: 4, mt: 11 }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box sx={isMatches
              ? { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }
              : {
                display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 270,
              }}
            >
              <Box sx={{ width: isMatches ? '25%' : '100%' }}>
                <SelectOutlined
                  selectTestID="SelectAreaID"
                  selectPlaceholder="Área"
                  selectPlaceholderNoItem="Não há área"
                  selectValue={areaSelected}
                  selectOptions={areasByTypeUser}
                  onChangeSelect={(optionValue) => dispatch(changeAreaSelected({ area: optionValue }))}
                  isDisable={false}
                />
              </Box>
              <Box sx={{ width: isMatches ? '35%' : '100%' }}>
                <SelectCheckboxOutlined
                  selectTestID="SelectIndicatorID"
                  selectPlaceholder="Seleção de indicadores"
                  selectPlaceholderNoItem="Não há indicador"
                  selectValue={indicatorsSelected !== undefined ? indicatorsSelected : []}
                  selectOptions={indicatorsFilteredByArea}
                  onChangeSelect={onChangeIndicators}
                  isDisable={false}
                />
              </Box>
              <PickerYearOutlined
                inputTestID="PickerYearID"
                inputPlaceholder="Filtro por ano"
                inputValue={yearSelected !== undefined ? dayjs(yearSelected) : dayjs(new Date())}
                onChangeInput={onFilterStatisticsByYear}
                isDisable={false}
              />
              <Button
                variant="outlined"
                size="large"
                sx={{ height: 54.5 }}
                onClick={() => clearSearch()}
              >
                LIMPAR FILTRO
              </Button>
            </Box>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              my: { xs: 3, md: 6 },
              p: { xs: 2, md: 0.5 },
              backgroundColor: TERTIARY_COLOR,
            }}
          >
            {statistics !== undefined ? (
              <Grid item xs={12} sm={10.25}>
                {statistics.length === 0 ? (
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    align="center"
                  >
                    Não há registro de estatística
                  </Typography>
                ) : null}
              </Grid>
            ) : null}

            <Grid container spacing={3}>
              {statistics !== undefined ? (statistics.map((statisticLoop) => (
                <Grid item xs={12} sm={isMatch1200 ? 3.99 : 2.99}>
                  <Card
                    elevation={2}
                    sx={{
                      borderRadius: '12px',
                      backgroundColor: '#FFF',
                    }}
                    style={{ backgroundColor: ON_SECONDARY_COLOR }}
                  >
                    <Box sx={{ p: 1, mb: -1 }}>
                      <ButtonTransparent
                        label="RELATÓRIO"
                        onAction={() => redirectToStaticPdf(statisticLoop.indicator.id)}
                        icon={<SaveAltIcon />}
                      />
                      <CardContent>
                        <RecordChartBar
                          indicatorParam={statisticLoop.indicator}
                          dataParam={statisticLoop.records}
                          chartWith={graphWidth}
                          chartMonth={yearSelected !== null ? 11 : currentMonth}
                        />
                      </CardContent>
                    </Box>
                  </Card>
                </Grid>
              ))) : null}
            </Grid>
          </Paper>
        </Container>
      </ThemeProvider>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingResult || isLoadingIndicator || isLoadingArea} />
    </MiniDrawer>
  );
}
