/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';

export default function PickerMonthOutlined({
  inputTestID,
  inputPlaceholder,
  inputValue,
  onChangeInput,
  isDisable = true,
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-BR"
    >
      <MobileDatePicker
        id={inputTestID}
        views={['year', 'month']}
        label={inputPlaceholder}
        value={inputValue}
        onChange={(newValue) => onChangeInput(newValue)}
        disabled={isDisable}
        renderInput={(params) => (
          <TextField {...params} fullWidth />
        )}
      />
    </LocalizationProvider>
  );
}
