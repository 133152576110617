/* eslint-disable max-len */
import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { actionSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  actionLoading,
  actionsSucess,
  actionSucess,
  changeActionsCount,
} = actionSlice.actions;

export const ActionsByPageFetch = (tokenAuthorization, page, responsibleID, situation) => async (dispatch) => {
  console.log('ActionsByPageFetch | page: ', page);
  const requestParams = { page };
  if (responsibleID !== '') {
    requestParams.responsible_id = responsibleID;
  }
  if (situation !== '') {
    requestParams.situation = situation;
  }
  const configRequest = {
    method: 'get',
    url: '/actions/actions_by_page',
    params: requestParams,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionsByPageFetch | response.data:', response.data);

    dispatch(changeActionsCount({ count: response.data.count }));
    dispatch(actionsSucess({ actions: response.data.results }));
  } catch (error) {
    console.log('ActionsByPageFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ActionsBySearchFetch = (tokenAuthorization, searchText, page) => async (dispatch) => {
  console.log('ActionsBySearchFetch | tokenAuthorization, searchText, page):', tokenAuthorization, searchText, page);
  const configRequest = {
    method: 'get',
    url: `/actions/actions_by_search?text=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionsBySearchFetch | response.data:', response.data);
    dispatch(changeActionsCount({ count: response.data.count }));
    dispatch(actionsSucess({ actions: response.data.results }));
  } catch (error) {
    console.log('ActionsBySearchFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ActionsByAreaFetch = (tokenAuthorization, area, page) => async (dispatch) => {
  console.log('ActionsByAreaFetch | tokenAuthorization, area, page):', tokenAuthorization, area, page);
  const configRequest = {
    method: 'get',
    url: `/actions/actions_by_area?area_id=${area}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionsByAreaFetch | response.data:', response.data);
    dispatch(changeActionsCount({ count: response.data.count }));
    dispatch(actionsSucess({ actions: response.data.results }));
  } catch (error) {
    console.log('ActionsByAreaFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ActionsByAlphabeticFetch = (tokenAuthorization, sortBy, page) => async (dispatch) => {
  console.log(
    'ActionsByAlphabeticFetch | tokenAuthorization, sortBy, page:',
    tokenAuthorization,
    sortBy,
    page,
  );
  const configRequest = {
    method: 'get',
    url: `/actions/alphabetic_plans?sort_by=${sortBy}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionsByAlphabeticFetch | response.data:', response.data);
    dispatch(changeActionsCount({ count: response.data.count }));
    dispatch(actionsSucess({ actions: response.data.results }));
  } catch (error) {
    console.log('ActionsByAlphabeticFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ActionCreateFetch = (
  tokenAuthorization,
  name,
  responsibleId,
  situation,
  startDate,
  endDates,
  cause,
  reasons,
  causeRoot,
  areaId,
  indicatorId,
  observation,
  files,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'ActionCreateFetch | tokenAuthorization, name, responsibleId, situation, startDate, endDates, cause, areaId, indicatorId, participantsIds, observation, files: ',
    tokenAuthorization,
    name,
    responsibleId,
    situation,
    startDate,
    endDates,
    cause,
    reasons,
    causeRoot,
    areaId,
    indicatorId,
    observation,
    files,
  );

  const data = new FormData();

  data.append('name', name);
  data.append('responsible_id', responsibleId);
  data.append('situation', situation);
  data.append('start_date', startDate);
  data.append('end_dates', endDates);
  data.append('cause', cause);
  data.append('reasons', reasons);
  data.append('cause_root', causeRoot);
  data.append('area_id', areaId);
  data.append('indicator_id', indicatorId);
  data.append('observation', observation);
  files.forEach((fileLoop) => {
    data.append('files', fileLoop);
  });
  console.log('ActionCreateFetch | data', data);

  const configRequest = {
    method: 'post',
    url: '/actions/create_action',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));
    console.log('ActionCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('ActionCreateFetch | response.data:', response.data);
    dispatch(actionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({
      message: response.data.message,
      type: 'success',
    }));
    navigateToPage('/actions', { replace: true });
  } catch (error) {
    console.log('ActionCreateFetch | error:', error);
    dispatch(actionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [404, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const ActionByIdFetch = (tokenAuthorization, actionId) => async (dispatch) => {
  console.log('ActionByIdFetch | tokenAuthorization, actionId:', tokenAuthorization, actionId);
  const configRequest = {
    method: 'get',
    url: `/actions/action_by_id?action_id=${actionId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionByIdFetch | response.data:', response.data);
    dispatch(actionSucess({ action: response.data.action }));
  } catch (error) {
    console.log('ActionByIdFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ActionUpdateByIdFetch = (
  tokenAuthorization,
  actionId,
  name,
  responsibleId,
  situation,
  startDate,
  endDates,
  cause,
  reasons,
  causeRoot,
  areaId,
  indicatorId,
  observation,
  files,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'ActionUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, area, role:',
    tokenAuthorization,
    actionId,
    name,
    responsibleId,
    situation,
    startDate,
    endDates,
    cause,
    reasons,
    causeRoot,
    areaId,
    indicatorId,
    observation,
    files,
    navigateToPage,
  );

  const data = new FormData();

  data.append('action_id', actionId);
  data.append('name', name);
  data.append('responsible_id', responsibleId);
  data.append('situation', situation);
  data.append('start_date', startDate);
  data.append('end_dates', endDates);
  data.append('cause', cause);
  data.append('reasons', reasons);
  data.append('cause_root', causeRoot);
  data.append('area_id', areaId);
  data.append('indicator_id', indicatorId);
  data.append('observation', observation);
  files.forEach((fileLoop) => {
    data.append('files', fileLoop);
  });

  const configRequest = {
    method: 'patch',
    url: '/actions/update_action_by_id',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  console.log('ActionUpdateByIdFetch configRequest.data', configRequest.data);

  try {
    dispatch(actionLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('ActionUpdateByIdFetch | response.data:', response.data);
    dispatch(actionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/actions', { replace: true });
  } catch (error) {
    console.log('ActionUpdateByIdFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [404, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const ActionDeleteByIdFetch = (
  tokenAuthorization,
  actionId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'ActionDeleteByIdFetch | tokenAuthorization, actionId, navigateToPage:',
    tokenAuthorization,
    actionId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/actions/delete_action_by_id',
    data: { action_id: actionId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(actionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ActionDeleteByIdFetch | response.data:', response.data);
    dispatch(actionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/actions', { replace: true });
  } catch (error) {
    console.log('ActionDeleteByIdFetch | error:', error.message);
    dispatch(actionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({
      message,
      type: 'error',
    }));
  }
};
