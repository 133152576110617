import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { notificationSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  notificationLoading,
  notificationsSucess,
  changeNotificationsCount,
} = notificationSlice.actions;

export const IsReadNotificationsFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('IsReadNotificationsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'put',
    url: '/notifications/read_notifications',
    data: { is_read: true },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(notificationLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('IsReadNotificationsFetch | response.data: ', response.data);
    dispatch(notificationLoading({ isLoading: false }));
  } catch (error) {
    console.log('IsReadNotificationsFetch | error: ', error);
    dispatch(notificationLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const NotificationsFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('NotificationsFetch');
  const configRequest = {
    method: 'get',
    url: '/notifications/notifications_by_user',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(notificationLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('NotificationsFetch | response.data:', response.data);

    dispatch(notificationsSucess({ notifications: response.data.notifications }));
  } catch (error) {
    console.log('NotificationsFetch | error:', error.message);
    dispatch(notificationLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const NotificationsByPageFetch = (tokenAuthorization, page) => async (dispatch) => {
  console.log('NotificationsByPageFetch | page: ', page);
  const configRequest = {
    method: 'get',
    url: `/notifications/notifications_by_page?page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(notificationLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('NotificationsByPageFetch | response.data:', response.data);

    dispatch(changeNotificationsCount({ count: response.data.count }));
    dispatch(notificationsSucess({ notifications: response.data.results }));
  } catch (error) {
    console.log('NotificationsByPageFetch | error:', error.message);
    dispatch(notificationLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const NotificationBySearchFetch = (
  tokenAuthorization,
  searchText,
  page,
) => async (dispatch) => {
  console.log('NotificationBySearchFetch | tokenAuthorization, searchText, page):', tokenAuthorization, searchText, page);
  const configRequest = {
    method: 'get',
    url: `/notifications/notifications_by_search?text=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(notificationLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('NotificationBySearchFetch | response.data:', response.data);
    dispatch(changeNotificationsCount({ count: response.data.count }));
    dispatch(notificationsSucess({ notifications: response.data.results }));
  } catch (error) {
    console.log('NotificationBySearchFetch | error:', error.message);
    dispatch(notificationLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};
