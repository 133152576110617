/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MuiDrawer from '@mui/material/Drawer';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupsIcon from '@mui/icons-material/Groups';
import FlagIcon from '@mui/icons-material/Flag';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ForumIcon from '@mui/icons-material/Forum';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MenuIcon from '@mui/icons-material/Menu';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { styled, useTheme } from '@mui/material/styles';

import { SECONDARY_COLOR } from '../../../../styles/colors';

const imageAlvoLogoWhite = require('../../../../assets/alvo_logo_white.png');
const imageAlvoLogomarcaWhite = require('../../../../assets/alvo_logomarca_white.png');

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function CollapseIcon({ isVisible, quantityPages }) {
  if (quantityPages === 0) {
    return null;
  }

  if (quantityPages > 1 && isVisible) {
    return (
      <ListItemIcon sx={{ color: '#fff' }}>
        <ExpandLess />
      </ListItemIcon>
    );
  }

  if (quantityPages > 1 && !isVisible) {
    return (
      <ListItemIcon sx={{ color: '#fff' }}>
        <ExpandMore />
      </ListItemIcon>
    );
  }

  return null;
}

function DrawerListItem({
  currentIdMenu,
  isVisibleBar,
  item,
  redirectToPage,
  onOpen,
}) {
  const [isVisibleSubItem, setIsVisibleSubItem] = useState(false);
  const quantitySubPages = (item.subPages).length;

  useEffect(() => {
    if (!isVisibleBar) {
      setIsVisibleSubItem(false);
    }
  }, [isVisibleBar]);

  useEffect(() => {
    if (currentIdMenu !== item.id) {
      setIsVisibleSubItem(false);
    }
  }, [currentIdMenu]);

  function openCollapse() {
    setIsVisibleSubItem(!isVisibleSubItem);
    onOpen();
  }

  function redirectRoute(urlRoute) {
    if (urlRoute !== null) {
      redirectToPage(urlRoute, { replace: true });
    }

    return null;
  }

  return (
    <>
      <ListItemButton
        onClick={() => (quantitySubPages > 0
          ? openCollapse()
          : redirectRoute(item.url))}
        sx={{
          minHeight: 48,
          justifyContent: isVisibleBar ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            color: '#fff',
            minWidth: 0,
            mr: isVisibleBar ? 3 : 'auto',
            justifyContent: 'center',
          }}
          size="sm"
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          sx={{ opacity: isVisibleBar ? 1 : 0 }}
          primaryTypographyProps={{
            color: '#fff',
          }}
        />
        {isVisibleBar ? (
          <CollapseIcon isVisible={isVisibleSubItem} quantityPages={quantitySubPages} />
        ) : null}
      </ListItemButton>
      {quantitySubPages > 0
        ? (
          <Collapse in={isVisibleSubItem} timeout="auto" unmountOnExit>
            {(item.subPages).map((subPage) => (
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => redirectRoute(subPage.url)}
                >
                  <ListItemText
                    primary={subPage.name}
                    sx={{ pl: 5 }}
                    primaryTypographyProps={{
                      color: '#fff',
                    }}
                  />
                </ListItemButton>
              </List>
            ))}
          </Collapse>
        ) : null }
    </>
  );
}

export default function DrawerList({
  isVisible,
  onUpdateVisible,
}) {
  const { type } = useSelector((state) => state.user);
  const [idMenuOpened, setIdMenuOpened] = useState(0);

  const navigate = useNavigate();

  const theme = useTheme();

  const pagesMenu = [
    {
      id: 1,
      name: 'Estatísticas',
      icon: <AssessmentIcon />,
      url: '/statistic/chart',
      isVisible: type === 'manager' || type === 'admin',
      subPages: [],
    },
    {
      id: 2,
      name: 'Reunião',
      icon: <ForumIcon />,
      url: null,
      isVisible: true,
      subPages: [
        {
          name: 'Reuniões',
          url: '/meetings',
        },
        {
          name: 'Decisões Tomadas',
          url: '/meetings/decisions',
        },
      ],
    },
    {
      id: 3,
      name: 'Resultado',
      icon: <FlagIcon />,
      url: null,
      isVisible: type === 'manager' || type === 'admin',
      subPages: [
        {
          name: 'Resultados',
          url: '/results/indicators',
        },
        {
          name: 'Criar Registro',
          url: '/results/create',
        },
      ],
    },
    {
      id: 3,
      name: 'Indicadores',
      icon: <TrackChangesIcon />,
      url: '/indicators',
      isVisible: true,
      subPages: [],
    },
    {
      id: 4,
      name: 'Plano de Ação',
      icon: <FactCheckIcon />,
      url: '/actions',
      isVisible: true,
      subPages: [],
    },
    {
      id: 5,
      name: 'Pendência',
      icon: <ErrorIcon />,
      url: '/pendencies',
      isVisible: true,
      subPages: [],
    },
    {
      id: 7,
      name: 'Equipe',
      icon: <GroupsIcon />,
      url: '/members',
      isVisible: type === 'manager' || type === 'admin',
      subPages: [],
    },
    {
      id: 6,
      name: 'Empresa',
      icon: <HomeRepairServiceIcon />,
      url: '/areas',
      isVisible: type === 'manager' || type === 'admin',
      subPages: [],
    },
  ];

  return (
    <Drawer
      variant="permanent"
      open={isVisible}
      PaperProps={{ sx: { bgcolor: SECONDARY_COLOR } }}
    >
      <DrawerHeader>
        <IconButton onClick={() => onUpdateVisible(false)}>
          {theme.direction !== 'rtl' ? <MenuIcon sx={{ color: '#fff' }} /> : null}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isVisible ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          {!isVisible ? (
            <CardMedia
              component="img"
              image={imageAlvoLogoWhite}
              alt="green iguana"
              sx={{
                height: 32,
                width: '155%',
                // backgroundColor: 'pink',
                margin: -0.5,
              }}
            />
          ) : (
            <CardMedia
              component="img"
              height="83"
              image={imageAlvoLogomarcaWhite}
              alt="green iguana"
            />
          )}
        </ListItemButton>
        {pagesMenu.map(
          (pageCycle) => (
            pageCycle.isVisible ? (
              <DrawerListItem
                currentIdMenu={idMenuOpened}
                isVisibleBar={isVisible}
                item={pageCycle}
                redirectToPage={navigate}
                onOpen={() => {
                  setIdMenuOpened(pageCycle.id);
                  if (!isVisible) {
                    onUpdateVisible(true);
                  }
                }}
              />
            ) : null),
        )}
      </List>
    </Drawer>
  );
}
