/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import InputSearcRounded from '../InputSearcRounded';
import ButtonContained from '../ButtonContained';

export default function ContainerSearch({
  placeholderSearch,
  valueSearch,
  onChangeSearch,
  onSearch,
}) {
  const isMatches = useMediaQuery('(min-width:900px)');

  return (
    <Box sx={isMatches ? {
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 3,
    } : {
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 3, height: 110,
    }}
    >
      <InputSearcRounded
        inputTestID="TextInputSearchID"
        inputPlaceholder={placeholderSearch}
        inputValue={valueSearch}
        inputWidth={`${isMatches ? 70 : 100}%`}
        onChangeInput={(textValue) => onChangeSearch(textValue)}
      />
      <ButtonContained label="PESQUISAR" onAction={() => onSearch()} />
    </Box>
  );
}
