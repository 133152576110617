/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import ResultItem from '../ResultItem';

export default function ResultItems({ itemsResult }) {
  const navigate = useNavigate();

  return (
    itemsResult !== undefined ? (itemsResult.map(
      (resultLoop) => (
        <ResultItem
          itemResult={resultLoop}
          onAction={() => navigate(`/indicators/${resultLoop.id}/actions/create`)}
        />
      ),
    )) : null
  );
}
