/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function HeaderBack({ title, onBack }) {
  const isMatches = useMediaQuery('(min-width:795px)');

  return (
    <Box
      sx={isMatches ? {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      } : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 3,
      }}
    >
      <Button
        variant="outlined"
        size="medium"
        sx={{ mb: 3, wordVreak: 'break-word' }}
        onClick={() => onBack()}
      >
        VOLTAR
      </Button>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        sx={isMatches ? { ml: -8 } : null}
      >
        {title}
      </Typography>
      <Box />
    </Box>
  );
}
