/* eslint-disable max-len */
import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { decisionSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  decisionLoading,
  decisionsSucess,
  decisionSucess,
  changeDecisionsCount,
} = decisionSlice.actions;

export const DecisionsByPageFetch = (tokenAuthorization, page) => async (dispatch) => {
  console.log('DecisionsByPageFetch | page: ', page);
  const configRequest = {
    method: 'get',
    url: `/decisions/decisions_by_page?page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionsByPageFetch | response.data:', response.data);

    dispatch(changeDecisionsCount({ count: response.data.count }));
    dispatch(decisionsSucess({ decisions: response.data.results }));
  } catch (error) {
    console.log('DecisionsByPageFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const DecisionBySearchFetch = (tokenAuthorization, searchText, page) => async (dispatch) => {
  console.log('DecisionBySearchFetch | tokenAuthorization, searchText, page):', tokenAuthorization, searchText, page);
  const configRequest = {
    method: 'get',
    url: `/decisions/decisions_by_search?text=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionBySearchFetch | response.data:', response.data);
    dispatch(changeDecisionsCount({ count: response.data.count }));
    dispatch(decisionsSucess({ decisions: response.data.results }));
  } catch (error) {
    console.log('DecisionBySearchFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const DecisionByDateFetch = (
  tokenAuthorization,
  dateStart,
  dateEnd,
  page,
) => async (dispatch) => {
  console.log(
    'DecisionByDateFetch | tokenAuthorization, dateStart, dateEnd, page: ',
    tokenAuthorization,
    dateStart,
    dateEnd,
    page,
  );
  const configRequest = {
    method: 'get',
    url: `/decisions/decisions_by_date?date_start=${dateStart}&date_end=${dateEnd}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionBySituationFetch | response.data:', response.data);
    dispatch(changeDecisionsCount({ count: response.data.count }));
    dispatch(decisionsSucess({ decisions: response.data.results }));
  } catch (error) {
    console.log('DecisionBySituationFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const DecisionCreateFetch = (
  tokenAuthorization,
  description,
  responsibleID,
  meetingID,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'DecisionCreateFetch | tokenAuthorization, description, responsibleID, meetingID: ',
    tokenAuthorization,
    description,
    responsibleID,
    meetingID,
  );

  const configRequest = {
    method: 'post',
    url: '/decisions/create_decision',
    data: {
      description,
      responsible_id: responsibleID,
      meeting_id: meetingID,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));
    console.log('DecisionCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('DecisionCreateFetch | response.data:', response.data);
    dispatch(decisionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({
      message: response.data.message,
      type: 'success',
    }));
    if (meetingID === null) {
      navigateToPage('/meetings/decisions', { replace: true });
    }
  } catch (error) {
    console.log('DecisionCreateFetch | error:', error);
    dispatch(decisionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const DecisionByIdFetch = (tokenAuthorization, decisionId) => async (dispatch) => {
  console.log('DecisionByIdFetch | tokenAuthorization, decisionId:', tokenAuthorization, decisionId);
  const configRequest = {
    method: 'get',
    url: `/decisions/decision_by_id?decision_id=${decisionId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionByIdFetch | response.data:', response.data);
    dispatch(decisionSucess({ decision: response.data.decision }));
  } catch (error) {
    console.log('DecisionByIdFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const DecisionUpdateByIdFetch = (
  tokenAuthorization,
  decisionID,
  description,
  responsibleID,
  meetingID,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'DecisionUpdateByIdFetch | tokenAuthorization, decisionID, description, responsibleID, meetingID: ',
    tokenAuthorization,
    decisionID,
    description,
    responsibleID,
    meetingID,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/decisions/update_decision_by_id',
    data: {
      decision_id: decisionID,
      description,
      responsible_id: responsibleID,
      meeting_id: meetingID,
    },
    headers: { Authorization: tokenAuthorization },
  };

  console.log('DecisionUpdateByIdFetch configRequest.data', configRequest.data);

  try {
    dispatch(decisionLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('DecisionUpdateByIdFetch | response.data:', response.data);
    dispatch(decisionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/meetings/decisions', { replace: true });
  } catch (error) {
    console.log('DecisionsUpdateByIdFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const DecisionDeleteByIdFetch = (
  tokenAuthorization,
  decisionId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'DecisionDeleteByIdFetch | tokenAuthorization, decisionId, navigateToPage:',
    tokenAuthorization,
    decisionId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/decisions/delete_decision_by_id',
    data: { decision_id: decisionId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionDeleteByIdFetch | response.data:', response.data);
    dispatch(decisionLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/meetings/decisions', { replace: true });
  } catch (error) {
    console.log('DecisionDeleteByIdFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers,
      );
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const DecisionsByMeetingFetch = (tokenAuthorization, meetingID) => async (dispatch) => {
  console.log('DecisionsByMeetingFetch | meetingID: ', meetingID);
  const configRequest = {
    method: 'get',
    url: `/decisions/decisions_by_meeting?meeting_id=${meetingID}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(decisionLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('DecisionsByMeetingFetch | response.data:', response.data);

    dispatch(decisionsSucess({ decisions: response.data.decisions }));
  } catch (error) {
    console.log('DecisionsByMeetingFetch | error:', error.message);
    dispatch(decisionLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};
