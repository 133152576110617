/* eslint-disable react/prop-types */
import React from 'react';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export default function PickerYearOutlined({
  inputTestID,
  inputPlaceholder,
  inputValue,
  onChangeInput,
  isDisable = true,
}) {
  console.log(
    'PickerYearOutlined | inputTestID, inputPlaceholder, inputValue, isDisable: ',
    inputTestID,
    inputPlaceholder,
    inputValue,
    isDisable,
  );
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-BR"
    >
      <MobileDateTimePicker
        id={inputTestID}
        ampm={false}
        format="MM/YYYY"
        views={['year']}
        label={inputPlaceholder}
        fullWidth
        disabled={isDisable}
        variant="outlined"
        value={inputValue}
        onChange={(newValue) => onChangeInput(newValue)}
      />
    </LocalizationProvider>
  );
}
