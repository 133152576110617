/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import InputOutlined from '../../components/InputOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import InputAvatar from '../../components/InputAvatar';
import ContainerPaper from '../../components/ContainerPaper';
import Header from '../../components/Header';
import ButtonContained from '../../components/ButtonContained';

import emailIsInvalid from '../../utils/validation/emailIsInvalid';

import { AreasFetch, RolesFetch } from '../../store/area/actions';
import {
  ProfileFetch,
  ProfileUpdateFetch,
  changeMessageWarning,
} from '../../store/user/actions';

export default function Profile({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    avatar,
    firstName,
    lastName,
    email,
    role,
    type,
    area,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas, roles } = useSelector((state) => state.area);

  const [avatarUrlBlobUpdate, setAvatarUrlBlobUpdate] = useState(avatar);
  const [avatarUri, setAvatarUri] = useState(null);
  const [firstNameUpdate, setFirstNameUpdate] = useState(firstName);
  const [lastNameUpdate, setLastNameUpdate] = useState(lastName);
  const [emailUpdate, setEmailUpdate] = useState(email);
  const [areaSelectedUpdate, setAreaSelectedUpdate] = useState(area);
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [roleSelectedUpdate, setRoleSelectedUpdate] = useState(role);
  const [rolesFilteredByArea, setRolesFilteredByArea] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getProfile() {
      try {
        dispatch(ProfileFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getAreas() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getRoles() {
      try {
        dispatch(RolesFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getProfile();
    getAreas();
    getRoles();
  }, [history]);

  useEffect(() => {
    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    fillAreasToSelect();
  }, [areas]);

  useEffect(() => {
    function fillInputRoleSelected() {
      try {
        console.log('useEffect | areaSelectedUpdate: ', areaSelectedUpdate);
        const rolesFromArea = roles.filter(
          (roleCycle) => roleCycle.area.name === areaSelectedUpdate,
        );
        console.log('useEffect | rolesFromArea: ', rolesFromArea);
        const rolesOptions = rolesFromArea.map((roleTwister) => roleTwister.name);
        setRolesFilteredByArea(rolesOptions);

        const roleFound = rolesFromArea.find(
          (roleCycle) => roleCycle.name === roleSelectedUpdate,
        );
        console.log('useEffect | roleFound, roleSelectedUpdate: ', roleFound, roleSelectedUpdate);
        const isFromAreaRole = roleFound !== undefined
          ? roleFound.name === roleSelectedUpdate : false;
        console.log('useEffect | isFromAreaRole: ', isFromAreaRole);
        if (!isFromAreaRole) {
          setRoleSelectedUpdate('');
        }
      } catch (error) {
        console.log('fillInputRoleSelected | error: ', error);
      }
    }

    fillInputRoleSelected();
  }, [areaSelectedUpdate]);

  function validateProfileUpdate() {
    console.log(
      'ProfileUpdateFetch | tokenAuthorization, firstNameUpdate, lastNameUpdate, emailUpdate, areaSelectedUpdate, roleSelectedUpdate: ',
      token,
      firstNameUpdate,
      lastNameUpdate,
      emailUpdate,
      areaSelectedUpdate,
      roleSelectedUpdate,
    );
    let message = '';

    if (!areaSelectedUpdate) {
      message = 'Preencha o campo área!';
    } else if (!roleSelectedUpdate) {
      message = 'Preencha o campo cargo!';
    } else if (!firstNameUpdate) {
      message = 'Preencha o campo nome!';
    } else if (firstNameUpdate.length < 3) {
      message = 'Campo nome é inválido!';
    } else if (!lastNameUpdate) {
      message = 'Preencha o campo sobrenome!';
    } else if (lastNameUpdate.length < 3) {
      message = 'Campo sobrenome é inválido!';
    } else if (!emailUpdate) {
      message = 'Preencha o campo e-mail!';
    } else if (emailUpdate && emailIsInvalid(emailUpdate)) {
      message = 'Campo e-mail é inválido!';
    }

    return { isInvalid: !!message, message };
  }

  async function sendProfileUpdate() {
    try {
      const responseValidateProfileUpdate = await validateProfileUpdate();
      console.log('sendProfileUpdate | responseValidateProfileUpdate: ', responseValidateProfileUpdate);

      if (responseValidateProfileUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateProfileUpdate.message,
          type: 'warning',
        }));
      } else {
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelectedUpdate);
        const roleFound = roles.find((roleCycle) => roleCycle.name === roleSelectedUpdate);

        dispatch(ProfileUpdateFetch(
          token,
          firstNameUpdate,
          lastNameUpdate,
          emailUpdate,
          areaFound.id,
          roleFound.id,
          avatarUri,
        ));
      }
    } catch (error) {
      console.log('sendProfileUpdate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  return (
    <MiniDrawer pageTitle="Perfil">
      <ContainerPaper>
        <Header title="Alterar informações" />
        <InputAvatar
          inputTestID="InputAvatarID"
          inputAlt={firstName}
          inputSource={avatarUrlBlobUpdate}
          onChangeInput={(urlBlobValue, uriValue) => {
            setAvatarUrlBlobUpdate(urlBlobValue);
            setAvatarUri(uriValue);
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há áreas"
              selectValue={areaSelectedUpdate}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelectedUpdate(optionValue)}
              isDisable={type !== 'admin'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOutlined
              selectTestID="SelectRoleID"
              selectPlaceholder="Cargo"
              selectPlaceholderNoItem="Não há cargos"
              selectValue={roleSelectedUpdate}
              selectOptions={rolesFilteredByArea}
              onChangeSelect={(optionValue) => setRoleSelectedUpdate(optionValue)}
              isDisable={type !== 'admin'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputFirstNameID"
              inputPlaceholder="Digite aqui seu nome"
              inputValue={firstNameUpdate}
              onChangeInput={(textValue) => setFirstNameUpdate(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputLastNameID"
              inputPlaceholder="Digite aqui seu sobrenome"
              inputValue={lastNameUpdate}
              onChangeInput={(textValue) => setLastNameUpdate(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputEmailID"
              inputPlaceholder="Digite aqui seu e-mail"
              inputValue={emailUpdate}
              onChangeInput={(textValue) => setEmailUpdate(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
          <ButtonContained label="ALTERAR" onAction={() => sendProfileUpdate()} />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingArea} />
    </MiniDrawer>
  );
}
