/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import InputOutlined from '../../components/InputOutlined';
import PickerDateTimeOutlined from '../../components/PickerDateTimeOutlined';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import ButtonContained from '../../components/ButtonContained';

import { MembersFetch, changeMessageWarning } from '../../store/user/actions';
import {
  DecisionCreateFetch,
  DecisionByIdFetch,
  DecisionUpdateByIdFetch,
  DecisionDeleteByIdFetch,
} from '../../store/decision/actions';

export default function DecisionForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingDecision, decision } = useSelector((state) => state.decision);

  const [description, setDescription] = useState('');
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [meetingID, setMeetingID] = useState(null);
  const [typeMeeting, setTypeMeeting] = useState('');
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [membersToSelect, setMembersToSelect] = useState([]);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { decisionId } = useParams();

  useEffect(() => {
    async function getDecisionById() {
      try {
        dispatch(DecisionByIdFetch(token, decisionId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getMembers() {
      try {
        dispatch(MembersFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getMembers();
    console.log('decisionId: ', decisionId);

    if (decisionId !== undefined) {
      getDecisionById();
    }
  }, [history]);

  useEffect(() => {
    function fillInputsDecision() {
      try {
        console.log('fillInputsDecision | decision: ', decision);
        setDescription(decision.description);
        setResponsibleSelected(`${decision.responsible.first_name} ${decision.responsible.last_name}`);
        console.log('fillInputsDecision | decision.meeting: ', decision.meeting);
        if (decision.meeting !== null) {
          setMeetingID(decision.meeting.id);
          setTypeMeeting(decision.meeting.meeting_type);
          setStartDate(dayjs(new Date(decision.meeting.start_date)));
        } else {
          setMeetingID(null);
          setTypeMeeting('');
          setStartDate(dayjs(new Date()));
        }
      } catch (error) {
        console.log('fillInputsDecision | error: ', error);
      }
    }

    console.log('useEffect | decisionId, decision: ', decisionId, decision);
    if (decisionId !== undefined && decision !== null) {
      fillInputsDecision();
    } else {
      setDescription('');
      setResponsibleSelected('');
      setMeetingID(null);
    }
  }, [decision, decisionId]);

  useEffect(() => {
    function fillMembersToSelect() {
      try {
        const membersOptions = members.map((memberTwister) => `${memberTwister.first_name} ${memberTwister.last_name}`);
        setMembersToSelect(membersOptions);
      } catch (error) {
        console.log('fillMembersToSelect | error: ', error);
      }
    }

    if (members !== undefined) {
      fillMembersToSelect();
    }
  }, [members]);

  function validateDecision() {
    console.log(
      'validateDecision | description, responsibleSelected, situationSelected: ',
      description,
      responsibleSelected,
    );
    let message = '';

    if (!description) {
      message = 'Preencha o campo descrição';
    } else if (description.length < 3) {
      message = 'Campo descrição é inválido';
    } else if (!responsibleSelected) {
      message = 'Selecione um responsável';
    }

    return { isInvalid: !!message, message };
  }

  async function sendDecisionCreate() {
    try {
      const responseValidateDecision = await validateDecision();
      console.log('sendDecisionCreate | responseValidateDecision ', responseValidateDecision);

      if (responseValidateDecision.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateDecision.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendDecisionCreate | responsibleFound: ', responsibleFound);

        dispatch(DecisionCreateFetch(
          token,
          description,
          responsibleFound.id,
          null,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendDecisionCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendDecisionUpdateById() {
    try {
      const responseValidateDecisionUpdate = await validateDecision();
      console.log('sendDecisionUpdateById | responseValidateDecisionUpdate: ', responseValidateDecisionUpdate);

      if (responseValidateDecisionUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateDecisionUpdate.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendDecisionCreate | responsibleFound: ', responsibleFound);

        dispatch(DecisionUpdateByIdFetch(
          token,
          decisionId,
          description,
          responsibleFound.id,
          meetingID,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendPendencyUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteDecisionById() {
    try {
      setIsVisibleDialog(false);
      dispatch(DecisionDeleteByIdFetch(token, decisionId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendDecision = () => {
    if (decisionId !== undefined) {
      sendDecisionUpdateById();
    } else {
      sendDecisionCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${decisionId !== undefined ? 'Editar' : 'Criar'} decisão`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações da decisão"
          onBack={() => navigate('/meetings/decisions', { replace: true })}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputDescriptionID"
              inputPlaceholder="Digite aqui a descrição"
              inputValue={description}
              onChangeInput={(textValue) => setDescription(textValue)}
              isSecure={false}
              isDisable={type === 'default_user'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersToSelect}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={type === 'default_user'}
            />
          </Grid>
          {meetingID !== null ? (
            <>
              <Grid item xs={12} sm={6}>
                <InputOutlined
                  inputTestID="TextInputDescriptionID"
                  inputPlaceholder="Tema da reunião"
                  inputValue={typeMeeting}
                  onChangeInput={(textValue) => console.log(textValue)}
                  isSecure={false}
                  isDisable
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PickerDateTimeOutlined
                  inputTestID="PickerDateTimeStartDateID"
                  inputPlaceholder="Data de Inicío"
                  inputValue={startDate}
                  onChangeInput={(textValue) => console.log(textValue)}
                  isDisable
                />
              </Grid>
            </>
          ) : null }
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: decisionId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {decisionId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}
          <ButtonContained
            label={decisionId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendDecision}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingDecision} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteDecisionById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir essa decisão?"
      />
    </MiniDrawer>
  );
}
