/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function RoleItem({ itemRole, onAction }) {
  return (
    <TableRow key={itemRole.id}>
      <TableCell sx={{ width: 20 }}>
        <IconButton
          aria-label={`button edit ${itemRole.name}`}
          size="small"
          onClick={() => onAction()}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row">
        {itemRole.name}
      </TableCell>
    </TableRow>
  );
}
