/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function InputOutlined({
  inputTestID,
  inputPlaceholder,
  inputValue,
  onChangeInput,
  isSecure = true,
  isDisable = true,
}) {
  const [isHidePassword, setIsHidePassword] = useState(isSecure);

  const onChangeValue = ({ currentTarget: { value } }) => {
    onChangeInput(value);
  };

  const handleClickShowPassword = () => setIsHidePassword(!isHidePassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      id={inputTestID}
      name={inputTestID}
      label={inputPlaceholder}
      fullWidth
      disabled={isDisable}
      type={isHidePassword ? 'password' : 'text'}
      variant="outlined"
      value={inputValue}
      onChange={onChangeValue}
      InputProps={isSecure ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {isHidePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      } : null}
    />
  );
}
