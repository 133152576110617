/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Collapse from '@mui/material/Collapse';

import SubLevelTableLine from './components/SublevelTableLine';

export default function TableBodySublevel({ sublevelsParam, isVisible }) {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#f2f2f2' }} colSpan={12}>
        <Collapse in={isVisible} timeout="auto" unmountOnExit>
          <Table aria-label="sublevels">
            <TableBody>
              {sublevelsParam.map((sublevelLoop) => (
                <SubLevelTableLine
                  sublevelParam={sublevelLoop}
                  onAction={() => navigate(`/indicators/${sublevelLoop.id}/sublevel/edit`, { replace: true })}
                />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
