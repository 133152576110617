const { SECONDARY_COLOR, TERTIARY_COLOR } = require('./colors');

const globalStyles = {
  container: { mb: 4, mt: 25 },
  containerUnauthenticated: { mb: 4, mt: 14 },
  containerPaper: { my: { xs: 0, md: 6 }, p: { xs: 2, md: 3 } },
  buttonDefault: {
    mt: 0.7,
    borderRadius: '30px',
  },
  inputSearch: {
    '& input': {
      paddingLeft: (theme) => theme.spacing(3.5),
      backgroundColor: TERTIARY_COLOR,
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
  },
  inputSearchAndorment: {
    padding: '28px 30px 28px 10px',
    marginRight: '-15px',
    marginLeft: '-5px',
    backgroundColor: TERTIARY_COLOR,
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  buttonActions: {
    mt: 3,
    ml: 1,
    borderRadius: '30px',
  },
  containerChip: {
    mt: 0.7,
    ml: 1,
  },
  containerIconTable: {
    fontSize: 14,
    marginLeft: 0.5,
    marginBottom: -0.55,
    color: SECONDARY_COLOR,
  },
};

export default globalStyles;
