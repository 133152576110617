import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import Header from '../../components/Header';
import InputOutlined from '../../components/InputOutlined';
import ContainerPaper from '../../components/ContainerPaper';
import ButtonContained from '../../components/ButtonContained';

import passwordIsInvalid from '../../utils/validation/passwordIsInvalid';

import { RedefinePasswordFetch, changeMessageWarning } from '../../store/user/actions';

export default function RedefinePassword() {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
  } = useSelector((state) => state.user);

  const [password, setPassword] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordNewConfirm, setPasswordNewConfirm] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function validateRedefinePassword() {
    console.log(
      'RedefinePasswordFetch | token, password: ',
      token,
      password,
    );
    let message = '';

    if (!password) {
      message = 'Preencha o campo senha atual!';
    } else if (password.length < 8) {
      message = 'Campo senha atual é inválido!';
    } else if (!passwordNew) {
      message = 'Preencha o campo nova senha!';
    } else if (passwordIsInvalid(passwordNew)) {
      message = 'Campo nova senha deve conter números e letras!';
    } else if (passwordNew.length < 8 && passwordNew.length > 15) {
      message = 'Campo nova senha deve conter de 8 à 15 caracteres!';
    } else if (!passwordNewConfirm) {
      message = 'Preencha o campo confirmar nova senha!';
    } else if (passwordNewConfirm.length < 8) {
      message = 'Campo confirmar nova senha é inválido!';
    } else if (password === passwordNew) {
      message = 'Campos senha atual e nova senha devem ser diferentes!';
    } else if (passwordNew !== passwordNewConfirm) {
      message = 'Os campos nova senha e confirmar nova senha devem ser iguais!';
    }

    return { isInvalid: !!message, message };
  }

  async function sendRedefinePassword() {
    try {
      const responseValidateRedefinePassword = await validateRedefinePassword();
      console.log('sendRedefinePassword | responseValidateRedefinePassword: ', responseValidateRedefinePassword);

      if (responseValidateRedefinePassword.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateRedefinePassword.message,
          type: 'warning',
        }));
      } else {
        console.log(
          'sendRedefinePassword | token, password, passwordNew: ',
          token,
          password,
          passwordNew,
        );
        dispatch(RedefinePasswordFetch(
          token,
          password,
          passwordNew,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendRedefinePassword | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  return (
    <MiniDrawer pageTitle="Redefinir Senha">
      <ContainerPaper>
        <Header title="Informações para redefinir senha" />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputPasswordID"
              inputPlaceholder="Digite sua senha atual"
              inputValue={password}
              onChangeInput={(textValue) => setPassword(textValue)}
              isSecure
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputPasswordNewID"
              inputPlaceholder="Digite sua nova senha"
              inputValue={passwordNew}
              onChangeInput={(textValue) => setPasswordNew(textValue)}
              isSecure
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputPasswordNewConfirmID"
              inputPlaceholder="Digite novamente sua nova senha"
              inputValue={passwordNewConfirm}
              onChangeInput={(textValue) => setPasswordNewConfirm(textValue)}
              isSecure
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
          <ButtonContained label="ALTERAR" onAction={() => sendRedefinePassword()} />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser} />
    </MiniDrawer>
  );
}
