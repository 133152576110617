/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';

import themeColors from '../../styles/theme_colors';
import globalStyles from '../../styles/global_styles';
import styles from './styles';

import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import InputRounded from '../../components/InputRounded';

import { SignInFetch, changeMessageWarning } from '../../store/user/actions';

const imageLogoAlvoAzul = require('../../assets/logo_alvo_azul.png');

export default function SignIn({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = createTheme(themeColors.unauthenticated);

  useEffect(() => {
    function redirectAppScreen() {
      console.log('redirectAppScreen | token: ', token);
      if (token !== '') {
        navigate('/profile', { replace: true });
      }
    }

    redirectAppScreen();
  }, [history]);

  function validateSignIn() {
    let message = '';

    if (!email) {
      message = 'Preencha o campo e-mail';
    } else if (!password) {
      message = 'Preencha o campo senha';
    }

    return { isInvalid: !!message, message };
  }

  async function sendSignIn() {
    try {
      const responseValidateSignIn = await validateSignIn();
      console.log('sendSignIn | responseValidateSignIn: ', responseValidateSignIn);

      if (responseValidateSignIn.isInvalid) {
        dispatch(changeMessageWarning({ message: responseValidateSignIn.message, type: 'warning' }));
      } else {
        console.log('sendSignIn: ', email, password);
        dispatch(SignInFetch(email, password, navigate));
      }
    } catch (error) {
      console.log('sendSignIn | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          component="main"
          maxWidth="sm"
          sx={globalStyles.containerUnauthenticated}
        >
          <Paper variant="elevation" elevation={0} sx={globalStyles.containerPaper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  height="213"
                  sx={{ objectFit: 'contain' }}
                  image={imageLogoAlvoAzul}
                  alt="alvo logo blue"
                />
              </Grid>
              <Grid item xs={12}>
                <InputRounded
                  inputTestID="TextInputEmailID"
                  inputPlaceholder="Digite aqui seu e-mail"
                  inputValue={email}
                  onChangeInput={(textValue) => setEmail(textValue)}
                  isSecure={false}
                />
              </Grid>
              <Grid item xs={12}>
                <InputRounded
                  inputTestID="TextInputPasswordID"
                  inputPlaceholder="Digite aqui a sua senha"
                  inputValue={password}
                  onChangeInput={(textValue) => setPassword(textValue)}
                  isSecure
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="text"
                size="large"
                onClick={() => navigate('/forgot-password', { replace: true })}
                sx={{ mt: 3, ml: 1 }}
              >
                Esqueceu a senha?
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => sendSignIn()}
                sx={styles.buttonSendContainer}
              >
                Entrar
              </Button>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser} />
    </>
  );
}
