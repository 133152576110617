/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ButtonTransparent from '../../components/ButtonTransparent';
import ContainerSearchRedirect from '../../components/ContainerSearchRedirect';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import ActionTableList from './components/ActionTableList';
import SituationChips from './components/SituationChips';

import { changeMessageWarning } from '../../store/user/actions';
import {
  ActionsByPageFetch,
  ActionsBySearchFetch,
  ActionsByAlphabeticFetch,
  ActionsByAreaFetch,
} from '../../store/action/actions';
import ActionItems from './components/ActionItems';
import ButtonOutlined from '../../components/ButtonOutlined';

const conditions = require('../../utils/resources/conditions.json');

export default function Action({ history }) {
  const {
    typeWarning,
    messageWarning,
    token,
    type,
    area,
    members,
  } = useSelector((state) => state.user);
  const { areas } = useSelector((state) => state.area);
  const { isLoadingAction, actions, actionsCount } = useSelector((state) => state.action);

  const nameAlphabetic = [
    '',
    'name',
    '-name',
  ];
  const responsibleAlphabetic = [
    '',
    'responsible',
    '-responsible',
  ];

  const [searchText, setSearchText] = useState('');
  const [actionsPerPage, setActionsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [situationFilterSelected, setSituationFilterSelected] = useState('');
  const [responsibleFilterSelected, setResponsibleFilterSelected] = useState('');
  const [areaFilterSelected, setAreaFilterSelected] = useState('');
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [nameOrderSelected, setNameOrderSelected] = useState('');
  const [responsibleOrderSelected, setResponsibleOrderSelected] = useState('');
  const [membersFilteredByArea, setMembersFilteredByArea] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getActionsByPage(currentPage, responsibleIdChosen, situationChosen) {
    console.log(
      'getActionsByPage | currentPage, responsibleIdChosen, situationChosen: ',
      currentPage,
      responsibleIdChosen,
      situationChosen,
    );
    try {
      dispatch(ActionsByPageFetch(
        token,
        currentPage + 1,
        responsibleIdChosen,
        situationChosen,
      ));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function clearSearchFilter() {
    setSearchText('');
    setPage(0);
  }

  function clearResponsibleFilter() {
    setResponsibleFilterSelected('');
    setPage(0);
  }

  function clearAreaFilter() {
    setAreaFilterSelected('');
    setPage(0);
  }

  function clearSituationFilter() {
    setSituationFilterSelected('');
    setPage(0);
  }

  function clearNameOrder() {
    setNameOrderSelected('');
    setPage(0);
  }

  function clearResponsibleOrder() {
    setResponsibleOrderSelected('');
  }

  async function getActionsBySearch(currentPage) {
    try {
      dispatch(ActionsBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getActionByArea(currentPage, areaChosen) {
    try {
      dispatch(ActionsByAreaFetch(token, areaChosen, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getActionsByAlphabetic(currentPage, sortByChosen) {
    try {
      dispatch(ActionsByAlphabeticFetch(token, sortByChosen, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getActionsByPage(page, '', '');
  }, [history]);

  useEffect(() => {
    function fillMembersFilteredByArea() {
      try {
        if (type === 'manager') {
          const membersFromArea = members.filter(
            (memberCycle) => memberCycle.area.name === area,
          );
          console.log('fillMembersFilteredByArea | membersFromArea: ', membersFromArea);
          const membersOptionsFromArea = membersFromArea.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
          setMembersFilteredByArea(membersOptionsFromArea);
        } else {
          const membersOptionsFromArea = members.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
          setMembersFilteredByArea(membersOptionsFromArea);
        }
      } catch (error) {
        console.log('fillMembersFilteredByArea | error: ', error);
      }
    }

    fillMembersFilteredByArea();
  }, [type]);

  useEffect(() => {
    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    if (areas !== undefined) {
      fillAreasToSelect();
    }
  }, [areas]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getActionsBySearch(newPage);
    } else if (areaFilterSelected) {
      console.log('handleChangePage | newPage, areaFilterSelected: ', newPage, areaFilterSelected);
      getActionByArea(newPage, areaFilterSelected);
    } else if (nameOrderSelected) {
      console.log('handleChangePage | newPage, nameOrderSelected: ', newPage, nameOrderSelected);
      getActionsByAlphabetic(newPage, nameOrderSelected);
    } else if (responsibleOrderSelected) {
      console.log('handleChangePage | newPage, responsibleOrderSelected: ', newPage, responsibleOrderSelected);
      getActionsByAlphabetic(newPage, responsibleOrderSelected);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getActionsByPage(newPage, responsibleFilterSelected, situationFilterSelected);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setActionsPerPage(+event.target.value);
    setPage(0);
  };

  function validateActionsSearch() {
    console.log('validateActionsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de ação por nome';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de ação por nome é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function onSearchActions() {
    const responsevalidateActionsSearch = await validateActionsSearch();
    console.log('onSearchActions | responsevalidateActionsSearch: ', responsevalidateActionsSearch);

    if (responsevalidateActionsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateActionsSearch.name,
        type: 'warning',
      }));
    } else {
      clearAreaFilter();
      clearResponsibleFilter();
      clearSituationFilter();
      clearNameOrder();
      clearResponsibleOrder();
      getActionsBySearch(0);
    }
  }

  async function onFilterActionsSituation(situationSelected) {
    console.log('onFilterActionsSituation | situationSelected: ', situationSelected);
    let situationManipule = '';
    let responsibleManipule = '';
    if (situationFilterSelected !== situationSelected) {
      clearAreaFilter();
      clearSearchFilter();
      clearNameOrder();
      situationManipule = situationSelected;
    }
    if (responsibleFilterSelected !== '') {
      const responsibleFound = members.find((memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleFilterSelected);
      console.log('onFilterActionsSituation | responsibleFound: ', responsibleFound);
      responsibleManipule = responsibleFound.id;
    }
    setSituationFilterSelected(situationManipule);
    getActionsByPage(0, responsibleManipule, situationManipule);
  }

  function changeNameOrder() {
    try {
      const nameAlphabeticCount = nameAlphabetic.length;
      const indexNameOrderSelected = nameAlphabetic.indexOf(nameOrderSelected);
      const indexLastNameAlphabetic = nameAlphabeticCount - 1;

      clearAreaFilter();
      clearResponsibleFilter();
      clearSituationFilter();
      clearSearchFilter();
      clearResponsibleOrder();

      if (indexNameOrderSelected === indexLastNameAlphabetic) {
        setNameOrderSelected(nameAlphabetic[0]);
        getActionsByPage(0, '', '');
      } else {
        setNameOrderSelected(nameAlphabetic[indexNameOrderSelected + 1]);
        getActionsByAlphabetic(0, nameAlphabetic[indexNameOrderSelected + 1]);
      }
    } catch (error) {
      console.log('changeNameOrder | error: ', error);
    }
  }

  function changeResponsibleOrder() {
    try {
      const responsibleAlphabeticCount = responsibleAlphabetic.length;
      const indexResponsibleOrderSelected = responsibleAlphabetic.indexOf(responsibleOrderSelected);
      const indexLastResponsibleAlphabetic = responsibleAlphabeticCount - 1;
      console.log(
        'changeResponsibleOrder | indexResponsibleOrderSelected, indexLastResponsibleAlphabetic: ',
        indexResponsibleOrderSelected,
        indexLastResponsibleAlphabetic,
      );

      clearAreaFilter();
      clearResponsibleFilter();
      clearSituationFilter();
      clearSearchFilter();
      clearNameOrder();

      if (indexResponsibleOrderSelected === indexLastResponsibleAlphabetic) {
        setResponsibleOrderSelected(responsibleAlphabetic[0]);
        getActionsByPage(0, '', '');
      } else {
        setResponsibleOrderSelected(responsibleAlphabetic[indexResponsibleOrderSelected + 1]);
        getActionsByAlphabetic(0, responsibleAlphabetic[indexResponsibleOrderSelected + 1]);
      }
    } catch (error) {
      console.log('changeResponsibleOrder | error: ', error);
    }
  }

  const onFilterActionsByResponsible = (responsibleNameSelected) => {
    console.log('onFilterActionsByResponsible | responsibleNameSelected: ', responsibleNameSelected);
    let responsibleManipule = '';
    clearAreaFilter();
    clearSearchFilter();
    clearNameOrder();
    if (responsibleNameSelected !== '') {
      const responsibleFound = members.find((memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleNameSelected);
      console.log('onFilterActionsSituation | responsibleFound: ', responsibleFound);
      responsibleManipule = responsibleFound.id;
    }
    console.log('onFilterActionsByResponsible | responsibleManipule: ', responsibleManipule);
    setResponsibleFilterSelected(responsibleNameSelected);
    getActionsByPage(0, responsibleManipule, situationFilterSelected);
  };

  const onFilterActionsByArea = (areaNameSelected) => {
    clearResponsibleFilter();
    clearSearchFilter();
    clearSituationFilter();
    clearNameOrder();
    clearResponsibleOrder();
    setAreaFilterSelected(areaNameSelected);
    const areaFound = areas.find((areaCycle) => areaCycle.name === areaNameSelected);
    console.log('onFilterActionsByArea | areaFound: ', areaFound);
    getActionByArea(0, areaFound.id);
  };

  const onChangeSearchText = (textValue) => {
    if (textValue === '') {
      clearSearchFilter();
      getActionsByPage(0, '', '');
    }
    setSearchText(textValue);
  };

  const redirectToActionsPdf = () => {
    const baseUrl = process.env.REACT_APP_API;
    let actionsIdQueryUrl = '';
    actions.forEach((actionCycle, index) => {
      const isMoreParameter = index > 0 ? '&' : '';
      actionsIdQueryUrl += `${isMoreParameter}actions_id=${actionCycle.id}`;
    });

    const redirectUrl = `${baseUrl}/actions/actions_pdf_by_id?${actionsIdQueryUrl}`;
    console.log('redirectToActionsPdf | redirectUrl: ', redirectUrl);
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle="Planos de Ação">
      <ContainerPaper>
        <SituationChips
          chipsSituation={conditions}
          itemsAction={actions}
          chipValue={situationFilterSelected}
          onAction={(chipValue) => onFilterActionsSituation(chipValue)}
        />
        {actions.length >= 1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: 3 }}>
            <ButtonTransparent
              label="RELATÓRIO"
              onAction={redirectToActionsPdf}
              icon={<SaveAltIcon />}
            />
          </Box>
        ) : null}
        <ContainerSearchRedirect
          placeholderSearch="Pesquisar por plano de ação"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchActions()}
          labelRedirect="CRIAR PLANO DE AÇÃO"
          onRedirect={() => navigate('/actions/create', { replace: true })}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={type === 'manager' ? 9 : 6}>
            <ButtonOutlined
              label="LIMPAR FILTRO"
              onAction={() => {
                clearAreaFilter();
                clearResponsibleFilter();
                clearSituationFilter();
                clearSearchFilter();
                getActionsByPage(0, '', '');
              }}
            />
          </Grid>
          <>
            {type === 'manager' || type === 'admin' ? (
              <Grid item xs={12} sm={3} mb={1}>
                <SelectAutocompleteOutlined
                  selectTestID="SelectAutocompleteResponsibleFilterID"
                  selectPlaceholder="Filtrar por responsável"
                  selectPlaceholderNoItem="Não há membros"
                  selectValue={responsibleFilterSelected}
                  selectOptions={membersFilteredByArea}
                  onChangeSelect={(optionValue) => onFilterActionsByResponsible(optionValue)}
                  isDisable={false}
                />
              </Grid>
            ) : null }
            {type === 'admin' ? (
              <Grid item xs={12} sm={3} mb={1}>
                <SelectOutlined
                  selectTestID="SelectFilterAreaID"
                  selectPlaceholder="Filtrar por área"
                  selectPlaceholderNoItem="Não há áreas"
                  selectValue={areaFilterSelected}
                  selectOptions={areasToSelect}
                  onChangeSelect={(optionValue) => onFilterActionsByArea(optionValue)}
                  isDisable={false}
                />
              </Grid>
            ) : null }
          </>
        </Grid>
        <ActionTableList
          tableTestID="TableActionID"
          tableMessageNoItem="Não há registro de ação"
          tableCells={['PRAZO FINAL', 'SITUAÇÃO']}
          countItems={actions.length}
          orderName={nameOrderSelected}
          onOrderName={() => changeNameOrder()}
          orderResponsible={responsibleOrderSelected}
          onOrderResponsible={() => changeResponsibleOrder()}
        >
          <ActionItems
            itemsAction={actions}
            onAction={(actionIdSeleted) => navigate(`/actions/${actionIdSeleted}/edit`, { replace: true })}
          />
        </ActionTableList>
        <TablePagination
          rowsPerPageOptions={[actionsPerPage]}
          component="div"
          count={actionsCount}
          rowsPerPage={actionsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingAction} />
    </MiniDrawer>
  );
}
