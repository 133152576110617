/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Collapse from '@mui/material/Collapse';

import SubSublevelTableLine from './components/SubSublevelTableLine';

export default function TableBodySubSublevel({ subSublevelsParam, isVisible }) {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#d9d9d9' }} colSpan={12}>
        <Collapse in={isVisible} timeout="auto" unmountOnExit>
          <Table aria-label="sublevels from sublevels">
            <TableBody>
              {subSublevelsParam !== null ? subSublevelsParam.map((subSublevelCycle) => (
                <SubSublevelTableLine
                  subSublevelParam={subSublevelCycle}
                  onAction={() => navigate(`/indicators/${subSublevelCycle.id}/sublevel/edit`, { replace: true })}
                />
              )) : null}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
