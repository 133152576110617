/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import InputOutlined from '../../components/InputOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import PickerMonthOutlined from '../../components/PickerMonthOutlined';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import GoalChips from '../../components/GoalChips';
import ButtonOutlined from '../../components/ButtonOutlined';
import ButtonContained from '../../components/ButtonContained';
import ButtonSquareContained from '../../components/ButtonSquareContained';

import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';

import { changeMessageWarning, MembersFetch } from '../../store/user/actions';
import {
  IndicatorsFetch,
  SublevelCreateFetch,
  IndicatorByIdFetch,
  SublevelUpdateByIdFetch,
  IndicatorDeleteByIdFetch,
} from '../../store/indicator/actions';

export default function IndicatorSublevelForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    area,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingIndicator, indicators, indicator } = useSelector((state) => state.indicator);

  const [name, setName] = useState('');
  const [goal, setGoal] = useState('');
  const [periodDate, setPeriodDate] = useState(dayjs(new Date()));
  const [goals, setGoals] = useState([]);
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [indicatorSelected, setIndicatorSelected] = useState('');
  const [indicatorsToSelect, setIndicatorsToSelect] = useState('');
  const [membersFilteredByArea, setMembersFilteredByArea] = useState([]);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indicatorId, sublevelId } = useParams();

  async function getIndicatorById() {
    try {
      dispatch(IndicatorByIdFetch(token, sublevelId));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getIndicators() {
    try {
      dispatch(IndicatorsFetch(token));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getMembers() {
    try {
      dispatch(MembersFetch(token));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getMembers();
    getIndicators();
    console.log('useEffect | sublevelId: ', sublevelId);

    if (sublevelId !== undefined) {
      getIndicatorById();
    }
  }, [history]);

  useEffect(() => {
    console.log(
      'useEffect | type, area, indicators: ',
      type,
      area,
      indicators,
    );
    if (type !== 'admin') {
      const indicatorsFromArea = indicators.filter(
        (indicatorCycle) => indicatorCycle.area.name === area,
      );
      const indicatorsOptions = indicatorsFromArea.map(
        (indicatorTwister) => indicatorTwister.name,
      );
      setIndicatorsToSelect(indicatorsOptions);
      const membersFromArea = members.filter(
        (memberCycle) => memberCycle.area.name === area,
      );
      const membersOptionsFromArea = membersFromArea.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
      console.log('useEffect | membersOptionsFromArea: ', membersOptionsFromArea);
      setMembersFilteredByArea(membersOptionsFromArea);
    } else {
      const indicatorsOptions = indicators.map(
        (indicatorTwister) => indicatorTwister.name,
      );
      setIndicatorsToSelect(indicatorsOptions);
      const membersOptions = members.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
      console.log('useEffect | membersOptions: ', membersOptions);
      setMembersFilteredByArea(membersOptions);
    }
  }, [indicators]);

  useEffect(() => {
    console.log(
      'useEffect | indicatorId, sublevelId, indicator: ',
      indicatorId,
      sublevelId,
      indicator,
    );
    if (sublevelId !== undefined && indicator !== null) {
      setName(indicator.name);
      setGoals(indicator.goals);
      setResponsibleSelected(`${indicator.responsible.first_name} ${indicator.responsible.last_name}`);
      if (indicator.parent !== null) {
        setIndicatorSelected(indicator.parent.name);
      }
    }
  }, [indicator]);

  useEffect(() => {
    console.log('useEffect | indicatorId: ', indicatorId);
    if (indicatorId !== undefined) {
      setName('');
      setGoals([]);
      setResponsibleSelected('');
      const indicatorFound = indicators.find(
        (indicatorCycle) => indicatorCycle.id === parseInt(indicatorId, 10),
      );
      console.log('useEffect | indicatorFound: ', indicatorFound);
      setIndicatorSelected(indicatorFound !== undefined ? indicatorFound.name : '');
    }
  }, [indicatorId]);

  function validateSublevel() {
    console.log(
      'validateSublevel | name, responsibleSelected, situationSelected: ',
      name,
      responsibleSelected,
    );
    let message = '';

    if (!name) {
      message = 'Preencha o campo nome';
    } else if (name.length < 3) {
      message = 'Campo nome é inválido';
    } else if (!indicatorSelected) {
      message = 'Selecione um indicador';
    } else if (goals.length === 0) {
      message = 'Preencha o campo meta';
    }

    return { isInvalid: !!message, message };
  }

  async function sendSublevelCreate() {
    try {
      const responseValidateIndicator = await validateSublevel();
      console.log('sendSublevelCreate | responseValidateIndicator ', responseValidateIndicator);

      if (responseValidateIndicator.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateIndicator.message,
          type: 'warning',
        }));
      } else {
        const indicatorFound = indicators.find(
          (indicatorCycle) => indicatorCycle.name === indicatorSelected,
        );
        console.log('sendSublevelCreate | indicatorFound: ', indicatorFound);

        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendIndicatorUpdateById | responsibleFound: ', responsibleFound);

        dispatch(SublevelCreateFetch(
          token,
          indicatorFound.id,
          name,
          goals,
          responsibleFound.id,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendSublevelCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendSublevelUpdateById() {
    try {
      const responseValidateSublevelUpdate = await validateSublevel();
      console.log('sendSublevelUpdateById | responseValidateSublevelUpdate: ', responseValidateSublevelUpdate);

      if (responseValidateSublevelUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateSublevelUpdate.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendSublevelUpdateById | responsibleFound: ', responsibleFound);
        const indicatorFound = indicators.find(
          (indicatorCycle) => indicatorCycle.name === indicatorSelected,
        );
        console.log('sendSublevelCreate | indicatorFound, indicatorSelected: ', indicatorFound, indicatorSelected);

        dispatch(SublevelUpdateByIdFetch(
          token,
          sublevelId,
          name,
          goals,
          responsibleFound.id,
          indicatorFound.id,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendSublevelUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteIndicatorById() {
    try {
      setIsVisibleDialog(false);
      dispatch(IndicatorDeleteByIdFetch(token, sublevelId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function validateSubject() {
    console.log('validateSubject | goal: ', goal);
    let message = '';

    if (!goal) {
      message = 'Preencha o campo meta';
    }

    return { isInvalid: !!message, message };
  }

  async function handleGoalAdd() {
    const responseValidateSubject = await validateSubject();
    console.log('handleGoalAdd | responseValidateSubject: ', responseValidateSubject);

    if (responseValidateSubject.isInvalid) {
      dispatch(changeMessageWarning({
        message: responseValidateSubject.message,
        type: 'warning',
      }));
    } else {
      const seletedDatetime = new Date(periodDate);
      const firstDayDateTimeSelected = new Date(
        seletedDatetime.getFullYear(),
        seletedDatetime.getMonth(),
        1,
      );
      const lastDayDateTimeSelected = new Date(
        seletedDatetime.getFullYear(),
        seletedDatetime.getMonth() + 1,
        0,
      );

      const subjectNew = {
        goal,
        start_date: firstDayDateTimeSelected.toISOString(),
        end_date: lastDayDateTimeSelected.toISOString(),
      };
      console.log('handleGoalAdd | subjectNew: ', subjectNew);
      const subjectsUptaded = [...goals, subjectNew];
      console.log('handleGoalAdd | subjectsUptaded: ', subjectsUptaded);
      setGoals(subjectsUptaded);
      setGoal('');
      setPeriodDate(dayjs(new Date()));
    }
  }

  const onChangeGoal = (textValue) => {
    const goalWithoutLetter = removeLetter(textValue);
    console.log('onChangeGoal | goalWithoutLetter', goalWithoutLetter);
    const goalWithoutSpecial = removeSpecial(goalWithoutLetter);
    console.log('onChangeGoal | goalWithoutSpecial', goalWithoutSpecial);

    setGoal(goalWithoutSpecial);
  };

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendIndicator = () => {
    if (sublevelId !== undefined) {
      sendSublevelUpdateById();
    } else {
      sendSublevelCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${sublevelId !== undefined ? 'Editar' : 'Criar'} subnível`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações do subnível"
          onBack={() => {
            if (indicatorId !== undefined) {
              navigate(`/indicators/${indicatorId}/edit`, { replace: true });
            }
            if (sublevelId !== undefined) {
              navigate(`/indicators/${sublevelId}/edit`, { replace: true });
            }
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputNameID"
              inputPlaceholder="Digite aqui o nome"
              inputValue={name}
              onChangeInput={(textValue) => setName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOutlined
              selectTestID="SelectIndicatorID"
              selectPlaceholder="Indicador"
              selectPlaceholderNoItem="Não há indicadores relacionados a área selecionada"
              selectValue={indicatorSelected}
              selectOptions={indicatorsToSelect}
              onChangeSelect={(optionValue) => setIndicatorSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={8.1}>
            <InputOutlined
              inputTestID="TextInputNameID"
              inputPlaceholder="Digite aqui a meta"
              inputValue={goal}
              onChangeInput={onChangeGoal}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={16} sm={2.3}>
            <PickerMonthOutlined
              inputTestID="PickerPeriodDateID"
              inputPlaceholder="Período da Meta"
              inputValue={periodDate}
              onChangeInput={(newValue) => setPeriodDate(newValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleGoalAdd()}
            />
          </Grid>
          <GoalChips
            chipsGoal={goals}
            onUpdate={(chipValue) => setGoals(chipValue)}
          />
          <Grid item xs={12}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersFilteredByArea}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: sublevelId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {sublevelId !== undefined
            ? (
              <>
                <ButtonContained
                  label="Excluir"
                  type="error"
                  onAction={changeIsVisibleDialog}
                />
                <ButtonOutlined
                  label="CRIAR SUBNÍVEL"
                  onAction={() => navigate(`/indicators/${sublevelId}/sublevel/create`, { replace: true })}
                />
              </>
            ) : null}
          <ButtonContained
            label={sublevelId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendIndicator}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingIndicator} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteIndicatorById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir esse subnível?"
      />
    </MiniDrawer>
  );
}
