/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ContainerSearchRedirect from '../../components/ContainerSearchRedirect';
import TableList from '../../components/TableList';
import MemberItems from './components/MemberItems';

import {
  MembersByPageFetch,
  MembersBySearchFetch,
  changeMessageWarning,
} from '../../store/user/actions';

export default function Member({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    members,
    membersCount,
  } = useSelector((state) => state.user);

  const [searchText, setSearchText] = useState('');
  const [membersPerPage, setMembersPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getMembersByPage(currentPage) {
    try {
      dispatch(MembersByPageFetch(token, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getMembersByPage(page);
  }, [history]);

  async function getMembersBySearch(currentPage) {
    try {
      dispatch(MembersBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function clearSearchFilter() {
    setSearchText('');
    setPage(0);
  }

  function validateActionsSearch() {
    console.log('validateActionsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de ação por nome';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de ação por nome é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function onSearchMembers() {
    const responsevalidateActionsSearch = await validateActionsSearch();
    console.log('onSearchMembers | responsevalidateActionsSearch: ', responsevalidateActionsSearch);

    if (responsevalidateActionsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateActionsSearch.name,
        type: 'warning',
      }));
    } else {
      getMembersBySearch(0);
    }
  }

  const onChangeSearchText = (textValue) => {
    if (textValue === '') {
      clearSearchFilter();
      getMembersByPage(0);
    }
    setSearchText(textValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getMembersBySearch(newPage);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getMembersByPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setMembersPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <MiniDrawer pageTitle="Membros">
      <ContainerPaper>
        <ContainerSearchRedirect
          placeholderSearch="Pesquisar por membro"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchMembers()}
          labelRedirect="CRIAR MEMBRO"
          onRedirect={() => navigate('/members/create', { replace: true })}
        />
        <TableList
          tableTestID="TableMemberID"
          tableMessageNoItem="Não há registro de membro"
          tableCells={['', 'NOME', 'SOBRENOME', 'ÁREA', 'CARGO']}
          countItems={members.length}
        >
          <MemberItems
            itemsMember={members}
            onAction={(memberIdSeleted) => navigate(`/members/${memberIdSeleted}/edit`, { replace: true })}
          />
        </TableList>
        <TablePagination
          rowsPerPageOptions={[membersPerPage]}
          component="div"
          count={membersCount}
          rowsPerPage={membersPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser} />
    </MiniDrawer>
  );
}
