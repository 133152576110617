/* eslint-disable react/prop-types */
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import RedefinePassword from './pages/RedefinePassword';
import Area from './pages/Area';
import AreaForm from './pages/AreaForm';
import AreaRoleForm from './pages/AreaRoleForm';
import ForgotPassword from './pages/ForgotPassword';
import Member from './pages/Member';
import MemberForm from './pages/MemberForm';
import Decision from './pages/Decision';
import DecisionForm from './pages/DecisionForm';
import Meeting from './pages/Meeting';
import MeetingForm from './pages/MeetingForm';
import Notification from './pages/Notification';
import Pendency from './pages/Pendency';
import PendencyForm from './pages/PendencyForm';
import Indicator from './pages/Indicator';
import IndicatorForm from './pages/IndicatorForm';
import IndicatorSublevelForm from './pages/IndicatorSublevelForm';
import ActionForm from './pages/ActionForm';
import Action from './pages/Action';
import Result from './pages/Result';
import RecordForm from './pages/RecordForm';
import RecordChart from './pages/RecordChart';
import Statistic from './pages/Statistic';

function RequireAuth({ children }) {
  const { token } = useSelector((state) => state.user);
  // console.log('RequireAuth | token: ', token);

  if (!token) {
    // console.log('RequireAuth | if | token: ', token);
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" replace />;
  }

  return children;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/profile',
    element: (
      <RequireAuth>
        <Profile />
      </RequireAuth>
    ),
  },
  {
    path: '/redefine-password',
    element: (
      <RequireAuth>
        <RedefinePassword />
      </RequireAuth>
    ),
  },
  {
    path: '/notifications',
    element: (
      <RequireAuth>
        <Notification />
      </RequireAuth>
    ),
  },
  {
    path: '/areas',
    element: (
      <RequireAuth>
        <Area />
      </RequireAuth>
    ),
  },
  {
    path: '/areas/create',
    element: (
      <RequireAuth>
        <AreaForm />
      </RequireAuth>
    ),
  },
  {
    path: '/areas/:areaId/edit',
    element: (
      <RequireAuth>
        <AreaForm />
      </RequireAuth>
    ),
  },
  {
    path: '/areas/:areaId/role/create',
    element: (
      <RequireAuth>
        <AreaRoleForm />
      </RequireAuth>
    ),
  },
  {
    path: '/areas/:roleId/role/edit',
    element: (
      <RequireAuth>
        <AreaRoleForm />
      </RequireAuth>
    ),
  },
  {
    path: '/members',
    element: (
      <RequireAuth>
        <Member />
      </RequireAuth>
    ),
  },
  {
    path: '/members',
    element: (
      <RequireAuth>
        <MemberForm />
      </RequireAuth>
    ),
  },
  {
    path: '/members/create',
    element: (
      <RequireAuth>
        <MemberForm />
      </RequireAuth>
    ),
  },
  {
    path: '/members/:memberId/edit',
    element: (
      <RequireAuth>
        <MemberForm />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings',
    element: (
      <RequireAuth>
        <Meeting />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings/create',
    element: (
      <RequireAuth>
        <MeetingForm />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings/:meetingId/edit',
    element: (
      <RequireAuth>
        <MeetingForm />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings/decisions',
    element: (
      <RequireAuth>
        <Decision />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings/decisions/create',
    element: (
      <RequireAuth>
        <DecisionForm />
      </RequireAuth>
    ),
  },
  {
    path: '/meetings/decisions/:decisionId/edit',
    element: (
      <RequireAuth>
        <DecisionForm />
      </RequireAuth>
    ),
  },
  {
    path: '/pendencies',
    element: (
      <RequireAuth>
        <Pendency />
      </RequireAuth>
    ),
  },
  {
    path: '/pendencies/create',
    element: (
      <RequireAuth>
        <PendencyForm />
      </RequireAuth>
    ),
  },
  {
    path: '/pendencies/:pendencyId/edit',
    element: (
      <RequireAuth>
        <PendencyForm />
      </RequireAuth>
    ),
  },
  {
    path: '/statistic/chart',
    element: (
      <RequireAuth>
        <Statistic />
      </RequireAuth>
    ),
  },
  {
    path: '/results/indicators',
    element: (
      <RequireAuth>
        <Result />
      </RequireAuth>
    ),
  },
  {
    path: '/results/:indicatorId/chart',
    element: (
      <RequireAuth>
        <RecordChart />
      </RequireAuth>
    ),
  },
  {
    path: '/results/create',
    element: (
      <RequireAuth>
        <RecordForm />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators',
    element: (
      <RequireAuth>
        <Indicator />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators/create',
    element: (
      <RequireAuth>
        <IndicatorForm />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators/:indicatorId/edit',
    element: (
      <RequireAuth>
        <IndicatorForm />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators/:indicatorId/sublevel/create',
    element: (
      <RequireAuth>
        <IndicatorSublevelForm />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators/:sublevelId/sublevel/edit',
    element: (
      <RequireAuth>
        <IndicatorSublevelForm />
      </RequireAuth>
    ),
  },
  {
    path: '/indicators/:indicatorId/actions/create',
    element: (
      <RequireAuth>
        <ActionForm />
      </RequireAuth>
    ),
  },
  {
    path: '/actions',
    element: (
      <RequireAuth>
        <Action />
      </RequireAuth>
    ),
  },
  {
    path: '/actions/create',
    element: (
      <RequireAuth>
        <ActionForm />
      </RequireAuth>
    ),
  },
  {
    path: '/actions/:actionId/edit',
    element: (
      <RequireAuth>
        <ActionForm />
      </RequireAuth>
    ),
  },
]);

export default router;
