/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import StatusChips from './components/StatusChips';
import ButtonTransparent from '../../components/ButtonTransparent';
import ContainerSearchRedirect from '../../components/ContainerSearchRedirect';
import PendencyTableList from './components/PendencyTableList';
import PendencyItems from './components/PendencyItems';

import { changeMessageWarning } from '../../store/user/actions';
import {
  PendenciesFetch,
  PendenciesBySearchFetch,
  PendenciesBySituationFetch,
  PendenciesByAlphabeticFetch,
} from '../../store/pendency/actions';

const conditions = require('../../utils/resources/conditions.json');

export default function Pendency({ history }) {
  const { typeWarning, messageWarning, token } = useSelector((state) => state.user);
  const { isLoadingPendency, pendencies } = useSelector((state) => state.pendency);

  const responsibleAlphabetic = [
    '',
    'responsible',
    '-responsible',
  ];
  const descriptionAlphabetic = [
    '',
    'description',
    '-description',
  ];

  const [searchText, setSearchText] = useState('');
  const [situationFilterSelected, setSituationFilterSelected] = useState('');
  const [descriptionOrderSelected, setDescriptionOrderSelected] = useState('');
  const [responsibleOrderSelected, setResponsibleOrderSelected] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getPendencies() {
    try {
      dispatch(PendenciesFetch(token));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getPendencies();
  }, [history]);

  function clearSearchFilter() {
    setSearchText('');
  }

  function clearSituationFilter() {
    setSituationFilterSelected('');
  }

  function clearResponsibleOrder() {
    setResponsibleOrderSelected('');
  }

  function clearDescriptionOrder() {
    setDescriptionOrderSelected('');
  }

  function validatePendenciesSearch() {
    console.log('validatePendenciesSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de reunião por descrição';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de reunião por descrição é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function getPendenciesBySearch() {
    try {
      dispatch(PendenciesBySearchFetch(token, searchText));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function onSearchPendecies() {
    const responsevalidatePendenciesSearch = await validatePendenciesSearch();
    console.log('onSearchPendecies | responsevalidatePendenciesSearch: ', responsevalidatePendenciesSearch);

    if (responsevalidatePendenciesSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidatePendenciesSearch.message,
        type: 'warning',
      }));
    } else {
      clearSituationFilter();
      clearDescriptionOrder();
      clearResponsibleOrder();
      getPendenciesBySearch();
    }
  }

  async function getPendenciesBySituation(situationChosen) {
    try {
      dispatch(PendenciesBySituationFetch(token, situationChosen));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getPendenciesByAlphabetic(sortByChosen) {
    try {
      dispatch(PendenciesByAlphabeticFetch(token, sortByChosen));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function handleActionsSituationFilter(situationSelected) {
    console.log('handleActionsSituationFilter | situationSelected: ', situationSelected);
    if (situationFilterSelected !== situationSelected) {
      clearSearchFilter();
      setSituationFilterSelected(situationSelected);
      getPendenciesBySituation(situationSelected);
    } else {
      clearSituationFilter();
      getPendencies();
    }
  }

  const onChangeSearchText = (eventTextValue) => {
    if (eventTextValue === '') {
      clearSearchFilter();
      getPendencies();
    }
    setSearchText(eventTextValue);
  };

  function changeDescriptionOrder() {
    try {
      const descriptionAlphabeticCount = descriptionAlphabetic.length;
      const indexDescriptionOrderSelected = descriptionAlphabetic.indexOf(descriptionOrderSelected);
      const indexLastDescriptionAlphabetic = descriptionAlphabeticCount - 1;

      clearSearchFilter();
      clearSituationFilter();
      clearResponsibleOrder();

      if (indexDescriptionOrderSelected === indexLastDescriptionAlphabetic) {
        setDescriptionOrderSelected(descriptionAlphabetic[0]);
        getPendencies();
      } else {
        setDescriptionOrderSelected(descriptionAlphabetic[indexDescriptionOrderSelected + 1]);
        getPendenciesByAlphabetic(descriptionAlphabetic[indexDescriptionOrderSelected + 1]);
      }
    } catch (error) {
      console.log('changeDescriptionOrder | error: ', error);
    }
  }

  function changeResponsibleOrder() {
    try {
      const responsibleAlphabeticCount = responsibleAlphabetic.length;
      const indexResponsibleOrderSelected = responsibleAlphabetic.indexOf(responsibleOrderSelected);
      const indexLastResponsibleAlphabetic = responsibleAlphabeticCount - 1;
      console.log(
        'changeResponsibleOrder | indexResponsibleOrderSelected, indexLastResponsibleAlphabetic: ',
        indexResponsibleOrderSelected,
        indexLastResponsibleAlphabetic,
      );

      clearSearchFilter();
      clearSituationFilter();
      clearDescriptionOrder();

      if (indexResponsibleOrderSelected === indexLastResponsibleAlphabetic) {
        setResponsibleOrderSelected(responsibleAlphabetic[0]);
        getPendencies();
      } else {
        setResponsibleOrderSelected(responsibleAlphabetic[indexResponsibleOrderSelected + 1]);
        getPendenciesByAlphabetic(responsibleAlphabetic[indexResponsibleOrderSelected + 1]);
      }
    } catch (error) {
      console.log('changeResponsibleOrder | error: ', error);
    }
  }

  const redirectToPendenciesPdf = () => {
    const baseUrl = process.env.REACT_APP_API;
    let pendenciesIdQueryUrl = '';
    pendencies.forEach((pendencyCycle, index) => {
      const isMoreParameter = index > 0 ? '&' : '';
      pendenciesIdQueryUrl += `${isMoreParameter}pendencies_id=${pendencyCycle.id}`;
    });

    const redirectUrl = `${baseUrl}/pendencies/pendencies_pdf_by_id?${pendenciesIdQueryUrl}`;
    console.log('redirectToPendenciesPdf | redirectUrl: ', redirectUrl);
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle="Pendências">
      <ContainerPaper>
        <StatusChips
          chipsStatus={conditions}
          itemsPendency={pendencies}
          chipValue={situationFilterSelected}
          onAction={(chipValue) => handleActionsSituationFilter(chipValue)}
        />
        {pendencies.length >= 1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: 3 }}>
            <ButtonTransparent
              label="RELATÓRIO"
              onAction={redirectToPendenciesPdf}
              icon={<SaveAltIcon />}
            />
          </Box>
        ) : null}
        <ContainerSearchRedirect
          placeholderSearch="Pesquisar por pendência"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchPendecies()}
          labelRedirect="CRIAR PENDÊNCIA"
          onRedirect={() => navigate('/pendencies/create', { replace: true })}
        />
        <PendencyTableList
          tableTestID="TablePendencyID"
          tableMessageNoItem="Não há registro de pendência"
          tableCells={['PRAZO FINAL', 'STATUS']}
          countItems={pendencies.length}
          orderDescription={descriptionOrderSelected}
          onOrderDescription={() => changeDescriptionOrder()}
          orderResponsible={responsibleOrderSelected}
          onOrderResponsible={() => changeResponsibleOrder()}
        >
          <PendencyItems
            itemsPendency={pendencies}
            onAction={(pendencyIdSeleted) => navigate(`/pendencies/${pendencyIdSeleted}/edit`, { replace: true })}
          />
        </PendencyTableList>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingPendency} />
    </MiniDrawer>
  );
}
