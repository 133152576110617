/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import NotificationTableList from './components/NotificationTableList';
import NotificationItems from './components/NotificationItems';
import ContainerSearch from '../../components/ContainerSearch';

import { changeMessageWarning } from '../../store/user/actions';
import {
  IsReadNotificationsFetch,
  NotificationsByPageFetch,
  NotificationBySearchFetch,
} from '../../store/notification/actions';

export default function Notification({ history }) {
  const { typeWarning, messageWarning, token } = useSelector((state) => state.user);
  const {
    isLoadingNotification,
    notifications,
    notificationsCount,
  } = useSelector((state) => state.notification);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const [notificationsPerPage, setNotificationsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  async function getNotificationsByPage(currentPage) {
    try {
      dispatch(NotificationsByPageFetch(token, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function clearSearch() {
    setPage(0);
    getNotificationsByPage(0);
  }

  async function getNotificationsBySearch(currentPage) {
    try {
      dispatch(NotificationBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendIsReadNotifications() {
    try {
      dispatch(IsReadNotificationsFetch(token));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getNotificationsByPage(page);
    sendIsReadNotifications();
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getNotificationsBySearch(newPage);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getNotificationsByPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setNotificationsPerPage(+event.target.value);
    setPage(0);
  };

  function validateNotificationsSearch() {
    console.log('validateNotificationsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de notificação por mensagem';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de notificação por mensagem é inválido';
    }

    return { isInvalid: !!message, message };
  }

  const onChangeSearchText = (ventTextValue) => {
    if (ventTextValue === '') {
      clearSearch();
    }
    setSearchText(ventTextValue);
  };

  async function onSearchNotifications() {
    const responsevalidateNotificationsSearch = await validateNotificationsSearch();
    console.log('onSearchNotifications | responsevalidateNotificationsSearch: ', responsevalidateNotificationsSearch);

    if (responsevalidateNotificationsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateNotificationsSearch.message,
        type: 'warning',
      }));
    } else {
      setPage(0);
      getNotificationsBySearch(0);
    }
  }

  return (
    <MiniDrawer pageTitle="Notificações">
      <ContainerPaper>
        <ContainerSearch
          placeholderSearch="Pesquisar por mensagem"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchNotifications()}
        />
        <NotificationTableList
          tableTestID="TableNotificationID"
          tableMessageNoItem="Não há registro de notificação"
          countItems={notifications.length}
        >
          <NotificationItems itemsNotification={notifications} />
        </NotificationTableList>
        <TablePagination
          rowsPerPageOptions={[notificationsPerPage]}
          component="div"
          count={notificationsCount}
          rowsPerPage={notificationsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingNotification} />
    </MiniDrawer>
  );
}
