/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './styles';

export default function StatusChips({
  chipsStatus,
  itemsPendency,
  chipValue,
  onAction,
}) {
  const isMatches = useMediaQuery('(min-width:795px)');

  return (
    <Box sx={isMatches ? {
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 3,
    } : { display: 'flex', flexWrap: 'wrap' }}
    >
      {chipsStatus !== undefined && itemsPendency !== undefined ? (chipsStatus.map(
        (statusLoop, statusIndex) => {
          console.log(
            'renderSituationChip | statusLoop.name, statusIndex: ',
            statusLoop.name,
            statusIndex,
          );
          const pendenciesWithSituationLoop = itemsPendency.filter(
            (pendencyCycle) => pendencyCycle.situation === statusLoop.name,
          );
          const countPendenciesSituation = pendenciesWithSituationLoop.length;
          console.log(
            'renderSituationChip | countPendenciesSituation: ',
            countPendenciesSituation,
          );
          const countPendencies = itemsPendency.length;
          console.log('renderSituationChip | countPendencies: ', countPendencies);
          const percentagePendenciesSituation = countPendencies !== 0
            ? (countPendenciesSituation * 100) / countPendencies : 0;
          console.log(
            'renderSituationChip | percentagePendenciesSituation: ',
            percentagePendenciesSituation,
          );
          const percentagePendenciesSituationRound = Math.round(percentagePendenciesSituation);
          console.log(
            'renderSituationChip | percentagePendenciesSituationRound: ',
            percentagePendenciesSituationRound,
          );
          const label = `${statusLoop.name} ${countPendenciesSituation} - ${percentagePendenciesSituationRound}%`;
          return (
            <Chip
              variant={chipValue === statusLoop.name ? 'outlined' : 'filled'}
              onClick={() => onAction(statusLoop.name)}
              label={label}
              sx={{
                backgroundColor: chipValue === statusLoop.name ? 'white' : statusLoop.color,
                color: chipValue === statusLoop.name ? statusLoop.color : 'white',
                ...styles.containerChipStatus,
              }}
              size="medium"
            />
          );
        },
      )) : null }
    </Box>
  );
}
