/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import SelectOutlined from '../../components/SelectOutlined';
import InputOutlined from '../../components/InputOutlined';
import InputAvatar from '../../components/InputAvatar';
import ButtonContained from '../../components/ButtonContained';

import emailIsInvalid from '../../utils/validation/emailIsInvalid';
import passwordIsInvalid from '../../utils/validation/passwordIsInvalid';

import { AreasFetch, RolesFetch } from '../../store/area/actions';
import {
  MemberCreateFetch,
  MemberByIdFetch,
  MemberUpdateByIdFetch,
  MemberDeleteByIdFetch,
  changeMessageWarning,
} from '../../store/user/actions';

export default function MemberForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    area,
    member,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas, roles } = useSelector((state) => state.area);

  const [avatarUrlBlob, setAvatarUrlBlob] = useState('');
  const [avatarUri, setAvatarUri] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [typeSelected, setTypeSelected] = useState('');
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [areaSelected, setAreaSelected] = useState('');
  const [roleSelected, setRoleSelected] = useState('');
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);
  const [rolesFilteredByArea, setRolesFilteredByArea] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { memberId } = useParams();

  const types = [
    { value: 'admin', name: 'Admin' },
    { value: 'manager', name: 'Gerente' },
    { value: 'default_user', name: 'Usuário Padrão' },
  ];

  useEffect(() => {
    async function getMemberById() {
      try {
        dispatch(MemberByIdFetch(token, memberId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getAreas() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getRoles() {
      try {
        dispatch(RolesFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getAreas();
    getRoles();
    console.log('memberId: ', memberId);

    if (memberId !== undefined) {
      getMemberById();
    }

    if (type === 'admin') {
      setUserTypes(['Admin', 'Gerente', 'Usuário Padrão']);
    } else {
      setUserTypes(['Gerente', 'Usuário Padrão']);
    }
  }, [history]);

  useEffect(() => {
    function fillAreaSelected() {
      try {
        console.log('fillAreaSelected | area: ', area);
        const areaFound = areas.find((areaCycle) => areaCycle.name === area);
        console.log('fillAreaSelected | areaFound: ', areaFound);
        if (areaFound !== undefined) {
          setAreaSelected(areaFound.name);
        }
      } catch (error) {
        console.log('fillAreaSelected | error: ', error);
      }
    }

    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    fillAreasToSelect();

    console.log('useEffect | areas: ', areas);
    if (areas !== undefined && type !== 'admin') {
      fillAreaSelected();
    }
  }, [areas]);

  useEffect(() => {
    function fillInputsMember() {
      try {
        setAvatarUrlBlob(member.profile_picture);
        setFirstName(member.first_name);
        setLastName(member.last_name);
        setEmail(member.email);
        const typeFound = types.find((typePhase) => typePhase.value === member.user_type);
        console.log('fillInputsMember | typeFound: ', typeFound);
        setTypeSelected(typeFound.name);
        setRoleSelected(member.role.name);
        setAreaSelected(member.area.name);
      } catch (error) {
        console.log('fillInputsMember | error: ', error);
      }
    }
    console.log('useEffect | memberId, member: ', memberId, member);
    if (memberId !== undefined && member !== null) {
      fillInputsMember();
    } else {
      setAvatarUrlBlob('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setTypeSelected('');
      setRoleSelected('');
      setAreaSelected('');
    }
  }, [member, memberId]);

  useEffect(() => {
    function fillInputRoleSelected() {
      try {
        console.log('useEffect | areaSelected: ', areaSelected);
        const rolesFromArea = roles.filter(
          (roleCycle) => roleCycle.area.name === areaSelected,
        );
        console.log('useEffect | rolesFromArea: ', rolesFromArea);
        const rolesOptions = rolesFromArea.map((roleTwister) => roleTwister.name);
        setRolesFilteredByArea(rolesOptions);

        const roleFound = rolesFromArea.find(
          (roleCycle) => roleCycle.name === roleSelected,
        );
        console.log('useEffect | roleFound, roleSelected: ', roleFound, roleSelected);
        const isFromAreaRole = roleFound !== undefined
          ? roleFound.name === roleSelected : false;
        console.log('useEffect | isFromAreaRole: ', isFromAreaRole);
        if (!isFromAreaRole) {
          setRoleSelected('');
        }
      } catch (error) {
        console.log('fillInputRoleSelected | error: ', error);
      }
    }

    fillInputRoleSelected();
  }, [areaSelected]);

  function validatePasswordStrong() {
    console.log(
      'validatePasswordStrong | password: ',
      password,
    );
    let message = '';

    if (!password) {
      message = 'Preencha o campo senha';
    } else if (password.length < 8 && password.length > 15) {
      message = 'Campo senha deve conter de 8 à 15 caracteres';
    } else if (passwordIsInvalid(password)) {
      message = 'Campo senha deve conter números e letras';
    }

    return { isInvalid: !!message, message };
  }

  function validateMember() {
    console.log(
      'validateMember | typeSelected, areaSelected, roleSelected, firstName, lastName, email: ',
      typeSelected,
      areaSelected,
      roleSelected,
      firstName,
      lastName,
      email,
    );
    let message = '';

    if (!typeSelected) {
      message = 'Preencha o campo tipo';
    } else if (!areaSelected) {
      message = 'Preencha o campo área';
    } else if (!roleSelected) {
      message = 'Preencha o campo cargo';
    } else if (firstName.length < 3) {
      message = 'Campo nome é inválido';
    } else if (!lastName) {
      message = 'Preencha o campo sobrenome';
    } else if (lastName.length < 3) {
      message = 'Campo sobrenome é inválido';
    } else if (!email) {
      message = 'Preencha o campo e-mail';
    } else if (email && emailIsInvalid(email)) {
      message = 'Campo e-mail é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function sendMemberCreate() {
    try {
      const responseValidateMemberCreate = await validateMember();
      console.log('sendMemberCreate | responseValidateMemberCreate: ', responseValidateMemberCreate);

      const responseValidatePasswordStrong = await validatePasswordStrong();
      console.log('sendMemberCreate | responseValidatePasswordStrong: ', responseValidatePasswordStrong);

      if (responseValidateMemberCreate.isInvalid || responseValidatePasswordStrong.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateMemberCreate.message || responseValidatePasswordStrong.message,
          type: 'warning',
        }));
      } else {
        const typeFound = types.find((typeCycle) => typeCycle.name === typeSelected);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        const roleFound = roles.find((roleCycle) => roleCycle.name === roleSelected);

        dispatch(MemberCreateFetch(
          token,
          firstName,
          lastName,
          email,
          typeFound.value,
          areaFound.id,
          roleFound.id,
          password,
          avatarUri,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendMemberCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendMemberUpdateById() {
    try {
      const responseValidateMemberUpdate = await validateMember();
      console.log('sendMemberUpdateById | responseValidateMemberUpdate: ', responseValidateMemberUpdate);

      if (responseValidateMemberUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateMemberUpdate.message,
          type: 'warning',
        }));
      } else {
        const typeFound = types.find((typeCycle) => typeCycle.name === typeSelected);
        console.log('sendMemberUpdateById | typeFound: ', typeFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendMemberUpdateById | areaFound: ', areaFound);
        const roleFound = roles.find((roleCycle) => roleCycle.name === roleSelected);
        console.log('sendMemberUpdateById | roleFound: ', roleFound);

        dispatch(MemberUpdateByIdFetch(
          token,
          memberId,
          firstName,
          lastName,
          email,
          typeFound.value,
          areaFound.id,
          roleFound.id,
          avatarUri,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendMemberUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteMemberById() {
    try {
      setIsVisibleDialog(false);
      dispatch(MemberDeleteByIdFetch(token, memberId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendMember = () => {
    if (memberId !== undefined) {
      sendMemberUpdateById();
    } else {
      sendMemberCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${memberId !== undefined ? 'Editar' : 'Cadastrar'} membro`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações do membro"
          onBack={() => navigate('/members', { replace: true })}
        />
        <InputAvatar
          inputTestID="InputAvatarID"
          inputAlt={firstName}
          inputSource={avatarUrlBlob}
          onChangeInput={(urlBlobValue, uriValue) => {
            setAvatarUrlBlob(urlBlobValue);
            setAvatarUri(uriValue);
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectTypeID"
              selectPlaceholder="Tipo"
              selectPlaceholderNoItem="Não há tipos"
              selectValue={typeSelected}
              selectOptions={userTypes}
              onChangeSelect={(optionValue) => setTypeSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há áreas"
              selectValue={areaSelected}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelected(optionValue)}
              isDisable={type !== 'admin'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectRoleID"
              selectPlaceholder="Cargo"
              selectPlaceholderNoItem="Não há cargos"
              selectValue={roleSelected}
              selectOptions={rolesFilteredByArea}
              onChangeSelect={(optionValue) => setRoleSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputFirstNameID"
              inputPlaceholder="Digite aqui seu nome"
              inputValue={firstName}
              onChangeInput={(textValue) => setFirstName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputLastNameID"
              inputPlaceholder="Digite aqui seu sobrenome"
              inputValue={lastName}
              onChangeInput={(textValue) => setLastName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputEmailID"
              inputPlaceholder="Digite aqui seu e-mail"
              inputValue={email}
              onChangeInput={(textValue) => setEmail(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          {memberId === undefined ? (
            <Grid item xs={12} sm={6}>
              <InputOutlined
                inputTestID="TextInputPasswordID"
                inputPlaceholder="Digite aqui a senha"
                inputValue={password}
                onChangeInput={(textValue) => setPassword(textValue)}
                isSecure
                isDisable={false}
              />
            </Grid>
          ) : null}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: memberId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {memberId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}
          <ButtonContained
            label={memberId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendMember}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingArea} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteMemberById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir esse membro?"
      />
    </MiniDrawer>
  );
}
