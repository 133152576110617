import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { pendencySlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  pendencyLoading,
  pendenciesSucess,
  pendencySucess,
} = pendencySlice.actions;

export const PendenciesFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('PendenciesFetch');
  const configRequest = {
    method: 'get',
    url: '/pendencies/pendencies_by_user',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendenciesFetch | response.data:', response.data);

    dispatch(pendenciesSucess({ pendencies: response.data.pendencies }));
  } catch (error) {
    console.log('PendenciesFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const PendenciesBySearchFetch = (tokenAuthorization, searchText) => async (dispatch) => {
  console.log('PendenciesBySearchFetch | tokenAuthorization, searchText:', tokenAuthorization, searchText);
  const configRequest = {
    method: 'get',
    url: `/pendencies/pendencies_by_search?text=${searchText}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendenciesBySearchFetch | response.data:', response.data);
    dispatch(pendenciesSucess({ pendencies: response.data.pendencies }));
  } catch (error) {
    console.log('PendenciesBySearchFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const PendenciesBySituationFetch = (tokenAuthorization, situation) => async (dispatch) => {
  console.log('PendenciesBySituationFetch | tokenAuthorization, situation:', tokenAuthorization, situation);
  const configRequest = {
    method: 'get',
    url: `/pendencies/pendencies_by_situation?situation=${situation}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendenciesBySituationFetch | response.data:', response.data);
    dispatch(pendenciesSucess({ pendencies: response.data.pendencies }));
  } catch (error) {
    console.log('PendenciesBySituationFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const PendenciesByAlphabeticFetch = (tokenAuthorization, sortBy) => async (dispatch) => {
  console.log('PendenciesByAlphabeticFetch | tokenAuthorization, sortBy:', tokenAuthorization, sortBy);
  const configRequest = {
    method: 'get',
    url: `/pendencies/alphabetic_pendencies?sort_by=${sortBy}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendenciesByAlphabeticFetch | response.data:', response.data);
    dispatch(pendenciesSucess({ pendencies: response.data.pendencies }));
  } catch (error) {
    console.log('PendenciesByAlphabeticFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const PendencyCreateFetch = (
  tokenAuthorization,
  description,
  responsible,
  situation,
  startDate,
  endDate,
  observation,
  meetingID,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'PendencyCreateFetch | tokenAuthorization, firstName, lastName, email, area, role, password: ',
    tokenAuthorization,
    description,
    responsible,
    situation,
    startDate,
    endDate,
    observation,
  );

  const configRequest = {
    method: 'post',
    url: '/pendencies/create_pendency',
    data: {
      description,
      responsible,
      situation,
      start_date: startDate,
      end_date: endDate,
      observation,
      meeting_id: meetingID,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));
    console.log('PendencyCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('PendencyCreateFetch | response.data:', response.data);
    dispatch(pendencyLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    if (meetingID === null) {
      navigateToPage('/pendencies', { replace: true });
    }
  } catch (error) {
    console.log('PendencyCreateFetch | error:', error);
    dispatch(pendencyLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const PendencyByIdFetch = (tokenAuthorization, pendencyId) => async (dispatch) => {
  console.log('PendencyByIdFetch | tokenAuthorization, pendencyId:', tokenAuthorization, pendencyId);
  const configRequest = {
    method: 'get',
    url: `/pendencies/pendency_by_id?pendency_id=${pendencyId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendencyByIdFetch | response.data:', response.data);
    dispatch(pendencySucess({ pendency: response.data.pendency }));
  } catch (error) {
    console.log('PendencyByIdFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const PendencyUpdateByIdFetch = (
  tokenAuthorization,
  pendencyId,
  description,
  responsible,
  situation,
  startDate,
  endDate,
  observation,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'PendencyUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, area, role:',
    tokenAuthorization,
    pendencyId,
    description,
    responsible,
    situation,
    startDate,
    endDate,
    observation,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/pendencies/update_pendency_by_id',
    data: {
      pendency_id: pendencyId,
      description,
      responsible,
      situation,
      start_date: startDate,
      end_date: endDate,
      observation,
    },
    headers: { Authorization: tokenAuthorization },
  };

  console.log('PendencyUpdateByIdFetch configRequest.data', configRequest.data);

  try {
    dispatch(pendencyLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('PendencyUpdateByIdFetch | response.data:', response.data);
    dispatch(pendencyLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/pendencies', { replace: true });
  } catch (error) {
    console.log('PendencyUpdateByIdFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const PendencyDeleteByIdFetch = (
  tokenAuthorization,
  pendencyId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'PendencyDeleteByIdFetch | tokenAuthorization, pendencyId, navigateToPage:',
    tokenAuthorization,
    pendencyId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/pendencies/delete_pendency_by_id',
    data: { pendency_id: pendencyId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendencyDeleteByIdFetch | response.data:', response.data);
    dispatch(pendencyLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/pendencies', { replace: true });
  } catch (error) {
    console.log('PendencyDeleteByIdFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({
      message,
      type: 'error',
    }));
  }
};

export const PendenciesByMeetingFetch = (tokenAuthorization, meetingID) => async (dispatch) => {
  console.log('PendenciesByMeetingFetch | tokenAuthorization, meetingID:', tokenAuthorization, meetingID);
  const configRequest = {
    method: 'get',
    url: `/pendencies/pendencies_by_meeting?meeting_id=${meetingID}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(pendencyLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('PendenciesByMeetingFetch | response.data:', response.data);
    dispatch(pendenciesSucess({ pendencies: response.data.pendencies }));
  } catch (error) {
    console.log('PendenciesByMeetingFetch | error:', error.message);
    dispatch(pendencyLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};
