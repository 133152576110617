/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import SelectOutlined from '../../components/SelectOutlined';
import InputOutlined from '../../components/InputOutlined';
import ButtonContained from '../../components/ButtonContained';

import { changeMessageWarning } from '../../store/user/actions';
import {
  AreasFetch,
  RoleCreateFetch,
  RoleByIdFetch,
  RoleUpdateByIdFetch,
  RoleDeleteByIdFetch,
} from '../../store/area/actions';

export default function AreaRoleForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas, role } = useSelector((state) => state.area);

  const [name, setName] = useState('');
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [areaSelected, setAreaSelected] = useState('');
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { areaId, roleId } = useParams();

  useEffect(() => {
    async function getRoleById() {
      try {
        dispatch(RoleByIdFetch(token, roleId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getAreas() {
      try {
        dispatch(AreasFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getAreas();
    console.log('roleId: ', roleId);

    if (roleId !== undefined) {
      getRoleById();
    }
  }, [history]);

  useEffect(() => {
    console.log('useEffect | role: ', role);
    if (roleId !== undefined && role !== null) {
      setName(role.name);
      const areaFound = areas.find(
        (areaCycle) => areaCycle.id === parseInt(role.area.id, 10),
      );
      console.log('useEffect | areaFound: ', areaFound);
      setAreaSelected(areaFound !== undefined ? areaFound.name : '');
    }
  }, [role]);

  useEffect(() => {
    console.log('useEffect | areas, areaId: ', areas, areaId);
    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }
    function fillAreaSelected() {
      try {
        const areaFound = areas.find(
          (areaCycle) => areaCycle.id === parseInt(areaId, 10),
        );
        console.log('useEffect | areaFound: ', areaFound);
        setAreaSelected(areaFound !== undefined ? areaFound.name : '');
      } catch (error) {
        console.log('fillAreaSelected | error: ', error);
      }
    }

    fillAreasToSelect();

    if (areaId !== undefined) {
      fillAreaSelected();
    }
  }, [areas]);

  useEffect(() => {
    console.log('useEffect | areaId: ', areaId);
    if (areaId !== undefined) {
      setName('');
      const areaFound = areas.find(
        (areaCycle) => areaCycle.id === parseInt(areaId, 10),
      );
      console.log('useEffect | areaFound: ', areaFound);
      setAreaSelected(areaFound !== undefined ? areaFound.name : '');
    }
  }, [areaId]);

  function validateRole() {
    console.log(
      'validateRole | name, situationSelected: ',
      name,
    );
    let message = '';

    if (!name) {
      message = 'Preencha o campo nome';
    } else if (name.length < 3) {
      message = 'Campo nome é inválido';
    } else if (!areaSelected) {
      message = 'Selecione uma área';
    }

    return { isInvalid: !!message, message };
  }

  async function sendRoleCreate() {
    try {
      const responseValidateRole = await validateRole();
      console.log('sendRoleCreate | responseValidateRole ', responseValidateRole);

      if (responseValidateRole.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateRole.message,
          type: 'warning',
        }));
      } else {
        const areaFound = areas.find(
          (areaCycle) => areaCycle.name === areaSelected,
        );
        console.log('sendRoleCreate | areaFound: ', areaFound);

        dispatch(RoleCreateFetch(
          token,
          areaFound.id,
          name,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendRoleCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendRoleUpdateById() {
    try {
      const responseValidateRoleUpdate = await validateRole();
      console.log('sendRoleUpdateById | responseValidateRoleUpdate: ', responseValidateRoleUpdate);

      if (responseValidateRoleUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateRoleUpdate.message,
          type: 'warning',
        }));
      } else {
        const areaFound = areas.find(
          (areaCycle) => areaCycle.name === areaSelected,
        );
        console.log('sendRoleCreate | areaFound, areaSelected: ', areaFound, areaSelected);

        dispatch(RoleUpdateByIdFetch(
          token,
          roleId,
          name,
          areaFound.id,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendRoleUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteRoleById() {
    try {
      setIsVisibleDialog(false);
      dispatch(RoleDeleteByIdFetch(token, roleId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendRole = () => {
    if (roleId !== undefined) {
      sendRoleUpdateById();
    } else {
      sendRoleCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${roleId !== undefined ? 'Editar' : 'Criar'} cargo`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações do cargo"
          onBack={() => {
            if (areaId !== undefined) {
              navigate(`/areas/${areaId}/edit`, { replace: true });
            }
            if (roleId !== undefined) {
              navigate('/areas', { replace: true });
            }
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputRoleID"
              inputPlaceholder="Digite aqui o cargo"
              inputValue={name}
              onChangeInput={(textValue) => setName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há áreas"
              selectValue={areaSelected}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: roleId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {roleId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}

          <ButtonContained
            label={roleId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendRole}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingArea} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteRoleById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir esse cargo?"
      />
    </MiniDrawer>
  );
}
