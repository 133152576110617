/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './styles';

export default function SituationChips({
  chipsSituation,
  itemsAction,
  chipValue,
  onAction,
}) {
  const isMatches = useMediaQuery('(min-width:795px)');

  return (
    <Box sx={isMatches ? {
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 3,
    } : { display: 'flex', flexWrap: 'wrap' }}
    >
      {chipsSituation !== undefined && itemsAction !== undefined ? (chipsSituation.map(
        (situationLoop) => {
          const actionsWithSituationLoop = itemsAction.filter(
            (actionCycle) => actionCycle.situation === situationLoop.name,
          );
          const countActionsSituation = actionsWithSituationLoop.length;
          // console.log('situations.map | countActionsSituation: ', countActionsSituation);
          const countActions = itemsAction.length;
          // console.log('situations.map | countActions: ', countActions);
          const percentageActionsSituation = countActions !== 0
            ? (countActionsSituation * 100) / countActions : 0;
          // console.log(
          //   'situations.map | percentageActionsSituation: ',
          //   percentageActionsSituation,
          // );
          const percentageActionsSituationRound = Math.round(percentageActionsSituation);
          // console.log(
          //   'situations.map | percentageActionsSituationRound: ',
          //   percentageActionsSituationRound,
          // );
          const label = `${situationLoop.name} ${countActionsSituation} - ${percentageActionsSituationRound}%`;
          return (
            <Chip
              variant={chipValue === situationLoop.name ? 'outlined' : 'filled'}
              onClick={() => onAction(situationLoop.name)}
              label={label}
              sx={{
                backgroundColor: chipValue === situationLoop.name ? 'white' : situationLoop.color,
                color: chipValue === situationLoop.name ? situationLoop.color : 'white',
                ...styles.containerChipSituation,
              }}
              size="medium"
            />
          );
        },
      )) : null }
    </Box>
  );
}
