/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import styles from './styles';

export default function InputAvatar({
  inputTestID,
  inputAlt,
  inputSource,
  onChangeInput,
}) {
  const onChangeValue = ({ target: { files } }) => onChangeInput(
    URL.createObjectURL(files[0]),
    files[0],
  );

  return (
    <Box sx={styles.containerInputAvatar}>
      <Avatar
        alt={inputAlt}
        src={inputSource}
        sx={styles.containerAvatar}
      />
      <label htmlFor={inputTestID}>
        <input
          style={{ display: 'none' }}
          id={inputTestID}
          name={inputTestID}
          type="file"
          placeholder="anexar imagem"
          onChange={onChangeValue}
        />
        <Button
          size="small"
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          ANEXAR FOTO
        </Button>
        {' '}
      </label>
    </Box>
  );
}
