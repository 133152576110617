/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import InputOutlined from '../../components/InputOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import PickerMonthOutlined from '../../components/PickerMonthOutlined';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import GoalChips from '../../components/GoalChips';
import ButtonOutlined from '../../components/ButtonOutlined';
import ButtonContained from '../../components/ButtonContained';
import ButtonSquareContained from '../../components/ButtonSquareContained';

import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';

import { AreasFetch } from '../../store/area/actions';
import { MembersFetch, changeMessageWarning } from '../../store/user/actions';
import {
  IndicatorCreateFetch,
  IndicatorByIdFetch,
  IndicatorUpdateByIdFetch,
  IndicatorDeleteByIdFetch,
} from '../../store/indicator/actions';

const unitsMeasurement = require('../../utils/resources/unitMeasurements.json');
const criteria = require('../../utils/resources/criteria.json');

export default function IndicatorForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    area,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas } = useSelector((state) => state.area);
  const { isLoadingIndicator, indicator } = useSelector((state) => state.indicator);

  const [name, setName] = useState('');
  const [unitMeasurementSelected, setUnitMeasurementSelected] = useState('');
  const [goal, setGoal] = useState('');
  const [periodDate, setPeriodDate] = useState(dayjs(new Date()));
  const [goals, setGoals] = useState([]);
  const [criterionSelected, setCriterionSelected] = useState('');
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [areaSelected, setAreaSelected] = useState('');
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [membersFilteredByArea, setMembersFilteredByArea] = useState([]);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indicatorId } = useParams();

  useEffect(() => {
    async function getIndicatorById() {
      try {
        dispatch(IndicatorByIdFetch(token, indicatorId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getAreas() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getMembers() {
      try {
        dispatch(MembersFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getMembers();
    getAreas();
    console.log('indicatorId: ', indicatorId);

    if (indicatorId !== undefined) {
      getIndicatorById();
    }
  }, [history]);

  useEffect(() => {
    console.log('useEffect | indicatorId, indicator: ', indicatorId, indicator);
    function fillInputsIndicator() {
      try {
        setName(indicator.name);
        setUnitMeasurementSelected(indicator.unit_measurement);
        setGoals(indicator.goals);
        setCriterionSelected(indicator.criterion);
        setResponsibleSelected(`${indicator.responsible.first_name} ${indicator.responsible.last_name}`);
        setAreaSelected(indicator.area.name);
      } catch (error) {
        console.log('fillInputsIndicator | error: ', error);
      }
    }
    if (indicatorId !== undefined && indicator !== null) {
      fillInputsIndicator();
    } else {
      setName('');
      setUnitMeasurementSelected('');
      setGoals([]);
      setCriterionSelected('');
      setResponsibleSelected('');
      setAreaSelected('');
    }
  }, [indicator, indicatorId]);

  useEffect(() => {
    function fillAreaSelected() {
      try {
        console.log('fillAreaSelected | area: ', area);
        const areaFound = areas.find((areaCycle) => areaCycle.name === area);
        console.log('fillAreaSelected | areaFound: ', areaFound);
        if (areaFound !== undefined) {
          setAreaSelected(areaFound.name);
        }
      } catch (error) {
        console.log('fillAreaSelected | error: ', error);
      }
    }

    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    fillAreasToSelect();

    console.log('useEffect | areas: ', areas);
    if (areas !== undefined && type !== 'admin') {
      fillAreaSelected();
    }
  }, [areas]);

  useEffect(() => {
    console.log('useEffect | areaSelected: ', areaSelected);
    const membersFromArea = members.filter(
      (memberCycle) => memberCycle.area.name === areaSelected,
    );
    const membersOptionsFromArea = membersFromArea.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
    console.log('useEffect | membersOptionsFromArea: ', membersOptionsFromArea);
    setMembersFilteredByArea(membersOptionsFromArea);
  }, [areaSelected]);

  function validateIndicator() {
    console.log(
      'validateIndicator | name, responsibleSelected, situationSelected: ',
      name,
      responsibleSelected,
    );
    let message = '';

    if (!name) {
      message = 'Preencha o campo nome';
    } else if (name.length < 3) {
      message = 'Campo nome é inválido';
    } else if (!unitMeasurementSelected) {
      message = 'Selecione uma unidade de medida';
    } else if (!criterionSelected) {
      message = 'Selecione um critério';
    } else if (!responsibleSelected) {
      message = 'Selecione um responsável';
    } else if (!areaSelected) {
      message = 'Selecione uma área';
    }

    return { isInvalid: !!message, message };
  }

  async function sendIndicatorCreate() {
    try {
      const responseValidateIndicator = await validateIndicator();
      console.log('sendIndicatorCreate | responseValidateIndicator ', responseValidateIndicator);

      if (responseValidateIndicator.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateIndicator.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendIndicatorCreate | responsibleFound: ', responsibleFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendIndicatorCreate | areaFound: ', areaFound);

        dispatch(IndicatorCreateFetch(
          token,
          name,
          unitMeasurementSelected,
          goals,
          criterionSelected,
          responsibleFound.id,
          areaFound.id,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendIndicatorCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendIndicatorUpdateById() {
    try {
      const responseValidateIndicatorUpdate = await validateIndicator();
      console.log('sendIndicatorUpdateById | responseValidateIndicatorUpdate: ', responseValidateIndicatorUpdate);

      if (responseValidateIndicatorUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateIndicatorUpdate.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendIndicatorUpdateById | responsibleFound: ', responsibleFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendIndicatorUpdateById | areaFound: ', areaFound);

        dispatch(IndicatorUpdateByIdFetch(
          token,
          indicatorId,
          name,
          unitMeasurementSelected,
          goals,
          criterionSelected,
          responsibleFound.id,
          areaFound.id,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendIndicatorUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteIndicatorById() {
    try {
      setIsVisibleDialog(false);
      dispatch(IndicatorDeleteByIdFetch(token, indicatorId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function subtractHours(date, hours) {
    date.setHours(date.getHours() - hours);

    return date;
  }

  function validateGoal() {
    console.log('validateGoal | goal: ', goal);
    let message = '';
    if (!goal) {
      message = 'Preencha o campo meta';
    }
    return { isInvalid: !!message, message };
  }

  async function handleGoalAdd() {
    const responseValidateGoal = await validateGoal();
    console.log('handleGoalAdd | responseValidateGoal: ', responseValidateGoal);
    if (responseValidateGoal.isInvalid) {
      dispatch(changeMessageWarning({
        message: responseValidateGoal.message,
        type: 'warning',
      }));
    } else {
      const seletedDatetime = new Date(periodDate);
      const firstDayDateTimeSelected = new Date(seletedDatetime.getFullYear(), seletedDatetime.getMonth(), 1);
      const lastDayDateTimeSelected = new Date(seletedDatetime.getFullYear(), seletedDatetime.getMonth() + 1, 0);
      console.log('handleGoalAdd | firstDayDateTimeSelected, lastDayDateTimeSelected: ', firstDayDateTimeSelected, lastDayDateTimeSelected);

      const firstDayDatetime = new Date(
        firstDayDateTimeSelected.getFullYear(),
        firstDayDateTimeSelected.getMonth(),
        firstDayDateTimeSelected.getDate(),
        0,
        0,
      );
      const lastDayDatetime = new Date(
        lastDayDateTimeSelected.getFullYear(),
        lastDayDateTimeSelected.getMonth(),
        lastDayDateTimeSelected.getDate(),
        23,
        59,
        59,
      );
      console.log('handleGoalAdd | firstDayDatetime, lastDayDatetime: ', firstDayDatetime, lastDayDatetime);

      const firstDayDateTimezoneSub = subtractHours(firstDayDatetime, 3);
      const lastDayDateTimezoneSub = subtractHours(lastDayDatetime, 3);
      console.log('handleGoalAdd | firstDayDateTimezoneSub, lastDayDateTimezoneSub: ', firstDayDateTimezoneSub, lastDayDateTimezoneSub);

      const goalNew = {
        goal,
        start_date: firstDayDateTimezoneSub.toISOString(),
        end_date: lastDayDateTimezoneSub.toISOString(),
      };
      console.log('handleGoalAdd | goalNew: ', goalNew);
      const goalsUptaded = [...goals, goalNew];
      console.log('handleGoalAdd | goalsUptaded: ', goalsUptaded);
      setGoals(goalsUptaded);
      setGoal('');
      setPeriodDate(dayjs(new Date()));
    }
  }

  const onChangeGoal = (textValue) => {
    const goalWithoutLetter = removeLetter(textValue);
    console.log('onChangeGoal | goalWithoutLetter', goalWithoutLetter);
    const goalWithoutSpecial = removeSpecial(goalWithoutLetter);
    console.log('onChangeGoal | goalWithoutSpecial', goalWithoutSpecial);

    setGoal(goalWithoutSpecial);
  };

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendIndicator = () => {
    if (indicatorId !== undefined) {
      sendIndicatorUpdateById();
    } else {
      sendIndicatorCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${indicatorId !== undefined ? 'Editar' : 'Criar'} indicador`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações do indicador"
          onBack={() => navigate('/indicators', { replace: true })}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputNameID"
              inputPlaceholder="Digite aqui o nome"
              inputValue={name}
              onChangeInput={(textValue) => setName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOutlined
              selectTestID="SelectUnitMeasurementID"
              selectPlaceholder="Unidades de medida"
              selectPlaceholderNoItem="Não há unidade de medida"
              selectValue={unitMeasurementSelected}
              selectOptions={unitsMeasurement}
              onChangeSelect={(optionValue) => setUnitMeasurementSelected(
                optionValue,
              )}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={8.1}>
            <InputOutlined
              inputTestID="TextInputGoalID"
              inputPlaceholder="Digite aqui a meta"
              inputValue={goal}
              onChangeInput={onChangeGoal}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={16} sm={2.3}>
            <PickerMonthOutlined
              inputTestID="PickerPeriodDateID"
              inputPlaceholder="Período da Meta"
              inputValue={periodDate}
              onChangeInput={(newValue) => setPeriodDate(newValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleGoalAdd()}
            />
          </Grid>
          <GoalChips
            chipsGoal={goals}
            onUpdate={(chipValue) => setGoals(chipValue)}
          />
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectCriterionID"
              selectPlaceholder="Critério"
              selectPlaceholderNoItem="Não há critério"
              selectValue={criterionSelected}
              selectOptions={criteria}
              onChangeSelect={(optionValue) => setCriterionSelected(
                optionValue,
              )}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há área"
              selectValue={areaSelected}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelected(optionValue)}
              isDisable={type !== 'admin'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersFilteredByArea}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: indicatorId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {indicatorId !== undefined
            ? (
              <>
                <ButtonContained
                  label="Excluir"
                  type="error"
                  onAction={changeIsVisibleDialog}
                />
                <ButtonOutlined
                  label="CRIAR SUBNÍVEL"
                  onAction={() => navigate(`/indicators/${indicatorId}/sublevel/create`, { replace: true })}
                />
              </>
            ) : null}

          <ButtonContained
            label={indicatorId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendIndicator}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingIndicator || isLoadingArea} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteIndicatorById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir esse indicador?"
      />
    </MiniDrawer>
  );
}
