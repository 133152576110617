/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

import TableBodySublevel from '../TableBodySublevel';

import getIndicatorGoal from '../../../../utils/getIndicatorGoal';
import numericDotMask from '../../../../utils/masks/numericDotMask';

export default function IndicatorItem({ itemIndicator, onAction }) {
  const [isCollapse, setIsCollapse] = useState(false);
  const [sublevelsLength, setSublevelsLength] = useState(0);
  const [indicatorGoal, setIndicatorGoal] = useState(0);

  async function fillIndicatorGoal() {
    try {
      console.log('fillIndicatorGoal | itemIndicator: ', itemIndicator);
      console.log('fillIndicatorGoal | itemIndicator.sublevels: ', itemIndicator.sublevels);

      const goalFromIndicator = await getIndicatorGoal(itemIndicator.goals);
      console.log('fillIndicatorGoal | goalFromIndicator: ', goalFromIndicator);
      const goalFromIndicatorWithMask = await numericDotMask(goalFromIndicator);
      setIndicatorGoal(goalFromIndicatorWithMask);
    } catch (error) {
      console.log('fillIndicatorGoal | error: ', error);
      setIndicatorGoal(0);
    }
  }

  async function countIndicadorSublevels() {
    try {
      const indicatorsCount = (itemIndicator.sublevels).length;
      console.log('fillIndicatorGoal | indicatorsCount: ', indicatorsCount);
      setSublevelsLength(indicatorsCount);
    } catch (error) {
      console.log('fillIndicatorGoal | error: ', error);
      setSublevelsLength(0);
    }
  }

  useEffect(() => {
    fillIndicatorGoal();
    countIndicadorSublevels();
  }, [itemIndicator]);

  return (
    <>
      <TableRow
        key={itemIndicator.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {sublevelsLength >= 1
          ? (
            <TableCell>
              <IconButton
                aria-label={`button collapse ${itemIndicator.name}`}
                size="small"
                onClick={() => setIsCollapse(!isCollapse)}
              >
                {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (<TableCell />)}
        <TableCell>
          <IconButton
            aria-label={`button edit ${itemIndicator.name}`}
            size="small"
            onClick={() => onAction()}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {itemIndicator.name}
        </TableCell>
        <TableCell align="left">{itemIndicator.unit_measurement}</TableCell>
        <TableCell align="left">
          {indicatorGoal}
        </TableCell>
        <TableCell align="left">{itemIndicator.criterion}</TableCell>
        {itemIndicator.area !== null ? (
          <TableCell align="left">{itemIndicator.area.name}</TableCell>
        ) : (
          <TableCell align="left" />
        )}
        <TableCell align="left">{`${itemIndicator.responsible.first_name} ${itemIndicator.responsible.last_name}`}</TableCell>
      </TableRow>
      {sublevelsLength >= 1
        ? (
          <TableBodySublevel
            sublevelsParam={itemIndicator.sublevels}
            isVisible={isCollapse}
          />
        ) : null }
    </>
  );
}
