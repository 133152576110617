/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ContainerSearch from '../../components/ContainerSearch';
import ProgressChips from './components/ProgressChips';
import SelectOutlined from '../../components/SelectOutlined';
import MonthChips from './components/MonthChips';
import ResultTableList from './components/ResultTableList';
import ResultItems from './components/ResultItems';
import AreaChips from './components/AreaChips';
import ButtonOutlined from '../../components/ButtonOutlined';

import { changeMessageWarning } from '../../store/user/actions';
import {
  ResultsByPageFetch,
  ResultsBySearchFetch,
  ResultsByUnitFetch,
  ResultsByAlphabeticFetch,
} from '../../store/result/actions';

const unitsMeasurement = require('../../utils/resources/unitMeasurements.json');
const months = require('../../utils/resources/months.json');

export default function Result({ history }) {
  const {
    typeWarning,
    messageWarning,
    token,
    type,
    area,
  } = useSelector((state) => state.user);

  const nameAlphabetic = [
    '',
    'a',
    'd',
  ];

  const { isLoadingArea, areas } = useSelector((state) => state.area);
  const { isLoadingResult, resultsCount, results } = useSelector((state) => state.result);
  const [searchText, setSearchText] = useState('');
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [monthFilterSelected, setMonthFilterSelected] = useState('');
  const [unitFilterSelected, setUnitFilterSelected] = useState('');
  const [areaFilterSelected, setAreaFilterSelected] = useState('');
  const [nameOrderSelected, setNameOrderSelected] = useState('');
  const [areasByTypeUser, setAreasByTypeUser] = useState([]);

  const dispatch = useDispatch();
  const isMatches = useMediaQuery('(min-width:795px)');

  async function getResultsByPage(currentPage, areaIdChosen, monthRange) {
    try {
      console.log(
        'getResultsByPage | currentPage, areaIdChosen, monthRange: ',
        currentPage,
        areaIdChosen,
        monthRange,
      );
      let firstDayDateTimeSelected = '';
      let lastDayDateTimeSelected = '';
      if (monthRange !== '') {
        const indexMonth = months.indexOf(monthRange);
        const currentDate = new Date();
        firstDayDateTimeSelected = new Date(currentDate.getFullYear(), indexMonth, 1);
        lastDayDateTimeSelected = new Date(currentDate.getFullYear(), indexMonth + 1, 0);
      }

      dispatch(ResultsByPageFetch(
        token,
        currentPage + 1,
        areaIdChosen,
        firstDayDateTimeSelected,
        lastDayDateTimeSelected,
      ));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getResultsByPage(page, '', '');

    if (type === 'admin') {
      setAreasByTypeUser(areas);
    } else {
      const areaFound = areas.filter((areaCycle) => areaCycle.name === area);
      console.log('handleActionsFilterResponsible | areaFound: ', areaFound);
      setAreasByTypeUser(areaFound);
    }
  }, [history]);

  function clearSearchFilter() {
    setSearchText('');
    setPage(0);
  }

  function clearAreaFilter() {
    setAreaFilterSelected('');
    setPage(0);
  }

  function clearUnitFilter() {
    setUnitFilterSelected('');
    setPage(0);
  }

  function clearMonthFilter() {
    setMonthFilterSelected('');
    setPage(0);
  }

  function clearNameOrder() {
    setNameOrderSelected('');
    setPage(0);
  }

  async function getResultsBySearch(currentPage) {
    try {
      dispatch(ResultsBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getResultsByUnit(currentPage, unitChosen) {
    try {
      dispatch(ResultsByUnitFetch(token, unitChosen, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function getResultsByAlphabetic(currentPage, sortByChosen) {
    try {
      dispatch(ResultsByAlphabeticFetch(token, sortByChosen, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function validateResultsSearch() {
    console.log('validateResultsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de resultado por indicador';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de resultado por indicador é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function onSearchResults() {
    const responsevalidateResultsSearch = await validateResultsSearch();
    console.log('onSearchResults | responsevalidateResultsSearch: ', responsevalidateResultsSearch);

    if (responsevalidateResultsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateResultsSearch.message,
        type: 'warning',
      }));
    } else {
      clearAreaFilter();
      clearMonthFilter();
      clearUnitFilter();
      clearNameOrder();
      getResultsBySearch(0);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getResultsBySearch(newPage);
    } else if (unitFilterSelected) {
      console.log('handleChangePage | newPage, unitFilterSelected: ', newPage, unitFilterSelected);
      getResultsByUnit(newPage, unitFilterSelected);
    } else if (nameOrderSelected) {
      console.log('handleChangePage | newPage, nameOrderSelected: ', newPage, nameOrderSelected);
      getResultsByAlphabetic(newPage, nameOrderSelected);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getResultsByPage(newPage, areaFilterSelected, monthFilterSelected);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setResultsPerPage(+event.target.value);
    setPage(0);
  };

  const onChangeSearchText = (eventTextValue) => {
    if (eventTextValue === '') {
      clearSearchFilter();
      getResultsByPage(0, '', '');
    }
    setSearchText(eventTextValue);
  };

  const onFilterResultsByUnit = (optionValue) => {
    clearAreaFilter();
    clearMonthFilter();
    clearNameOrder();
    clearSearchFilter();
    setUnitFilterSelected(optionValue);
    getResultsByUnit(0, optionValue);
  };

  const onFilterResultsByArea = (optionValue) => {
    if (areaFilterSelected !== optionValue.id) {
      clearSearchFilter();
      clearUnitFilter();
      setAreaFilterSelected(optionValue.id);
      getResultsByPage(0, optionValue.id, monthFilterSelected);
    } else {
      clearAreaFilter();
      getResultsByPage(
        0,
        '',
        monthFilterSelected,
      );
    }
  };

  const onFilterResultsByMonth = (monthSelected) => {
    console.log(' onFilterResultsByMonth | monthSelected: ', monthSelected);
    if (monthFilterSelected !== monthSelected) {
      clearUnitFilter();
      clearNameOrder();
      clearSearchFilter();
      setMonthFilterSelected(monthSelected);
      getResultsByPage(
        0,
        areaFilterSelected,
        monthSelected,
      );
    } else {
      clearMonthFilter();
      getResultsByPage(
        0,
        areaFilterSelected,
        '',
      );
    }
  };

  function changeNameOrder() {
    try {
      const nameAlphabeticCount = nameAlphabetic.length;
      const indexNameOrderSelected = nameAlphabetic.indexOf(nameOrderSelected);
      const indexLastNameAlphabetic = nameAlphabeticCount - 1;

      clearMonthFilter();
      clearUnitFilter();
      clearAreaFilter();
      clearNameOrder();
      clearSearchFilter();

      if (indexNameOrderSelected === indexLastNameAlphabetic) {
        setNameOrderSelected(nameAlphabetic[0]);
        getResultsByPage(0, '', '');
      } else {
        setNameOrderSelected(nameAlphabetic[indexNameOrderSelected + 1]);
        getResultsByAlphabetic(0, nameAlphabetic[indexNameOrderSelected + 1]);
      }
    } catch (error) {
      console.log('changeNameOrder | error: ', error);
    }
  }

  return (
    <MiniDrawer pageTitle="Resultados">
      <ContainerPaper>
        <ContainerSearch
          placeholderSearch="Pesquisar por indicador"
          valueSearch={searchText}
          onChangeSearch={onChangeSearchText}
          onSearch={() => onSearchResults()}
        />
        <Box sx={isMatches ? {
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
        } : {
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: 160,
        }}
        >
          <ButtonOutlined
            label="LIMPAR FILTRO"
            onAction={() => {
              clearAreaFilter();
              clearMonthFilter();
              clearSearchFilter();
              clearUnitFilter();
              getResultsByPage(0, '', '');
            }}
          />
          <ProgressChips chipsResult={results} />
          <Box sx={{ width: isMatches ? '20%' : '100%' }}>
            <SelectOutlined
              selectTestID="SelectUnitID"
              selectPlaceholder="Filtrar por unidade"
              selectPlaceholderNoItem="Não há unidade"
              selectValue={unitFilterSelected}
              selectOptions={unitsMeasurement}
              onChangeSelect={onFilterResultsByUnit}
              isDisable={false}
            />
          </Box>
        </Box>
        <MonthChips
          chipsMonth={months}
          chipValue={monthFilterSelected}
          onUpdate={onFilterResultsByMonth}
        />
        <ResultTableList
          tableTestID="TableResultID"
          tableMessageNoItem="Não há registro de resultado"
          tableCells={['META', 'REALIZADO', 'TENDÊNCIA', '% REALIZADO', 'STATUS', 'GRÁFICO', 'PLANO DE AÇÃO', 'NOVO P.A']}
          countItems={results.length}
          orderName={nameOrderSelected}
          onOrderName={() => changeNameOrder()}
        >
          <ResultItems itemsResult={results} />
        </ResultTableList>
        <TablePagination
          rowsPerPageOptions={[resultsPerPage]}
          component="div"
          count={resultsCount}
          rowsPerPage={resultsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AreaChips
          chipsArea={areasByTypeUser}
          chipValue={areaFilterSelected}
          onUpdate={onFilterResultsByArea}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingResult || isLoadingArea} />
    </MiniDrawer>
  );
}
