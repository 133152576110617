/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import calculeProgressColorBiggerThen from '../../../../../../utils/calculeProgressColorBiggerThen';
import calculeProgressColorLessThen from '../../../../../../utils/calculeProgressColorLessThen';
import numericDotMask from '../../../../../../utils/masks/numericDotMask';

const progressColors = require('../../../../../../utils/resources/progressColors.json');

export default function SubSublevelTableLine({ subSublevelParam }) {
  const [colorStatus, setColorStatus] = useState(progressColors[0]);

  const navigate = useNavigate();

  useEffect(() => {
    if (subSublevelParam.criterion === 'MAIOR QUE') {
      const currentProgressColorBiggerThen = calculeProgressColorBiggerThen(subSublevelParam.done_percentage);
      // console.log('calculeProgressColorBiggerThen | currentProgressColorBiggerThen: ', currentProgressColorBiggerThen);
      setColorStatus(currentProgressColorBiggerThen);
    }
    if (subSublevelParam.criterion === 'MENOR QUE') {
      const currentProgressColorLessThen = calculeProgressColorLessThen(subSublevelParam.done_percentage);
      // console.log('calculeProgressColorLessThen | currentProgressColorLessThen: ', currentProgressColorLessThen);
      setColorStatus(currentProgressColorLessThen);
    }
  }, [subSublevelParam]);

  return (
    <TableRow key={subSublevelParam.id}>
      <TableCell component="th" scope="row">
        {subSublevelParam.name}
      </TableCell>
      <TableCell align="center">
        {subSublevelParam.goal !== null && subSublevelParam.goal !== undefined ? numericDotMask(subSublevelParam.goal.goal) : '0'}
      </TableCell>
      <TableCell align="center">{numericDotMask(subSublevelParam.done)}</TableCell>
      <TableCell align="center">{numericDotMask(subSublevelParam.linear_trend)}</TableCell>
      <TableCell align="center">
        {`${subSublevelParam.done_percentage !== undefined ? subSublevelParam.done_percentage : 0}%`}
      </TableCell>
      {subSublevelParam.goal !== null && subSublevelParam.goal !== undefined ? (
        <TableCell
          align="center"
          sx={{
            fontWeight: 'bold',
            color: colorStatus,
          }}
        >
          <CircleIcon />
        </TableCell>
      ) : (
        <TableCell
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#7388A95A',
          }}
        >
          <CircleIcon />
        </TableCell>
      )}
      <TableCell align="center" sx={{ color: '#f66600' }}>
        <AssessmentIcon onClick={() => navigate(`/results/${subSublevelParam.id}/chart`, { replace: true })} />
      </TableCell>
      {subSublevelParam.action !== null
      && subSublevelParam.action !== undefined
      && subSublevelParam.action.situation !== 'CONCLUIDO'
      && subSublevelParam.action.situation !== 'CANCELADO' ? (
        <TableCell align="center" sx={{ color: '#34bfa3' }}>
          <CircleIcon />
        </TableCell>
        ) : (
          <TableCell align="center">
            <CircleOutlinedIcon />
          </TableCell>
        )}
      {subSublevelParam.action === null && subSublevelParam.action !== undefined ? (
        <TableCell align="center">
          <AddBoxIcon onClick={() => navigate(`/indicators/${subSublevelParam.id}/actions/create`)} />
        </TableCell>
      ) : (
        <TableCell align="center" />
      )}
    </TableRow>
  );
}
