/* eslint-disable react/prop-types */
import React from 'react';

import NotificationItem from '../NotificationItem';

export default function NotificationItems({ itemsNotification }) {
  return (
    itemsNotification !== undefined ? (itemsNotification.map(
      (notificationLoop) => (
        <NotificationItem itemNotification={notificationLoop} />
      ),
    )) : null
  );
}
