const styles = {
  containerChipProgress: {
    color: 'white',
    fontWeight: 'bold',
    width: '60px',
    margin: '2px 4px',
  },
};

export default styles;
