const progressColors = require('./resources/progressColors.json');

export default function calculeProgressColorBiggerThen(progressPercentage) {
  try {
    if (progressPercentage < 90) {
      return progressColors[0];
    }
    if (progressPercentage >= 90 && progressPercentage <= 99.9) {
      return progressColors[1];
    }
    if (progressPercentage >= 100 && progressPercentage <= 119.9) {
      return progressColors[2];
    }
    if (progressPercentage > 120) {
      return progressColors[3];
    }

    return progressColors[0];
  } catch (error) {
    console.log('calculeProgressColorBiggerThen | error: ', error);
    return progressColors[0];
  }
}
