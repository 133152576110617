import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { indicatorSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  indicatorLoading,
  changeIndicatorsCount,
  indicatorsSucess,
  indicatorSucess,
} = indicatorSlice.actions;

export const IndicatorsFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('IndicatorsFetch');
  const configRequest = {
    method: 'get',
    url: '/indicators/indicators_by_user',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('IndicatorsFetch | response.data:', response.data);

    dispatch(indicatorsSucess({ indicators: response.data.indicators }));
  } catch (error) {
    console.log('IndicatorsFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const IndicatorsByPageFetch = (tokenAuthorization, page, ordering) => async (dispatch) => {
  console.log('IndicatorsByPageFetch | tokenAuthorization, page: ', tokenAuthorization, page);
  const configRequest = {
    method: 'get',
    url: `/indicators/indicators_by_page?page=${page}&ordering=${ordering}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('IndicatorsByPageFetch | response.data:', response.data);

    dispatch(changeIndicatorsCount({ count: response.data.count }));
    dispatch(indicatorsSucess({ indicators: response.data.results }));
  } catch (error) {
    console.log('IndicatorsByPageFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const IndicatorCreateFetch = (
  tokenAuthorization,
  name,
  unitMeasurement,
  goals,
  criterion,
  responsibleId,
  area,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'IndicatorCreateFetch | tokenAuthorization, firstName, lastName, email, area, role, password: ',
    tokenAuthorization,
    name,
    unitMeasurement,
    goals,
    criterion,
    responsibleId,
    area,
  );

  const configRequest = {
    method: 'post',
    url: '/indicators/create_indicator',
    data: {
      name,
      unit_measurement: unitMeasurement,
      goals,
      criterion,
      responsible: responsibleId,
      area,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));
    console.log('IndicatorCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('IndicatorCreateFetch | response.data:', response.data);
    dispatch(indicatorLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/indicators', { replace: true });
  } catch (error) {
    console.log('IndicatorCreateFetch | error:', error);
    dispatch(indicatorLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const IndicatorByIdFetch = (tokenAuthorization, indicatorId) => async (dispatch) => {
  console.log('IndicatorByIdFetch | tokenAuthorization, indicatorId:', tokenAuthorization, indicatorId);
  const configRequest = {
    method: 'get',
    url: `/indicators/indicator_by_id?indicator_id=${indicatorId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('IndicatorByIdFetch | response.data:', response.data);
    dispatch(indicatorSucess({ indicator: response.data.indicator }));
  } catch (error) {
    console.log('IndicatorByIdFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const IndicatorUpdateByIdFetch = (
  tokenAuthorization,
  indicatorId,
  name,
  unitMeasurement,
  goals,
  criterion,
  responsibleId,
  area,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'IndicatorUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, area, role:',
    tokenAuthorization,
    indicatorId,
    name,
    unitMeasurement,
    goals,
    criterion,
    responsibleId,
    area,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/indicators/update_indicator_by_id',
    data: {
      indicator_id: indicatorId,
      name,
      unit_measurement: unitMeasurement,
      goals,
      criterion,
      responsible: responsibleId,
      area,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('IndicatorUpdateByIdFetch | response.data:', response.data);
    dispatch(indicatorLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/indicators', { replace: true });
  } catch (error) {
    console.log('IndicatorUpdateByIdFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const IndicatorDeleteByIdFetch = (
  tokenAuthorization,
  indicatorId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'IndicatorDeleteByIdFetch | tokenAuthorization, indicatorId, navigateToPage:',
    tokenAuthorization,
    indicatorId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/indicators/delete_indicator_by_id',
    data: { indicator_id: indicatorId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('IndicatorDeleteByIdFetch | response.data:', response.data);
    dispatch(indicatorLoading({ isLoading: false }));
    navigateToPage('/indicators', { replace: true });
  } catch (error) {
    console.log('IndicatorDeleteByIdFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({
      message,
      type: 'error',
    }));
  }
};

export const SublevelCreateFetch = (
  tokenAuthorization,
  indicatorId,
  name,
  goals,
  responsibleId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'SublevelCreateFetch | tokenAuthorization, firstName, lastName, email, area, role, password: ',
    tokenAuthorization,
    indicatorId,
    name,
    goals,
    responsibleId,
  );

  const configRequest = {
    method: 'post',
    url: '/indicators/create_sublevel',
    data: {
      parent_id: indicatorId,
      name,
      goals,
      responsible: responsibleId,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));
    console.log('SublevelCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('SublevelCreateFetch | response.data:', response.data);
    dispatch(indicatorLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/indicators', { replace: true });
  } catch (error) {
    console.log('SublevelCreateFetch | error.response.status:', error.response.status);
    dispatch(indicatorLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401, 404, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const SublevelUpdateByIdFetch = (
  tokenAuthorization,
  indicatorId,
  name,
  goals,
  responsibleId,
  parentId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'SublevelUpdateByIdFetch | tokenAuthorization, indicatorId, name, goals, responsibleId, parentId:',
    tokenAuthorization,
    indicatorId,
    name,
    goals,
    responsibleId,
    parentId,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/indicators/update_sublevel_by_id',
    data: {
      indicator_id: indicatorId,
      name,
      goals,
      responsible: responsibleId,
      parent_id: parentId,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(indicatorLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('SublevelUpdateByIdFetch | response.data:', response.data);
    dispatch(indicatorLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/indicators', { replace: true });
  } catch (error) {
    console.log('SublevelUpdateByIdFetch | error:', error.message);
    dispatch(indicatorLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401, 404, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};
