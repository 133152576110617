/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import getIndicatorGoal from '../../../../../../utils/getIndicatorGoal';
import numericDotMask from '../../../../../../utils/masks/numericDotMask';

export default function SubSublevelTableLine({ subSublevelParam, onAction }) {
  const [indicatorSubSublevelGoal, setIndicatorSubSublevelGoal] = useState(0);

  async function fillIndicatorGoal() {
    try {
      const goalFromIndicator = await getIndicatorGoal(subSublevelParam.goals);
      const goalFromIndicatorWithMask = await numericDotMask(goalFromIndicator);
      setIndicatorSubSublevelGoal(goalFromIndicatorWithMask);
    } catch (error) {
      console.log('fillIndicatorGoal | error: ', error);
      setIndicatorSubSublevelGoal(0);
    }
  }

  useEffect(() => {
    fillIndicatorGoal();
  }, [subSublevelParam]);

  return (
    <TableRow key={subSublevelParam.id}>
      <TableCell sx={{ width: '12.5%' }}>
        <IconButton
          aria-label={`button edit ${subSublevelParam.name}`}
          size="small"
          onClick={() => onAction()}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell align="left" sx={{ width: '19.5%' }}>
        {subSublevelParam.name}
      </TableCell>
      <TableCell align="left" sx={{ width: '17.4%' }}>
        {subSublevelParam.unit_measurement}
      </TableCell>
      <TableCell align="left" sx={{ width: '8.5%' }}>
        {indicatorSubSublevelGoal}
      </TableCell>
      <TableCell align="left">
        {subSublevelParam.criterion}
      </TableCell>
      {subSublevelParam.area !== null ? (
        <TableCell align="left">{subSublevelParam.area.name}</TableCell>
      ) : (
        <TableCell align="left" />
      )}
      <TableCell>
        {`${subSublevelParam.responsible.first_name} ${subSublevelParam.responsible.last_name}`}
      </TableCell>
    </TableRow>
  );
}
