const progressColors = require('./resources/progressColors.json');

export default function calculeProgressColorLessThen(progressPercentage) {
  try {
    if (progressPercentage > 120) {
      return progressColors[0];
    }
    if (progressPercentage > 100 && progressPercentage <= 120) {
      return progressColors[1];
    }
    if (progressPercentage > 80 && progressPercentage <= 100) {
      return progressColors[2];
    }
    if (progressPercentage < 80) {
      return progressColors[3];
    }

    return progressColors[3];
  } catch (error) {
    console.log('calculeProgressColorLessThen | error: ', error);
    return progressColors[3];
  }
}
