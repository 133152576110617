/* eslint-disable react/prop-types */
import React from 'react';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export default function PickerDateTimeOutlined({
  inputTestID,
  inputPlaceholder,
  inputValue,
  onChangeInput,
  isDisable = true,
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="pt-BR"
    >
      <MobileDateTimePicker
        id={inputTestID}
        ampm={false}
        format="DD/MM/YYYY HH:mm"
        label={inputPlaceholder}
        fullWidth
        disabled={isDisable}
        variant="outlined"
        value={inputValue}
        onChange={(newValue) => onChangeInput(newValue)}
      />
    </LocalizationProvider>
  );
}
