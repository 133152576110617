/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function InputFile({
  inputTestID,
  inputPlaceholder,
  inputSource,
  extensionsFile,
  onChangeInput,
}) {
  const onChangeValue = ({ target: { files } }) => onChangeInput(
    URL.createObjectURL(files[0]),
    files[0],
  );

  return (
    <label htmlFor={inputTestID} style={{ alignContent: 'center' }}>
      <input
        style={{ display: 'none' }}
        id={inputTestID}
        name={inputTestID}
        type="file"
        placeholder="anexar arquivo"
        accept={extensionsFile}
        value={inputSource}
        onChange={onChangeValue}
      />
      <Button
        size="medium"
        variant="contained"
        component="span"
        endIcon={<AttachFileIcon />}
      >
        {inputPlaceholder}
      </Button>
      {' '}
    </label>
  );
}
