const styles = {
  containerChipMonth: {
    color: 'white',
    fontWeight: 'bold',
    width: '90px',
    margin: '4px 6px',
  },
};

export default styles;
