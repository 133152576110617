/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import globalStyles from '../../../../styles/global_styles';
import styles from './styles';

export default function ActionTableList({
  tableTestID,
  tableMessageNoItem,
  tableCells,
  countItems,
  orderName,
  onOrderName,
  orderResponsible,
  onOrderResponsible,
  children,
}) {
  function renderCellNameOrder() {
    if (orderName === '') {
      return (
        <KeyboardArrowRightIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderName()}
        />
      );
    }
    if (orderName === 'name') {
      return (
        <KeyboardArrowDownIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderName()}
        />
      );
    }
    if (orderName === '-name') {
      return (
        <KeyboardArrowUpIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderName()}
        />
      );
    }

    return null;
  }

  function renderCellResponsibleOrder() {
    if (orderResponsible === '') {
      return (
        <KeyboardArrowRightIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderResponsible()}
        />
      );
    }
    if (orderResponsible === 'responsible') {
      return (
        <KeyboardArrowDownIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderResponsible()}
        />
      );
    }
    if (orderResponsible === '-responsible') {
      return (
        <KeyboardArrowUpIcon
          sx={globalStyles.containerIconTable}
          onClick={() => onOrderResponsible()}
        />
      );
    }

    return null;
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label={tableTestID}>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.textTableCell}>
                PLANO DE AÇÃO
                {renderCellNameOrder()}
              </TableCell>
              <TableCell align="left" sx={styles.textTableCell}>
                RESPONSÁVEL
                {renderCellResponsibleOrder()}
              </TableCell>
              {tableCells.map((tableCellLoop) => (
                <TableCell align="left" sx={styles.textTableCell}>
                  {tableCellLoop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sm={10.25} mb={2}>
        {countItems === 0 || countItems === undefined ? (
          <Typography
            variant="body2"
            fontWeight="bold"
            color="text.secondary"
            align="center"
            sx={{ mt: 3.5 }}
          >
            {tableMessageNoItem}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
