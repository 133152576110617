/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderRedirect from '../../components/HeaderRedirect';
import InputOutlined from '../../components/InputOutlined';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import PickerDateOutlined from '../../components/PickerDateOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import ButtonContained from '../../components/ButtonContained';
import ButtonSquareContained from '../../components/ButtonSquareContained';
import InputFile from '../../components/InputFile';

import calendarDateMask from '../../utils/masks/calendarDateMask';

import { MembersFetch, changeMessageWarning } from '../../store/user/actions';
import { AreasFetch } from '../../store/area/actions';
import { IndicatorsFetch, IndicatorByIdFetch } from '../../store/indicator/actions';
import {
  ActionCreateFetch,
  ActionByIdFetch,
  ActionUpdateByIdFetch,
  ActionDeleteByIdFetch,
} from '../../store/action/actions';

const situations = require('../../utils/resources/situations.json');

export default function ActionForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    area,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas } = useSelector((state) => state.area);
  const { isLoadingIndicator, indicators, indicator } = useSelector((state) => state.indicator);
  const { isLoadingAction, action } = useSelector((state) => state.action);

  const [name, setName] = useState('');
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [situationSelected, setSituationSelected] = useState('EM ANDAMENTO');
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [endDates, setEndDates] = useState([]);
  const [cause, setCause] = useState('');
  const [reason, setReason] = useState('');
  const [reasons, setReasons] = useState([]);
  const [causeRoot, setCauseRoot] = useState('');
  const [areaSelected, setAreaSelected] = useState('');
  const [indicatorSelected, setIndicatorSelected] = useState('');
  const [observation, setObservation] = useState('');
  const [file, setFile] = useState('');
  const [files, setFiles] = useState([]);
  const [indicatorsFilteredByArea, setIndicatorsFilteredByArea] = useState([]);
  const [membersToSelect, setMembersToSelect] = useState([]);
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actionId, indicatorId } = useParams();

  useEffect(() => {
    async function getActionById() {
      try {
        dispatch(ActionByIdFetch(token, actionId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getIndicatorById() {
      try {
        dispatch(IndicatorByIdFetch(token, indicatorId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getAreas() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getMembers() {
      try {
        dispatch(MembersFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getIndicators() {
      try {
        dispatch(IndicatorsFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getMembers();
    getAreas();
    getIndicators();
    console.log('actionId: ', actionId);

    if (actionId !== undefined) {
      getActionById();
    }

    if (indicatorId !== undefined) {
      getIndicatorById();
    }
  }, [history]);

  useEffect(() => {
    console.log(
      'useEffect | actionId, action: ',
      actionId,
      action,
    );

    function fillInputsAction() {
      try {
        setName(action.name);
        setResponsibleSelected(`${action.responsible.first_name} ${action.responsible.last_name}`);
        setSituationSelected(action.situation);
        setStartDate(dayjs(action.start_date));
        setEndDates(action.end_dates);
        setCause(action.cause);
        setReasons(action.reasons);
        setCauseRoot(action.cause_root);
        setAreaSelected(action.area.name);
        setIndicatorSelected(action.indicator.name);
        setObservation(action.observation);
        console.log('fillInputsAction | action.files: ', action.files);
        const filesWithName = (action.files).map((fileCycle) => ({ name: fileCycle.file }));
        console.log('fillInputsAction | filesWithName: ', filesWithName);
        setFiles(filesWithName);
      } catch (error) {
        console.log('fillInputsAction | error: ', error);
      }
    }

    if (actionId !== undefined && action !== null) {
      fillInputsAction();
    } else {
      setName('');
      setResponsibleSelected('');
      setSituationSelected('');
      setStartDate(dayjs(new Date()));
      setEndDates([]);
      setCause('');
      setReasons([]);
      setCauseRoot('');
      setAreaSelected('');
      setIndicatorSelected('');
      setObservation('');
      setFiles([]);
    }
  }, [action, actionId]);

  useEffect(() => {
    console.log('useEffect | indicatorId, indicator: ', indicatorId, indicator);
    function fillInputsIndicator() {
      try {
        setResponsibleSelected(`${indicator.responsible.first_name} ${indicator.responsible.last_name}`);
        setAreaSelected(indicator.area.name);
        setIndicatorSelected(indicator.name);
      } catch (error) {
        console.log('fillInputsIndicator | error: ', error);
      }
    }
    if (indicatorId !== undefined && indicator !== null) {
      fillInputsIndicator();
    } else {
      setResponsibleSelected('');
      setAreaSelected('');
      setIndicatorSelected('');
    }
  }, [indicator, indicatorId]);

  useEffect(() => {
    console.log('useEffect | areaSelected: ', areaSelected);
    const indicatorsFromArea = indicators.filter(
      (indicatorCycle) => indicatorCycle.area.name === areaSelected,
    );
    const indicatorsOptions = indicatorsFromArea.map((indicatorTwister) => indicatorTwister.name);
    console.log('useEffect | indicatorsOptions: ', indicatorsOptions);
    setIndicatorsFilteredByArea(indicatorsOptions);

    function fillInputIndicatorByAreaAction() {
      try {
        console.log('fillInputIndicatorByAreaAction | action.indicator.name: ', action.indicator.name);
        const indicatorFromAreaFound = indicatorsOptions.find(
          (indicatorPhase) => indicatorPhase === action.indicator.name,
        );
        console.log('fillInputIndicatorByAreaAction | indicatorFromAreaFound: ', indicatorFromAreaFound);
        if (indicatorFromAreaFound !== undefined) {
          setIndicatorSelected(indicatorFromAreaFound);
        } else {
          setIndicatorSelected('');
        }
      } catch (error) {
        console.log('fillInputIndicatorByAreaAction | error: ', error);
      }
    }

    if (actionId !== undefined && action !== null) {
      fillInputIndicatorByAreaAction();
    }
  }, [areaSelected]);

  useEffect(() => {
    console.log('useEffect | actionId, action: ', actionId, action);
    async function autoFillCauseRoot() {
      try {
        if (reasons.length === 0) {
          setCauseRoot(cause);
        } else if (reasons.length !== 0) {
          setCauseRoot(reasons[reasons.length - 1]);
        }
      } catch (error) {
        console.log('autoFillCauseRoot | error: ', error);
      }
    }

    autoFillCauseRoot();
  }, [cause, reasons]);

  useEffect(() => {
    function fillMembersToSelect() {
      try {
        const membersOptions = members.map((memberTwister) => `${memberTwister.first_name} ${memberTwister.last_name}`);
        setMembersToSelect(membersOptions);
      } catch (error) {
        console.log('fillMembersToSelect | error: ', error);
      }
    }

    if (members !== undefined) {
      fillMembersToSelect();
    }
  }, [members]);

  useEffect(() => {
    function fillAreaSelected() {
      try {
        console.log('fillAreaSelected | area: ', area);
        const areaFound = areas.find((areaCycle) => areaCycle.name === area);
        console.log('fillAreaSelected | areaFound: ', areaFound);
        if (areaFound !== undefined) {
          setAreaSelected(areaFound.name);
        }
      } catch (error) {
        console.log('fillAreaSelected | error: ', error);
      }
    }

    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    fillAreasToSelect();

    console.log('useEffect | areas: ', areas);
    if (areas !== undefined && type !== 'admin') {
      fillAreaSelected();
    }
  }, [areas]);

  function validateAction() {
    console.log(
      'validateAction | name, responsibleSelected, situationSelected, cause, areaSelected, indicatorSelected, observation: ',
      name,
      responsibleSelected,
      situationSelected,
      cause,
      causeRoot,
      areaSelected,
      indicatorSelected,
      observation,
    );
    let message = '';

    if (cause.length < 3) {
      message = 'Campo causa fundamenta é inválido';
    } else if (name.length < 3) {
      message = 'Campo ação é inválido';
    } else if (!responsibleSelected) {
      message = 'Selecione um responsável';
    } else if (endDates.length === 0) {
      message = 'Preencha o campo prazo final';
    } else if (!areaSelected) {
      message = 'Selecione uma área';
    } else if (!indicatorSelected) {
      message = 'Selecione um indicador';
    } else if (!situationSelected) {
      message = 'Selecione uma situação';
    }

    return { isInvalid: !!message, message };
  }

  async function sendActionCreate() {
    try {
      const responseValidateAction = await validateAction();
      console.log('sendActionCreate | responseValidateAction ', responseValidateAction);

      if (responseValidateAction.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateAction.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendActionCreate | responsibleFound: ', responsibleFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendActionCreate | areaFound: ', areaFound);
        const indicatorFound = indicators.find(
          (indicatorCycle) => indicatorCycle.name === indicatorSelected,
        );
        console.log('sendActionCreate | indicatorFound: ', indicatorFound);

        console.log('sendActionUpdate | endDates: ', endDates);
        const endDateWithIso = endDates.map((endDateLoop) => {
          const endDateEndDate = new Date(endDateLoop);
          console.log('endDates.map | endDateEndDate: ', endDateEndDate);
          const endDateEndDateHourLast = new Date(
            endDateEndDate.getFullYear(),
            endDateEndDate.getMonth(),
            endDateEndDate.getDate(),
            23,
            59,
            59,
          );
          console.log('endDates.map | endDateEndDateHourLast: ', endDateEndDateHourLast);
          const endDateEndDateMask = endDateEndDateHourLast.toISOString();
          console.log('endDates.map | endDateEndDateMask: ', endDateEndDateMask);
          return endDateEndDateMask;
        });
        console.log('sendActionUpdate | endDateWithIso: ', endDateWithIso);

        const startDateWithIso = startDate.toISOString();
        console.log('sendActionUpdate | startDateWithIso: ', startDateWithIso);

        dispatch(ActionCreateFetch(
          token,
          name,
          responsibleFound.id,
          situationSelected,
          startDateWithIso,
          endDateWithIso,
          cause,
          reasons,
          causeRoot,
          areaFound.id,
          indicatorFound.id,
          observation,
          files,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendActionCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendActionUpdateById() {
    try {
      const responseValidateActionUpdate = await validateAction();
      console.log('sendActionUpdateById | responseValidateActionUpdate: ', responseValidateActionUpdate);

      if (responseValidateActionUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateActionUpdate.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendActionCreate | responsibleFound: ', responsibleFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendActionUpdate | areaFound: ', areaFound);
        const indicatorFound = indicators.find(
          (indicatorCycle) => indicatorCycle.name === indicatorSelected,
        );
        console.log('sendActionUpdate | indicatorFound: ', indicatorFound);

        console.log('sendActionUpdate | endDates: ', endDates);
        const endDateWithIso = endDates.map((endDateLoop) => {
          const endDateEndDate = new Date(endDateLoop);
          console.log('endDates.map | endDateEndDate: ', endDateEndDate);
          const endDateEndDateHourLast = new Date(
            endDateEndDate.getFullYear(),
            endDateEndDate.getMonth(),
            endDateEndDate.getDate(),
            23,
            59,
            59,
          );
          console.log('endDates.map | endDateEndDateHourLast: ', endDateEndDateHourLast);
          const endDateEndDateMask = endDateEndDateHourLast.toISOString();
          console.log('endDates.map | endDateEndDateMask: ', endDateEndDateMask);
          return endDateEndDateMask;
        });
        console.log('sendActionUpdate | endDateWithIso: ', endDateWithIso);

        const startDateWithIso = startDate.toISOString();
        console.log('sendActionUpdate | startDateWithIso: ', startDateWithIso);

        dispatch(ActionUpdateByIdFetch(
          token,
          actionId,
          name,
          responsibleFound.id,
          situationSelected,
          startDateWithIso,
          endDateWithIso,
          cause,
          reasons,
          causeRoot,
          areaFound.id,
          indicatorFound.id,
          observation,
          files,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendActionUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteActionById() {
    try {
      setIsVisibleDialog(false);
      dispatch(ActionDeleteByIdFetch(token, actionId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function validateEndDate() {
    console.log('validateEndDate | endDate: ', endDate);
    let message = '';
    const currentDate = new Date().setUTCSeconds(0);
    console.log('validateEndDate | currentDate: ', currentDate);

    if (!endDate) {
      message = 'Preencha o campo data de término';
    } else if (endDate < currentDate) {
      message = 'Campo prazo final não pode ser uma data passada';
    }

    return { isInvalid: !!message, message };
  }

  const handleDataDelete = (dataToDelete) => () => {
    console.log('handleDataDelete | dataToDelete: ', dataToDelete);
    const datasUptaded = endDates.filter((dataCycle) => dataCycle !== dataToDelete);
    setEndDates(datasUptaded);
  };

  async function handleEndDateAdd() {
    const responseValidateData = await validateEndDate();
    console.log('handleEndDateAdd | responseValidateData: ', responseValidateData);

    if (responseValidateData.isInvalid) {
      dispatch(changeMessageWarning({
        message: responseValidateData.message,
      }));
    } else {
      console.log('handleEndDateAdd | endDate: ', endDate);
      const datasUptaded = [...endDates, endDate];
      console.log('handleEndDateAdd | datasUptaded: ', datasUptaded);
      setEndDates(datasUptaded);
      setEndDate(dayjs(new Date()));
    }
  }

  function validateReason() {
    console.log('validateReason | reason: ', reason);
    let message = '';

    if (!reason) {
      message = 'Preencha o campo porque';
    } else if (reasons.length >= 5) {
      message = 'Preenchimento do campo porque excedido, máximo 5';
    }

    return { isInvalid: !!message, message };
  }

  const handleReasonDelete = (reasonToDelete) => () => {
    console.log('handleReasonDelete | reasonToDelete: ', reasonToDelete);
    const reasonsUptaded = reasons.filter((reasonCycle) => reasonCycle !== reasonToDelete);
    setReasons(reasonsUptaded);
  };

  async function handleReasonAdd() {
    const responseValidateReason = await validateReason();
    console.log('handleReasonAdd | responseValidateReason: ', responseValidateReason);

    if (responseValidateReason.isInvalid) {
      dispatch(changeMessageWarning({
        message: responseValidateReason.message,
        type: 'warning',
      }));
    } else {
      const reasonsUptaded = [...reasons, reason];
      console.log('handleReasonAdd | reasonsUptaded: ', reasonsUptaded);
      setReasons(reasonsUptaded);
      setReason('');
    }
  }

  const handleFileDelete = (fileToDelete) => () => {
    console.log('handleSubjectDelete | subjectToDelete: ', fileToDelete);
    const filesUptaded = files.filter((fileCycle) => fileCycle !== fileToDelete);
    setFiles(filesUptaded);
  };

  const onAddFile = (urlBlobValue, uriValue) => {
    console.log('handleSubjectAdd | urlBlobValue, uriValue: ', urlBlobValue, uriValue);
    const fileNew = uriValue;
    console.log('handleSubjectAdd | subjectNew: ', fileNew);
    const filesUptaded = [...files, fileNew];
    console.log('handlefileAdd | filesUptaded: ', filesUptaded);
    setFiles(filesUptaded);
    setFile('');
  };

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendAction = () => {
    if (actionId !== undefined) {
      sendActionUpdateById();
    } else {
      sendActionCreate();
    }
  };

  const redirectToActionPdf = () => {
    const baseUrl = process.env.REACT_APP_API;
    const redirectUrl = `${baseUrl}/actions/action_pdf_by_id?action_id=${actionId}`;
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle={`${actionId !== undefined ? 'Editar' : 'Criar'} plano de ação`}>
      <ContainerPaper>
        <HeaderRedirect
          title="Informações do plano de ação"
          onBack={() => navigate('/actions', { replace: true })}
          onRedirect={redirectToActionPdf}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5.25}>
            <InputOutlined
              inputTestID="TextInputCauseID"
              inputPlaceholder="Digite aqui a causa fundamental"
              inputValue={cause}
              onChangeInput={(textValue) => setCause(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={5.25}>
            <InputOutlined
              inputTestID="TextInputReasonID"
              inputPlaceholder="Digite aqui o porque"
              inputValue={reason}
              onChangeInput={(textValue) => setReason(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleReasonAdd()}
            />
          </Grid>
          {reasons.length >= 1
            ? (
              <Grid item xs={12}>
                {reasons.map((reasonLoop, index) => {
                  const reasonLabel = reasonLoop;
                  return (
                    <Chip
                      sx={{ mt: 0.5, ml: 1 }}
                      label={`Porque ${index + 1}: ${reasonLabel}`}
                      color="primary"
                      onDelete={handleReasonDelete(reasonLoop)}
                    />
                  );
                })}
              </Grid>
            ) : null}
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputCauseRootID"
              inputPlaceholder="Causa raiz"
              inputValue={causeRoot}
              onChangeInput={(textValue) => setCauseRoot(textValue)}
              isSecure={false}
              isDisable
            />
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <InputOutlined
              inputTestID="TextInputNameID"
              inputPlaceholder="Digite aqui a ação"
              inputValue={name}
              onChangeInput={(textValue) => setName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersToSelect}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <PickerDateOutlined
              inputTestID="PickerDateEndDatePendencyID"
              inputPlaceholder="Prazo Final"
              inputValue={endDate}
              onChangeInput={(newValue) => setEndDate(newValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleEndDateAdd()}
            />
          </Grid>
          {endDates.length >= 1
            ? (
              <Grid item xs={12}>
                {endDates.map((endDateLoop) => {
                  const endDateEndDate = new Date(endDateLoop);
                  console.log('endDates.map | endDateEndDate: ', endDateEndDate);
                  const endDateEndDateMask = calendarDateMask(endDateEndDate.toISOString());
                  console.log('endDates.map | endDateEndDateMask: ', endDateEndDateMask);

                  const labelEndDate = `Prazo Final: ${endDateEndDateMask}`;
                  return (
                    <Chip
                      sx={{ mt: 0.7, ml: 1 }}
                      label={labelEndDate}
                      color="primary"
                      onDelete={handleDataDelete(endDateLoop)}
                    />
                  );
                })}
              </Grid>
            ) : null}
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há áreas"
              selectValue={areaSelected}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelected(optionValue)}
              isDisable={type !== 'admin'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectIndicatorID"
              selectPlaceholder="Indicador"
              selectPlaceholderNoItem="Não há indicadores relacionados a área selecionada"
              selectValue={indicatorSelected}
              selectOptions={indicatorsFilteredByArea}
              onChangeSelect={(optionValue) => setIndicatorSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectSituationID"
              selectPlaceholder="Situação"
              selectPlaceholderNoItem="Não há indicadores relacionados a área selecionada"
              selectValue={situationSelected}
              selectOptions={situations}
              onChangeSelect={(optionValue) => setSituationSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputObservationID"
              inputPlaceholder="Digite aqui a observação"
              inputValue={observation}
              onChangeInput={(textValue) => setObservation(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <InputFile
              inputTestID="upload-spreadsheet"
              inputPlaceholder="ANEXAR ARQUIVO"
              inputSource={file}
              extensionsFile="image/pdf/video*"
              onChangeInput={onAddFile}
            />
          </Grid>
          {files.length >= 1
            ? (
              <Grid item xs={12}>
                {files.map((fileLoop) => {
                  const fileLabelFile = fileLoop.name;
                  return (
                    <Chip
                      sx={{ mt: 0.7, ml: 1 }}
                      label={`Arquivo: ${fileLabelFile}`}
                      color="primary"
                      onClick={() => (fileLabelFile.includes('https://static')
                        ? window.open(fileLabelFile, '_blank') : null)}
                      onDelete={handleFileDelete(fileLoop)}
                    />
                  );
                })}
              </Grid>
            ) : null}
          {' '}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: actionId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {actionId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}

          <ButtonContained
            label={actionId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendAction}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={
        isLoadingUser
        || isLoadingAction
        || isLoadingArea
        || isLoadingIndicator
        }
      />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteActionById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir esse plano de ação?"
      />
    </MiniDrawer>
  );
}
