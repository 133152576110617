/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import styles from './styles';

import calculeProgressColorBiggerThen from '../../../../utils/calculeProgressColorBiggerThen';
import calculeProgressColorLessThen from '../../../../utils/calculeProgressColorLessThen';

const progressColors = require('../../../../utils/resources/progressColors.json');

export default function ProgressChips({ chipsResult }) {
  return (
    <Box>
      {progressColors.map((progressColorLoop, index) => {
        const resultsWithStatusLoop = chipsResult.filter(
          (resultCycle) => {
            if (resultCycle.criterion === 'MAIOR QUE') {
              const currentProgressColorBiggerThen = calculeProgressColorBiggerThen(
                resultCycle.done_percentage,
              );
              console.log(
                'calculeProgressColorBiggerThen | currentProgressColorBiggerThen: ',
                currentProgressColorBiggerThen,
              );
              return progressColorLoop === currentProgressColorBiggerThen;
            }
            if (resultCycle.criterion === 'MENOR QUE') {
              const currentProgressColorLessThen = calculeProgressColorLessThen(
                resultCycle.done_percentage,
              );
              // console.log
              //   'calculeProgressColorLessThen | currentProgressColorLessThen: ',
              //   currentProgressColorLessThen,
              // );
              return progressColorLoop === currentProgressColorLessThen;
            }
            return false;
          },
        );
        const countResultsStatus = resultsWithStatusLoop.length;
        // console.log('status.map | countResultsStatus: ', countResultsStatus);
        const countResults = chipsResult.length;
        // console.log('status.map | countResults: ', countResults);
        const percentageResultsStatus = countResults !== 0
          ? (countResultsStatus * 100) / countResults : 0;
        // console.log('status.map | percentageResultsStatus: ', percentageResultsStatus);
        const percentageResultsStatusRound = Math.round(percentageResultsStatus);
        // console.log('status.map | percentageResultsStatusRound: ', percentageResultsStatusRound);
        const label = `${percentageResultsStatusRound}%`;
        return (
          <Chip
            variant="filled"
            label={label}
            sx={{
              backgroundColor: progressColors[index],
              ...styles.containerChipProgress,
            }}
            size="medium"
          />
        );
      })}
    </Box>
  );
}
