/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import globalStyles from '../../styles/global_styles';

export default function InputSearcRounded({
  inputTestID,
  inputPlaceholder,
  inputValue,
  inputWidth,
  onChangeInput,
}) {
  const onChangeValue = ({ currentTarget: { value } }) => {
    onChangeInput(value);
  };

  return (
    <TextField
      sx={[globalStyles.inputSearch, { width: inputWidth }]}
      id={inputTestID}
      name={inputTestID}
      label={inputPlaceholder}
      fullWidth
      autoComplete="family-name"
      variant="outlined"
      value={inputValue}
      onChange={onChangeValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={globalStyles.inputSearchAndorment}>
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
