/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircleIcon from '@mui/icons-material/Circle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import TableBodySublevel from '../TableBodySublevel';

import calculeProgressColorBiggerThen from '../../../../utils/calculeProgressColorBiggerThen';
import calculeProgressColorLessThen from '../../../../utils/calculeProgressColorLessThen';
import numericDotMask from '../../../../utils/masks/numericDotMask';

const progressColors = require('../../../../utils/resources/progressColors.json');

export default function ResultItem({ itemResult, onAction }) {
  const [isCollapse, setIsCollapse] = useState(false);
  const [colorStatus, setColorStatus] = useState(progressColors[0]);
  const [sublevelsLength, setSublevelsLength] = useState(0);

  const navigate = useNavigate();

  async function countIndicadorSublevels() {
    try {
      const indicatorsCount = (itemResult.sublevels).length;
      console.log('countIndicadorSublevels | indicatorsCount: ', indicatorsCount);
      setSublevelsLength(indicatorsCount);
    } catch (error) {
      console.log('countIndicadorSublevels | error: ', error);
      setSublevelsLength(0);
    }
  }

  useEffect(() => {
    console.log('useEffect | itemResult: ', itemResult);

    if (itemResult.criterion === 'MAIOR QUE') {
      const currentProgressColorBiggerThen = calculeProgressColorBiggerThen(itemResult.done_percentage);
      // console.log('calculeProgressColorBiggerThen | currentProgressColorBiggerThen: ', currentProgressColorBiggerThen);
      setColorStatus(currentProgressColorBiggerThen);
    }
    if (itemResult.criterion === 'MENOR QUE') {
      const currentProgressColorLessThen = calculeProgressColorLessThen(itemResult.done_percentage);
      // console.log('calculeProgressColorLessThen | currentProgressColorLessThen: ', currentProgressColorLessThen);
      setColorStatus(currentProgressColorLessThen);
    }
    countIndicadorSublevels();
  }, [itemResult]);

  return (
    <>
      <TableRow
        key={itemResult.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {sublevelsLength >= 1
          ? (
            <TableCell>
              <IconButton
                aria-label={`button collapse ${itemResult.name}`}
                size="small"
                onClick={() => setIsCollapse(!isCollapse)}
              >
                {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (<TableCell />)}
        <TableCell component="th" scope="row">
          {itemResult.name}
        </TableCell>
        <TableCell align="center">
          {itemResult.goal !== null && itemResult.goal !== undefined ? numericDotMask(itemResult.goal.goal) : '0'}
        </TableCell>
        <TableCell align="center">{numericDotMask(itemResult.done)}</TableCell>
        <TableCell align="center">{numericDotMask(itemResult.linear_trend)}</TableCell>
        <TableCell align="center">{`${itemResult.done_percentage}%`}</TableCell>
        {itemResult.goal !== null && itemResult.goal !== undefined ? (
          <TableCell
            align="center"
            sx={{
              fontWeight: 'bold',
              color: colorStatus,
            }}
          >
            <CircleIcon />
          </TableCell>
        ) : (
          <TableCell
            align="center"
            sx={{
              fontWeight: 'bold',
              color: '#7388A95A',
            }}
          >
            <CircleIcon />
          </TableCell>
        )}
        <TableCell align="center" sx={{ color: '#f66600' }}>
          <AssessmentIcon onClick={() => navigate(`/results/${itemResult.id}/chart`, { replace: true })} />
        </TableCell>
        {itemResult.action !== null && itemResult.action.situation !== 'CONCLUIDO' && itemResult.action.situation !== 'CANCELADO' ? (
          <TableCell align="center" sx={{ color: '#34bfa3' }}>
            <CircleIcon />
          </TableCell>
        ) : (
          <TableCell align="center">
            <CircleOutlinedIcon />
          </TableCell>
        )}
        <TableCell align="center">
          <AddBoxIcon onClick={() => onAction()} />
        </TableCell>
      </TableRow>
      {sublevelsLength >= 1
        ? (
          <TableBodySublevel
            sublevelsParam={itemResult.sublevels}
            isVisible={isCollapse}
          />
        ) : null }
    </>
  );
}
