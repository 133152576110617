/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ON_SECONDARY_COLOR } from '../../../../styles/colors';
import styles from './styles';

import calendarDateMask from '../../../../utils/masks/calendarDateMask';

export default function CardMeeting({ meetingParam, onAction }) {
  const isMatches = useMediaQuery('(min-width:1200px)');
  console.log('CardMeeting | isMatches: ', isMatches);

  return (
    <Grid item xs={12} sm={isMatches ? 2 : 3}>
      <Card
        elevation={2}
        sx={styles.containerCardMeeting}
        style={{ backgroundColor: ON_SECONDARY_COLOR }}
        onClick={() => onAction()}
      >
        <Box sx={{ p: 1, mb: -1 }}>
          <CardContent>
            <div style={styles.containerCardContent}>
              <LayersOutlinedIcon fontSize="small" />
              <CircleIcon color={meetingParam.has_pendencie_or_decision ? 'success' : 'error'} fontSize="small" />
            </div>
            <div style={styles.containerCardContent}>
              <div style={styles.containerCardContentSpacing}>
                <Typography sx={styles.textCardContentLabel} fontWeight="bold" color="text.primary">
                  Nº
                </Typography>
                <Typography sx={styles.textCardContentValue} color="text.secondary" gutterBottom>
                  {meetingParam.id}
                </Typography>
              </div>
              <div>
                <Typography sx={styles.textCardContentLabel} fontWeight="bold" color="text.primary">
                  Tema
                </Typography>
                <Typography sx={styles.textCardContentValue} color="text.secondary" gutterBottom>
                  {meetingParam.meeting_type}
                </Typography>
              </div>
            </div>
            <div style={styles.containerCardContent}>
              <div style={styles.containerCardContentSpacing}>
                <Typography sx={styles.textCardContentLabel} fontWeight="bold" color="text.primary">
                  Coordenador
                </Typography>
                <Typography sx={styles.textCardContentValue} color="text.secondary" gutterBottom>
                  {`${meetingParam.coordinator.first_name} ${meetingParam.coordinator.last_name}`}
                </Typography>
              </div>
              <div>
                <Typography sx={styles.textCardContentLabel} fontWeight="bold" color="text.primary">
                  Data
                </Typography>
                <Typography sx={styles.textCardContentValue} color="text.secondary" gutterBottom>
                  {calendarDateMask(meetingParam.start_date)}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
}
