/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Collapse from '@mui/material/Collapse';

import RoleItem from '../RoleItem';

export default function RoleItems({ itemsRole, isVisible }) {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#e6e6e6' }} colSpan={12}>
        <Collapse in={isVisible} timeout="auto" unmountOnExit>
          <Table aria-label="roles">
            <TableBody>
              {itemsRole.map((roleLoop) => (
                <RoleItem
                  itemRole={roleLoop}
                  onAction={() => navigate(`/areas/${roleLoop.id}/role/edit`, { replace: true })}
                />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
