/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import styles from './styles';

export default function MonthChips({
  chipsArea,
  chipValue,
  onUpdate,
}) {
  return (chipsArea !== undefined
    ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 1 }}>
        {chipsArea.map((areaLoop) => (
          <Chip
            variant={chipValue === areaLoop.id ? 'outlined' : 'filled'}
            onClick={() => onUpdate(areaLoop)}
            color="secondary"
            label={areaLoop.name}
            sx={styles.containerChipArea}
            size="medium"
          />
        ))}
      </Box>
    )
    : null);
}
