import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { userSlice } from './reducer';

export const {
  userLoading,
  changeMessageWarning,
  signInSuccess,
  profileSuccess,
  areasSuccess,
  changeMembersCount,
  membersSucess,
  memberSucess,
  signOut,
} = userSlice.actions;

export const MembersFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('MembersFetch');
  const configRequest = {
    method: 'get',
    url: '/users/members',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MembersFetch | response.data:', response.data);

    dispatch(membersSucess({ members: response.data.members }));
  } catch (error) {
    console.log('MembersFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MembersBySearchFetch = (tokenAuthorization, searchText, page) => async (dispatch) => {
  console.log(
    'MembersBySearchFetch | tokenAuthorization, searchText, page:',
    tokenAuthorization,
    searchText,
    page,
  );
  const configRequest = {
    method: 'get',
    url: `/users/search_members?name=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MembersBySearchFetch | response.data:', response.data);
    dispatch(changeMembersCount({ count: response.data.count }));
    dispatch(membersSucess({ members: response.data.members }));
  } catch (error) {
    console.log('MembersBySearchFetch | error:', error.message);
    dispatch(userLoading({ isLoading: true }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MembersByPageFetch = (tokenAuthorization, page) => async (dispatch) => {
  console.log('MembersByPageFetch | page: ', page);
  const configRequest = {
    method: 'get',
    url: `/users/members_by_page?page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MembersByPageFetch | response.data:', response.data);

    dispatch(changeMembersCount({ count: response.data.count }));
    dispatch(membersSucess({ members: response.data.results }));
  } catch (error) {
    console.log('MembersByPageFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MemberCreateFetch = (
  tokenAuthorization,
  firstName,
  lastName,
  email,
  typeChosen,
  areaId,
  roleId,
  password,
  avatar,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'MemberCreateFetch | tokenAuthorization, firstName, lastName, email, typeChosen, areaId, roleId, password: ',
    tokenAuthorization,
    firstName,
    lastName,
    email,
    typeChosen,
    areaId,
    roleId,
    password,
    avatar,
  );
  const data = new FormData();

  data.append('first_name', firstName);
  data.append('last_name', lastName);
  data.append('email', email);
  data.append('user_type', typeChosen);
  data.append('area_id', areaId);
  data.append('role_id', roleId);
  data.append('password', password);
  data.append('profile_picture', avatar);

  const configRequest = {
    method: 'post',
    url: '/users/create_member',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  try {
    dispatch(userLoading({ isLoading: true }));
    console.log('MemberCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('MemberCreateFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/members', { replace: true });
  } catch (error) {
    console.log('MemberCreateFetch | error:', error);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const MemberByIdFetch = (tokenAuthorization, memberId) => async (dispatch) => {
  console.log('MemberByIdFetch | tokenAuthorization:', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: `/users/member_by_id?member_id=${memberId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MemberByIdFetch | response.data:', response.data);
    dispatch(memberSucess({ member: response.data.member }));
  } catch (error) {
    console.log('MemberByIdFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MemberUpdateByIdFetch = (
  tokenAuthorization,
  memberId,
  firstName,
  lastName,
  email,
  typeChosen,
  areaId,
  roleId,
  avatar,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'MemberUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, typeChosen, areaId, roleId:',
    tokenAuthorization,
    memberId,
    firstName,
    lastName,
    email,
    typeChosen,
    areaId,
    roleId,
    avatar,
    navigateToPage,
  );

  const data = new FormData();

  data.append('first_name', firstName);
  data.append('last_name', lastName);
  data.append('email', email);
  data.append('user_type', typeChosen);
  data.append('area_id', areaId);
  data.append('role_id', roleId);
  data.append('profile_picture', avatar);
  data.append('member_id', memberId);

  const configRequest = {
    method: 'patch',
    url: '/users/update_member_by_id',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  try {
    dispatch(userLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('MemberUpdateByIdFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/members', { replace: true });
  } catch (error) {
    console.log('MemberUpdateByIdFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const MemberDeleteByIdFetch = (
  tokenAuthorization,
  memberId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'MemberDeleteByIdFetch | tokenAuthorization, memberId, navigateToPage:',
    tokenAuthorization,
    memberId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/users/delete_member_by_id',
    data: { member_id: memberId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MemberDeleteByIdFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/members', { replace: true });
  } catch (error) {
    console.log('MemberDeleteByIdFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const SignInFetch = (
  email,
  password,
  navigateToPage,
) => async (dispatch) => {
  console.log('SignInFetch | email, password:', email, password);
  const configRequest = {
    method: 'post',
    url: '/users/sign_in',
    data: { email, password },
  };
  try {
    dispatch(userLoading({ isLoading: true }));

    console.log('SignInFetch | configRequest:', configRequest);
    const response = await Axios(configRequest);
    console.log('SignInFetch | response.data:', response.data);

    dispatch(signInSuccess({
      token: `Token ${response.data.token}`,
      ...response.data.user,
    }));
    navigateToPage('/profile', { replace: true });
  } catch (error) {
    console.log('SignInFetch | error:', error);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401, 404];
    if (expectedStatusCodes.includes(error.response.status)) {
      console.log('SignInFetch | error.response.data:', error.response.data);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const ProfileFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('ProfileFetch | tokenAuthorization:', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/users/profile',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ProfileFetch | response.data:', response.data);
    dispatch(profileSuccess(response.data.user));
  } catch (error) {
    console.log('ProfileFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ProfileUpdateFetch = (
  tokenAuthorization,
  firstName,
  lastName,
  email,
  areaId,
  roleId,
  avatar,
) => async (dispatch) => {
  console.log(
    'ProfileUpdateFetch | tokenAuthorization, firstName, lastName, email, areaId, roleId:',
    tokenAuthorization,
    firstName,
    lastName,
    email,
    areaId,
    roleId,
    avatar,
  );

  const data = new FormData();

  data.append('first_name', firstName);
  data.append('last_name', lastName);
  data.append('email', email);
  data.append('area_id', areaId);
  data.append('role_id', roleId);
  data.append('profile_picture', avatar);

  const configRequest = {
    method: 'patch',
    url: '/users/profile',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  try {
    dispatch(userLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('ProfileUpdateFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    dispatch(ProfileFetch(tokenAuthorization));
  } catch (error) {
    console.log('ProfileUpdateFetch | error:', error.message);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const RedefinePasswordFetch = (
  tokenAuthorization,
  password,
  passwordNew,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'RedefinePasswordFetch | tokenAuthorization, password, passwordNew: ',
    tokenAuthorization,
    password,
    passwordNew,
  );
  const configRequest = {
    method: 'post',
    url: '/users/change_password',
    data: { password, new_password: passwordNew },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(userLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('RedefinePasswordFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/profile', { replace: true });
  } catch (error) {
    console.log('RedefinePasswordFetch | error:', error);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      console.log('RedefinePasswordFetch | error.response.data:', error.response.data);
      message = error.response.data.message;
    } else {
      console.log('RedefinePasswordFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers,
        ...configRequest.data,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const ForgotPasswordFetch = (email, navigateToPage) => async (dispatch) => {
  console.log('ForgotPasswordFetch | email: ', email);
  const configRequest = {
    method: 'post',
    url: '/users/forgot_password',
    data: { email },
  };

  try {
    dispatch(userLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('ForgotPasswordFetch | response.data:', response.data);
    dispatch(userLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/', { replace: true });
  } catch (error) {
    console.log('ForgotPasswordFetch | error: ', error);
    dispatch(userLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [404];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};
