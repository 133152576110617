/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ButtonOutlined from '../../components/ButtonOutlined';
import TableList from '../../components/TableList';
import AreaItems from './components/AreaItems';

import { changeMessageWarning } from '../../store/user/actions';
import { AreasByPageFetch } from '../../store/area/actions';

export default function Pendency({ history }) {
  const { typeWarning, messageWarning } = useSelector((state) => state.user);
  const { isLoadingArea, areas, areasCount } = useSelector((state) => state.area);

  const [areasPerPage, setAreasPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getAreasByPage(currentPage) {
    try {
      dispatch(AreasByPageFetch(currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getAreasByPage(page);
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAreasByPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setAreasPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <MiniDrawer pageTitle="Áreas">
      <ContainerPaper>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <ButtonOutlined
            label="CRIAR ÁREA"
            onAction={() => navigate('/areas/create', { replace: true })}
          />
        </Box>
        <TableList
          tableTestID="TableAreaID"
          tableMessageNoItem="Não há registro de área"
          tableCells={['', '', 'NOME']}
          countItems={areas.length}
        >
          <AreaItems itemsArea={areas} />
        </TableList>
        <TablePagination
          rowsPerPageOptions={[areasPerPage]}
          component="div"
          count={areasCount}
          rowsPerPage={areasPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingArea} />
    </MiniDrawer>
  );
}
