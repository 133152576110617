/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ModalDialog({
  isVisible,
  onAction,
  onClose,
  title,
  description,
}) {
  return (
    <Dialog
      open={isVisible}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onClose()}>
          CANCELAR
        </Button>
        <Button variant="contained" onClick={() => onAction()}>
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
