/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import calendarDateMask from '../../../../utils/masks/calendarDateMask';

const situations = require('../../../../utils/resources/situations.json');
const situationColors = require('../../../../utils/resources/situationColors.json');

export default function ActionItems({
  itemsAction,
  onAction,
}) {
  return (
    itemsAction !== undefined ? (itemsAction.map(
      (actionLoop) => (
        <TableRow
          key={actionLoop.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={() => onAction(actionLoop.id)}
        >
          <TableCell align="left">
            {actionLoop.name}
          </TableCell>
          <TableCell align="left">
            {`${actionLoop.responsible.first_name} ${actionLoop.responsible.last_name}`}
          </TableCell>
          <TableCell align="left">{calendarDateMask(actionLoop.end_dates[0])}</TableCell>
          <TableCell
            align="left"
            sx={{
              fontWeight: 'bold',
              color: situationColors[situations.indexOf(actionLoop.situation)],
            }}
          >
            {actionLoop.situation}
          </TableCell>
        </TableRow>
      ),
    )) : null
  );
}
