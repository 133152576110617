import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { meetingSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  meetingLoading,
  meetingsSucess,
  changeMeetingsCount,
  meetingSucess,
} = meetingSlice.actions;

export const MeetingsFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('MeetingsFetch');
  const configRequest = {
    method: 'get',
    url: '/meetings/meetings_by_user',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MeetingsFetch | response.data:', response.data);

    dispatch(meetingsSucess({ meetings: response.data.meetings }));
  } catch (error) {
    console.log('MeetingsFetch | error:', error.message);
    dispatch(meetingLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MeetingsByPageFetch = (tokenAuthorization, page) => async (dispatch) => {
  console.log('MeetingsByPageFetch | page: ', page);
  const configRequest = {
    method: 'get',
    url: `/meetings/meetings_by_page?page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MeetingsByPageFetch | response.data:', response.data);

    dispatch(changeMeetingsCount({ count: response.data.count }));
    dispatch(meetingsSucess({ meetings: response.data.results }));
  } catch (error) {
    console.log('MeetingsByPageFetch | error:', error.message);
    dispatch(meetingLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MeetingBySearchFetch = (tokenAuthorization, searchText, page) => async (dispatch) => {
  console.log('MeetingBySearchFetch | tokenAuthorization, searchText, page):', tokenAuthorization, searchText, page);
  const configRequest = {
    method: 'get',
    url: `/meetings/meetings_by_search?text=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MeetingBySearchFetch | response.data:', response.data);
    dispatch(changeMeetingsCount({ count: response.data.count }));
    dispatch(meetingsSucess({ meetings: response.data.results }));
  } catch (error) {
    console.log('MeetingBySearchFetch | error:', error.message);
    dispatch(meetingLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MeetingCreateFetch = (meetingToCreate) => async (dispatch) => {
  console.log(
    'MeetingCreateFetch | meetingToCreate: ',
    meetingToCreate,
  );

  const configRequest = {
    method: 'post',
    url: '/meetings/create_meeting',
    data: meetingToCreate.body,
    headers: { Authorization: meetingToCreate.tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));
    console.log('MeetingCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('MeetingCreateFetch | response.data:', response.data);
    dispatch(meetingLoading({ isLoading: false }));
    dispatch(changeMessageWarning({
      message: response.data.message,
      type: 'success',
    }));
    meetingToCreate.navigateToPage('/meetings', { replace: true });
  } catch (error) {
    console.log('MeetingCreateFetch | error:', error);
    dispatch(meetingLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      console.log('MeetingCreateFetch | error.response.data.message:', error.response.data.message);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const MeetingByIdFetch = (tokenAuthorization, meetingId) => async (dispatch) => {
  console.log('MeetingByIdFetch | tokenAuthorization, meetingId:', tokenAuthorization, meetingId);
  const configRequest = {
    method: 'get',
    url: `/meetings/meeting_by_id?meeting_id=${meetingId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MeetingByIdFetch | response.data:', response.data);
    dispatch(meetingSucess({ meeting: response.data.meeting }));
  } catch (error) {
    console.log('MeetingByIdFetch | error:', error.message);
    dispatch(meetingLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const MeetingUpdateByIdFetch = (meetingToUpdate) => async (dispatch) => {
  console.log(
    'MeetingUpdateByIdFetch | meetingToUpdate:',
    meetingToUpdate,
  );

  const configRequest = {
    method: 'patch',
    url: '/meetings/update_meeting_by_id',
    data: meetingToUpdate.body,
    headers: { Authorization: meetingToUpdate.tokenAuthorization },
  };
  console.log('MeetingUpdateByIdFetch configRequest.data', configRequest.data);

  try {
    console.log('MeetingUpdateByIdFetch | configRequest.data:', configRequest.data);
    dispatch(meetingLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('MeetingUpdateByIdFetch | response.data:', response.data);
    dispatch(meetingLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    meetingToUpdate.navigateToPage('/meetings', { replace: true });
  } catch (error) {
    console.log('MeetingUpdateByIdFetch | error:', error);
    dispatch(meetingLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      console.log('MeetingUpdateByIdFetch | error.response.data.message:', error.response.data.message);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const MeetingDeleteByIdFetch = (
  tokenAuthorization,
  meetingId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'MeetingDeleteByIdFetch | tokenAuthorization, meetingId, navigateToPage:',
    tokenAuthorization,
    meetingId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/meetings/delete_meeting_by_id',
    data: { meeting_id: meetingId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(meetingLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('MeetingDeleteByIdFetch | response.data:', response.data);
    dispatch(meetingLoading({ isLoading: false }));
    navigateToPage('/meetings', { replace: true });
  } catch (error) {
    console.log('MeetingDeleteByIdFetch | error:', error);
    dispatch(meetingLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [401];
    if (expectedStatusCodes.includes(error.response.status)) {
      console.log('MeetingDeleteByIdFetch | error.response.data.message:', error.response.data.message);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};
