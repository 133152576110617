/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import InputOutlined from '../../components/InputOutlined';
import PickerDateOutlined from '../../components/PickerDateOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import ButtonContained from '../../components/ButtonContained';

import { AreasFetch } from '../../store/area/actions';
import { MembersFetch, changeMessageWarning } from '../../store/user/actions';
import {
  PendencyCreateFetch,
  PendencyByIdFetch,
  PendencyUpdateByIdFetch,
  PendencyDeleteByIdFetch,
} from '../../store/pendency/actions';

const situations = require('../../utils/resources/situations.json');

export default function PendencyForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    type,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingPendency, pendency } = useSelector((state) => state.pendency);

  const [description, setDescription] = useState('');
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [situationSelected, setSituationSelected] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [observation, setObservation] = useState('');
  const [membersToSelect, setMembersToSelect] = useState([]);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pendencyId } = useParams();

  useEffect(() => {
    async function getPendencyById() {
      try {
        dispatch(PendencyByIdFetch(token, pendencyId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getRoles() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getMembers() {
      try {
        dispatch(MembersFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getMembers();
    getRoles();
    console.log('pendencyId: ', pendencyId);

    if (pendencyId !== undefined) {
      getPendencyById();
    }
  }, [history]);

  useEffect(() => {
    function fillInputsPendency() {
      try {
        setDescription(pendency.description);
        setResponsibleSelected(`${pendency.responsible.first_name} ${pendency.responsible.last_name}`);
        setSituationSelected(pendency.situation);
        setStartDate(pendency.start_date);
        setEndDate(dayjs(pendency.end_date));
        setObservation(pendency.observation);
      } catch (error) {
        console.log('fillInputsPendency | error: ', error);
      }
    }

    console.log('useEffect | pendencyId, pendency: ', pendencyId, pendency);
    if (pendencyId !== undefined && pendency !== null) {
      fillInputsPendency();
    } else {
      setDescription('');
      setResponsibleSelected('');
      setSituationSelected('');
      setStartDate(dayjs(new Date()));
      setEndDate(dayjs(new Date()));
      setObservation('');
    }
  }, [pendency, pendencyId]);

  useEffect(() => {
    function fillMembersToSelect() {
      try {
        const membersOptions = members.map((memberTwister) => `${memberTwister.first_name} ${memberTwister.last_name}`);
        setMembersToSelect(membersOptions);
      } catch (error) {
        console.log('fillMembersToSelect | error: ', error);
      }
    }

    if (members !== undefined) {
      fillMembersToSelect();
    }
  }, [members]);

  function validatePendency() {
    console.log(
      'validatePendency | description, responsibleSelected, situationSelected, observation: ',
      description,
      responsibleSelected,
      situationSelected,
      observation,
    );
    let message = '';

    if (!description) {
      message = 'Preencha o campo descrição';
    } else if (description.length < 3) {
      message = 'Campo descrição é inválido';
    } else if (!responsibleSelected) {
      message = 'Selecione um responsável';
    } else if (!situationSelected) {
      message = 'Selecione uma situação';
    }

    return { isInvalid: !!message, message };
  }

  async function sendPendencyCreate() {
    try {
      const responseValidatePendency = await validatePendency();
      console.log('sendPendencyCreate | responseValidatePendency ', responseValidatePendency);

      if (responseValidatePendency.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidatePendency.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendPendencyCreate | responsibleFound: ', responsibleFound);

        const endDatetime = new Date(endDate);

        const endDateEndDateHourLast = new Date(
          endDatetime.getFullYear(),
          endDatetime.getMonth(),
          endDatetime.getDate(),
          23,
          59,
          59,
        );

        dispatch(PendencyCreateFetch(
          token,
          description,
          responsibleFound.id,
          situationSelected,
          startDate,
          endDateEndDateHourLast,
          observation,
          null,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendPendencyCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendPendencyUpdateById() {
    try {
      const responseValidatePendencyUpdate = await validatePendency();
      console.log('sendPendencyUpdateById | responseValidatePendencyUpdate: ', responseValidatePendencyUpdate);

      if (responseValidatePendencyUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidatePendencyUpdate.message,
          type: 'warning',
        }));
      } else {
        const responsibleFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
        );
        console.log('sendPendencyCreate | responsibleFound: ', responsibleFound);

        const endDatetime = new Date(endDate);

        const endDateEndDateHourLast = new Date(
          endDatetime.getFullYear(),
          endDatetime.getMonth(),
          endDatetime.getDate(),
          23,
          59,
          59,
        );

        dispatch(PendencyUpdateByIdFetch(
          token,
          pendencyId,
          description,
          responsibleFound.id,
          situationSelected,
          startDate,
          endDateEndDateHourLast,
          observation,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendPendencyUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deletePendencyById() {
    try {
      setIsVisibleDialog(false);
      dispatch(PendencyDeleteByIdFetch(token, pendencyId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendPendency = () => {
    if (pendencyId !== undefined) {
      sendPendencyUpdateById();
    } else {
      sendPendencyCreate();
    }
  };

  return (
    <MiniDrawer pageTitle={`${pendencyId !== undefined ? 'Editar' : 'Criar'} pendência`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações da pendência"
          onBack={() => navigate('/pendencies', { replace: true })}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputOutlined
              inputTestID="TextInputDescriptionID"
              inputPlaceholder="Digite aqui qual a pendência"
              inputValue={description}
              onChangeInput={(textValue) => setDescription(textValue)}
              isSecure={false}
              isDisable={type === 'default_user'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersToSelect}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={type === 'default_user'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectSituationID"
              selectPlaceholder="Situação"
              selectPlaceholderNoItem="Não há situação"
              selectValue={situationSelected}
              selectOptions={situations}
              onChangeSelect={(optionValue) => setSituationSelected(
                optionValue,
              )}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PickerDateOutlined
              inputTestID="PickerDateEndDateID"
              inputPlaceholder="Prazo Final"
              inputValue={endDate}
              onChangeInput={(newValue) => setEndDate(newValue)}
              isDisable={type === 'default_user'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputOutlined
              inputTestID="TextInputObservationID"
              inputPlaceholder="Digite aqui a observação"
              inputValue={observation}
              onChangeInput={(textValue) => setObservation(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: pendencyId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {pendencyId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}

          <ButtonContained
            label={pendencyId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendPendency}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingPendency} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deletePendencyById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="realmente deseja excluir essa pendência?"
      />
    </MiniDrawer>
  );
}
