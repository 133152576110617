/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';

import DrawerBar from './components/DrawerBar';
import DrawerList from './components/DrawerList';

export default function MiniDrawer({ pageTitle, children }) {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const isMatches = useMediaQuery('(min-width:795px)');

  const onDrawerOpen = () => {
    setIsOpenDrawer(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerBar
        title={pageTitle}
        isOpen={isOpenDrawer}
        onClose={onDrawerOpen}
      />
      {isMatches || isOpenDrawer ? (
        <DrawerList
          isVisible={isOpenDrawer}
          onUpdateVisible={(openUpdated) => setIsOpenDrawer(openUpdated)}
        />
      ) : null }
      {children}
    </Box>
  );
}
