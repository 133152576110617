/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import useMediaQuery from '@mui/material/useMediaQuery';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import SelectOutlined from '../../components/SelectOutlined';
import TableList from '../../components/TableList';
import IndicatorItems from './components/IndicatorItems';
import ButtonOutlined from '../../components/ButtonOutlined';

import { changeMessageWarning } from '../../store/user/actions';
import { IndicatorsByPageFetch } from '../../store/indicator/actions';

export default function Indicator({ history }) {
  const {
    typeWarning,
    messageWarning,
    token,
    type,
  } = useSelector((state) => state.user);
  const {
    isLoadingIndicator,
    indicatorsCount,
    indicators,
  } = useSelector((state) => state.indicator);

  const [indicatorsPerPage, setIndicatorsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [orderingSelected, setOrderingSelected] = useState('created');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMatches = useMediaQuery('(min-width:795px)');

  const optionsOrder = ['ÁREA', 'DATA'];
  const valuesOrdersBy = ['area', 'created'];

  async function getIndicatorsByPage(currentPage, orderingChosen) {
    try {
      dispatch(IndicatorsByPageFetch(token, currentPage + 1, orderingChosen));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getIndicatorsByPage(page, orderingSelected);
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getIndicatorsByPage(newPage, orderingSelected);
  };

  const onOrderIndicator = (optionValue) => {
    setOrderingSelected(optionValue);
    setPage(0);
    const indexOrder = optionsOrder.indexOf(optionValue);
    if (indexOrder !== -1) {
      getIndicatorsByPage(0, valuesOrdersBy[indexOrder]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setIndicatorsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <MiniDrawer pageTitle="Indicadores">
      <ContainerPaper>
        <Box sx={{
          display: 'flex',
          flexDirection: isMatches ? 'row' : 'column',
          justifyContent: 'space-between',
          height: isMatches ? 50 : 120,
          marginBottom: 3,
        }}
        >
          <Box sx={isMatches ? { width: '25%' } : null}>
            <SelectOutlined
              selectTestID="SelectOrderID"
              selectPlaceholder="Ordenar por"
              selectPlaceholderNoItem="Não há opções"
              selectValue={orderingSelected}
              selectOptions={optionsOrder}
              onChangeSelect={onOrderIndicator}
              isDisable={type !== 'admin'}
            />
          </Box>
          {type !== 'default_user' ? (
            <ButtonOutlined
              label="CRIAR INDICADOR"
              onAction={() => navigate('/indicators/create', { replace: true })}
            />
          ) : null }
        </Box>
        <TableList
          tableTestID="TableIndicatorID"
          tableMessageNoItem="Não há registro de indicador"
          tableCells={['', '', 'NOME', 'UNID. DE MEDIDA', 'META', 'CRITÉRIO', 'ÁREA', 'RESPONSÁVEL']}
          countItems={indicators.length}
        >
          <IndicatorItems itemsIndicator={indicators} />
        </TableList>
        <TablePagination
          rowsPerPageOptions={[indicatorsPerPage]}
          component="div"
          count={indicatorsCount}
          rowsPerPage={indicatorsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingIndicator} />
    </MiniDrawer>
  );
}
