const { ON_PRIMARY_COLOR, TERTIARY_COLOR } = require('../../styles/colors');

const styles = {
  inputPasswordAndorment: {
    padding: '28px 30px 28px 10px',
    marginRight: '-15px',
    marginLeft: '-5px',
    backgroundColor: TERTIARY_COLOR,
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
  },
  inputPassword: {
    '& label': { paddingLeft: (theme) => theme.spacing(0.5) },
    '& input': {
      paddingLeft: (theme) => theme.spacing(3.5),
      backgroundColor: TERTIARY_COLOR,
      color: ON_PRIMARY_COLOR,
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
    },
    '& fieldset': {
      borderRadius: '30px',
    },
  },
  buttonSendContainer: {
    mt: 3,
    ml: 1,
    pl: 4,
    pr: 4,
    borderRadius: '20px',
  },
};

export default styles;
