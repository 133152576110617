/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingDecision: false,
  decisions: [],
  decisionsCount: 0,
  decision: null,
};

export const decisionSlice = createSlice({
  name: 'decision',
  initialState,
  reducers: {
    decisionLoading(state, action) {
      const draft = state;
      //   console.log('decisionLoading | action.payload:', action.payload);
      draft.isLoadingDecision = action.payload.isLoading;
    },
    decisionsSucess: (state, action) => {
      const draft = state;
      //   console.log('decisionsSucess | action.payload:', action.payload);

      draft.decisions = action.payload.decisions;
      draft.isLoadingDecision = false;
    },
    changeDecisionsCount: (state, action) => {
      const draft = state;
      //   console.log('changeDecisionsCount | action.payload:', action.payload);

      draft.decisionsCount = action.payload.count;
    },
    decisionSucess: (state, action) => {
      const draft = state;
      //   console.log('decisionsSucess | action.payload:', action.payload);

      draft.decision = action.payload.decision;
      draft.isLoadingDecision = false;
    },
  },
});

export default decisionSlice.reducer;
