/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import itemsByAlphabeticalOrder from '../../utils/itemsByAlphabeticalOrder';

export default function SelectAutocompleteOutlined({
  selectTestID,
  selectPlaceholder,
  selectPlaceholderNoItem,
  selectValue,
  selectOptions,
  onChangeSelect,
  isDisable = true,
}) {
  const [optionsToSelect, setOptionsToSelect] = useState('');

  useEffect(() => {
    function fillOptionsToSelect() {
      try {
        const selectOptionsAlphabetical = itemsByAlphabeticalOrder(selectOptions);
        setOptionsToSelect(selectOptionsAlphabetical);
      } catch (error) {
        console.log('fillOptionsToSelect | error: ', error);
      }
    }

    if (selectOptions !== undefined) {
      fillOptionsToSelect();
    }
  }, [selectOptions]);

  const onChangeValue = ({ target: { textContent } }) => {
    console.log('SelectAutocompleteOutlined | textContent: ', textContent);
    onChangeSelect(textContent);
  };

  return (
    <Autocomplete
      id={selectTestID}
      disablePortal
      options={optionsToSelect}
      value={selectValue}
      noOptionsText={selectPlaceholderNoItem}
      renderInput={(params) => <TextField fullWidth {...params} label={selectPlaceholder} />}
      onChange={onChangeValue}
      disabled={isDisable}
    />
  );
}
