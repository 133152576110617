/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';

import themeColors from '../../styles/theme_colors';

export default function ContainerPaper({ children }) {
  const theme = createTheme(themeColors.authenticated, ptBR);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth={false} sx={{ mt: 11, mb: 4 }}>
        <Paper elevation={3} sx={{ p: { xs: 2, md: 3 } }}>
          {children}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
