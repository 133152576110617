/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingNotification: false,
  notifications: [],
  notificationsCount: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationLoading(state, action) {
      const draft = state;
      console.log('notificationLoading | action.payload:', action.payload);
      draft.isLoadingNotification = action.payload.isLoading;
    },
    changeNotificationsCount: (state, action) => {
      const draft = state;
      console.log('changeNotificationsCount | action.payload:', action.payload);

      draft.notificationsCount = action.payload.count;
    },
    notificationsSucess: (state, action) => {
      const draft = state;
      console.log('notificationsSucess | action.payload:', action.payload);

      draft.notifications = action.payload.notifications;
      draft.isLoadingNotification = false;
    },
  },
});

export default notificationSlice.reducer;
