/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function MemberItems({
  itemsMember,
  onAction,
}) {
  return (
    itemsMember !== undefined ? (itemsMember.map(
      (memberLoop) => (
        <TableRow
          key={memberLoop.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell sx={{ width: 20 }}>
            <IconButton
              aria-label={`button edit ${memberLoop.first_name}`}
              size="small"
              onClick={() => onAction(memberLoop.id)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {memberLoop.first_name}
          </TableCell>
          <TableCell align="left">{memberLoop.last_name}</TableCell>
          <TableCell align="left">{memberLoop.area.name}</TableCell>
          <TableCell align="left">{memberLoop.role.name}</TableCell>
        </TableRow>
      ),
    )) : null
  );
}
