/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderBack from '../../components/HeaderBack';
import InputOutlined from '../../components/InputOutlined';
import ButtonContained from '../../components/ButtonContained';
import ButtonOutlined from '../../components/ButtonOutlined';

import { changeMessageWarning } from '../../store/user/actions';
import {
  AreaCreateFetch,
  AreaByIdFetch,
  AreaUpdateByIdFetch,
  AreaDeleteByIdFetch,
} from '../../store/area/actions';

export default function AreaForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
  } = useSelector((state) => state.user);
  const { isLoadingArea, area } = useSelector((state) => state.area);

  const [name, setName] = useState('');
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { areaId } = useParams();

  useEffect(() => {
    async function getAreaById() {
      try {
        dispatch(AreaByIdFetch(areaId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    if (areaId !== undefined) {
      getAreaById();
    }
  }, [history]);

  useEffect(() => {
    console.log('useEffect | areaId, area: ', areaId, area);
    if (areaId !== undefined && area !== null) {
      setName(area.name);
    } else {
      setName('');
    }
  }, [area, areaId]);

  function validateArea() {
    console.log(
      'validateArea | name: ',
      name,
    );
    let message = '';

    if (!name) {
      message = 'Preencha o campo área';
    }

    return { isInvalid: !!message, message };
  }

  async function sendAreaCreate() {
    try {
      const responseValidateArea = await validateArea();
      console.log('sendAreaCreate | responseValidateArea ', responseValidateArea);

      if (responseValidateArea.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateArea.message,
          type: 'warning',
        }));
      } else {
        dispatch(AreaCreateFetch(
          token,
          name,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendAreaCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      }));
    }
  }

  async function sendAreaUpdateById() {
    try {
      const responseValidateAreaUpdate = await validateArea();
      console.log('sendAreaUpdateById | responseValidateAreaUpdate: ', responseValidateAreaUpdate);

      if (responseValidateAreaUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateAreaUpdate.message,
          type: 'warning',
        }));
      } else {
        dispatch(AreaUpdateByIdFetch(
          token,
          areaId,
          name,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendIndicatorUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteAreaById() {
    try {
      setIsVisibleDialog(false);
      dispatch(AreaDeleteByIdFetch(token, areaId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendArea = () => {
    if (areaId !== undefined) {
      sendAreaUpdateById();
    } else {
      sendAreaCreate();
    }
  };
  return (
    <MiniDrawer pageTitle={`${areaId !== undefined ? 'Editar' : 'Criar'} área`}>
      <ContainerPaper>
        <HeaderBack
          title="Informações da área"
          onBack={() => navigate('/areas', { replace: true })}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputOutlined
              inputTestID="TextInputNameID"
              inputPlaceholder="Digite aqui a área"
              inputValue={name}
              onChangeInput={(textValue) => setName(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: areaId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {areaId !== undefined
            ? (
              <>
                <ButtonContained
                  label="Excluir"
                  type="error"
                  onAction={changeIsVisibleDialog}
                />
                <ButtonOutlined
                  label="CRIAR CARGO"
                  onAction={() => navigate(`/areas/${areaId}/role/create`, { replace: true })}
                />
              </>
            ) : null}
          <ButtonContained
            label={areaId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendArea}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser || isLoadingArea} />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteAreaById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir essa área?"
      />
    </MiniDrawer>
  );
}
