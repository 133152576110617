/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingResult: false,
  sheetUrl: '',
  resultsCount: 0,
  results: [],
  result: null,
  records: [],
  statistics: [],
  yearSelected: null,
  areaSelected: '',
  indicatorsSelected: [],
};

export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    changeYearSelected: (state, action) => {
      const draft = state;
      console.log('changeYearSelected | action.payload:', action.payload);

      draft.yearSelected = action.payload.year;
    },
    changeAreaSelected: (state, action) => {
      const draft = state;
      console.log('changeAreaSelected | action.payload:', action.payload);

      draft.areaSelected = action.payload.area;
    },
    changeIndicatorsSelected: (state, action) => {
      const draft = state;
      console.log('changeIndicatorsSelected | action.payload:', action.payload);

      draft.indicatorsSelected = action.payload.indicators;
    },
    resultLoading(state, action) {
      const draft = state;
      // console.log('resultLoading | action.payload:', action.payload);
      draft.isLoadingResult = action.payload.isLoading;
    },
    recordSheetModelSucess: (state, action) => {
      const draft = state;
      // console.log('resultsSucess | action.payload:', action.payload);

      draft.sheetUrl = action.payload.sheetUrl;
      draft.isLoadingResult = false;
    },
    changeResultsCount: (state, action) => {
      const draft = state;
      console.log('changeResultsCount | action.payload:', action.payload);

      draft.resultsCount = action.payload.count;
    },
    resultsSucess: (state, action) => {
      const draft = state;
      // console.log('resultsSucess | action.payload:', action.payload);

      draft.results = action.payload.results;
      draft.isLoadingResult = false;
    },
    resultSucess: (state, action) => {
      const draft = state;
      // console.log('resultSucess | action.payload:', action.payload);

      draft.result = action.payload.result;
      draft.isLoadingResult = false;
    },
    recordsSucess: (state, action) => {
      const draft = state;
      // console.log('recordsSucess | action.payload:', action.payload);

      draft.records = action.payload.records;
      draft.isLoadingResult = false;
    },
    statisticsSucess: (state, action) => {
      const draft = state;
      // console.log('statisticsSucess | action.payload:', action.payload);

      draft.statistics = action.payload.statistics;
      draft.isLoadingResult = false;
    },
    changeStatistics: (state, action) => {
      const draft = state;
      // console.log('changeStatistics | action.payload:', action.payload);

      draft.statistics = action.payload.statistics;
    },
  },
});

export default resultSlice.reducer;
