/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

import TableBodySubSublevel from '../../../TableBodySubSublevel';

import getIndicatorGoal from '../../../../../../utils/getIndicatorGoal';
import numericDotMask from '../../../../../../utils/masks/numericDotMask';

export default function TableBodyIndicator({ sublevelParam, onAction }) {
  const sublevelLoopLength = sublevelParam.sublevels !== null ? (sublevelParam.sublevels).length : 0;
  const [isCollapse, setIsCollapse] = useState(false);
  const [indicatorSublevelGoal, setIndicatorSublevelGoal] = useState(0);

  async function fillIndicatorGoal() {
    try {
      const goalFromIndicator = await getIndicatorGoal(sublevelParam.goals);
      const goalFromIndicatorWithMask = await numericDotMask(goalFromIndicator);
      setIndicatorSublevelGoal(goalFromIndicatorWithMask);
    } catch (error) {
      console.log('fillIndicatorGoal | error: ', error);
      setIndicatorSublevelGoal(0);
    }
  }

  useEffect(() => {
    fillIndicatorGoal();
  }, [sublevelParam]);

  return (
    <>
      <TableRow key={sublevelParam.id}>
        {sublevelLoopLength >= 1
          ? (
            <TableCell sx={{ width: '6.1%' }}>
              <IconButton
                aria-label={`button collapse ${sublevelParam.name}`}
                size="small"
                onClick={() => setIsCollapse(!isCollapse)}
              >
                {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (<TableCell sx={{ width: '5.5%' }} />)}
        <TableCell sx={sublevelLoopLength >= 1 ? { width: '7.3%' } : { width: '6.7%' }}>
          <IconButton
            aria-label={`button edit ${sublevelParam.name}`}
            size="small"
            onClick={() => onAction()}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={sublevelLoopLength >= 1 ? { width: '19%' } : { width: '22.8%' }}>
          {sublevelParam.name}
        </TableCell>
        <TableCell align="left" sx={sublevelLoopLength >= 1 ? { width: '17%' } : { width: '15.6%' }}>
          {sublevelParam.unit_measurement}
        </TableCell>
        <TableCell align="left" sx={sublevelLoopLength >= 1 ? { width: '8.3%' } : { width: '7.2%' }}>
          {indicatorSublevelGoal}
        </TableCell>
        <TableCell align="left" sx={sublevelLoopLength >= 1 ? { width: '12.5%' } : { width: '12.1%' }}>
          {sublevelParam.criterion}
        </TableCell>
        {sublevelParam.area !== null ? (
          <TableCell sx={sublevelLoopLength >= 1 ? { width: '15.6%' } : { width: '14.3%' }}>
            {sublevelParam.area.name}
          </TableCell>
        ) : (
          <TableCell />
        )}
        <TableCell align="left">
          {`${sublevelParam.responsible.first_name} ${sublevelParam.responsible.last_name}`}
        </TableCell>
      </TableRow>
      {sublevelLoopLength >= 1
        ? (
          <TableBodySubSublevel
            subSublevelsParam={sublevelParam.sublevels}
            isVisible={isCollapse}
          />
        ) : null }
    </>
  );
}
