/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import VideocamIcon from '@mui/icons-material/Videocam';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import ModalDialog from '../../components/ModalDialog';
import HeaderRedirect from '../../components/HeaderRedirect';
import InputOutlined from '../../components/InputOutlined';
import PickerDateTimeOutlined from '../../components/PickerDateTimeOutlined';
import PickerTimeOutlined from '../../components/PickerTimeOutlined';
import PickerDateOutlined from '../../components/PickerDateOutlined';
import SelectOutlined from '../../components/SelectOutlined';
import CheckboxList from '../../components/CheckboxList';
import SelectAutocompleteOutlined from '../../components/SelectAutocompleteOutlined';
import ButtonContained from '../../components/ButtonContained';
import ButtonSquareContained from '../../components/ButtonSquareContained';

import emailIsInvalid from '../../utils/validation/emailIsInvalid';
import calendarDateMask from '../../utils/masks/calendarDateMask';

import { AreasFetch } from '../../store/area/actions';
import { PendencyCreateFetch, PendenciesByMeetingFetch } from '../../store/pendency/actions';
import { DecisionCreateFetch, DecisionsByMeetingFetch } from '../../store/decision/actions';
import { MembersFetch, changeMessageWarning } from '../../store/user/actions';
import {
  MeetingCreateFetch,
  MeetingByIdFetch,
  MeetingUpdateByIdFetch,
  MeetingDeleteByIdFetch,
} from '../../store/meeting/actions';

const situations = require('../../utils/resources/situations.json');

export default function MeetingForm({ history }) {
  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
    token,
    members,
  } = useSelector((state) => state.user);
  const { isLoadingArea, areas } = useSelector((state) => state.area);
  const { isLoadingDecision, decisions } = useSelector((state) => state.decision);
  const { isLoadingPendency, pendencies } = useSelector((state) => state.pendency);
  const { isLoadingMeeting, meeting } = useSelector((state) => state.meeting);

  const [typeMeeting, setTypeMeeting] = useState('');
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [place, setPlace] = useState('');
  const [externalLink, setExternalLink] = useState('');
  const [coordinatorSelected, setCoordinatorSelected] = useState('');
  const [secretarySelected, setSecretarySelected] = useState('');
  const [areaSelected, setAreaSelected] = useState('');
  const [areasToSelect, setAreasToSelect] = useState([]);
  const [participantsChecked, setParticipantsChecked] = useState([]);
  const [participantsAttendedChecked, setParticipantsAttendedChecked] = useState([]);
  const [guest, setGuest] = useState('');
  const [guests, setGuests] = useState([]);
  const [subject, setSubject] = useState('');
  const [responsibleSelected, setResponsibleSelected] = useState('');
  const [durationTime, setDurationTime] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [membersFilteredByArea, setMembersFilteredByArea] = useState([]);
  const [membersToParticipant, setMembersToParticipant] = useState([]);
  const [membersToSelect, setMembersToSelect] = useState([]);
  const [createdBySelected, setCreatedBySelected] = useState('');
  const [isInPerson, setIsInPerson] = useState(true);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);
  const [isVisibleRecordFromMeeting, setIsVisibleRecordFromMeeting] = useState(false);

  const [descriptionDecision, setDescriptionDecision] = useState('');
  const [responsibleDecisionSelected, setResponsibleDecisionSelected] = useState('');

  const [descriptionPendency, setDescriptionPendency] = useState('');
  const [responsiblePendencySelected, setResponsiblePendencySelected] = useState('');
  const [situationPendencySelected, setSituationPendencySelected] = useState('');
  const startDatePendency = new Date();
  const [endDatePendency, setEndDatePendency] = useState(dayjs(new Date()));
  const [observationPendency, setObservationPendency] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { meetingId } = useParams();

  useEffect(() => {
    async function getMeetingById() {
      try {
        dispatch(MeetingByIdFetch(token, meetingId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getDecisionsByMeeting() {
      try {
        dispatch(DecisionsByMeetingFetch(token, meetingId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getPendenciesByMeeting() {
      try {
        dispatch(PendenciesByMeetingFetch(token, meetingId));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getRoles() {
      try {
        dispatch(AreasFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getMembers() {
      try {
        dispatch(MembersFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getMembers();
    getRoles();
    console.log('meetingId: ', meetingId);

    if (meetingId !== undefined) {
      getMeetingById();
      getDecisionsByMeeting();
      getPendenciesByMeeting();
    }
  }, [history]);

  useEffect(() => {
    console.log('useEffect | meetingId, meeting: ', meetingId, meeting);
    function fillInputsMeeting() {
      try {
        if (meetingId !== undefined && meeting !== null) {
          setTypeMeeting(meeting.meeting_type);
          setStartDate(dayjs(meeting.start_date));
          setEndDate(dayjs(meeting.end_date));
          setIsInPerson(!meeting.online);
          setPlace(meeting.place);
          setExternalLink(meeting.link);
          setCoordinatorSelected(`${meeting.coordinator.first_name} ${meeting.coordinator.last_name}`);
          setSecretarySelected(`${meeting.secretary.first_name} ${meeting.secretary.last_name}`);
          setAreaSelected(meeting.area.name);
          const participantsOptions = (meeting.participants).map((participantTwister) => `${participantTwister.first_name} ${participantTwister.last_name}`);
          console.log('fillInputsMeeting | participantsOptions: ', participantsOptions);
          setParticipantsChecked(participantsOptions);
          const participantsAttendedFillFound = members.filter(
            (memberTwist) => (meeting.participants_attended).includes((memberTwist.id).toString()),
          );
          console.log('fillInputsMeeting | participantsAttendedFillFound: ', participantsAttendedFillFound);
          const participantsAttendedOptions = participantsAttendedFillFound.map(
            (participantAttendedTwister) => `${participantAttendedTwister.first_name} ${participantAttendedTwister.last_name}`,
          );
          setParticipantsAttendedChecked(participantsAttendedOptions);
          console.log('fillInputsMeeting | meeting.participants: ', meeting.participants);
          setSubjects(meeting.subjects);
          const meetingGuets = (meeting.guests).map((guestLoop) => guestLoop.email);
          console.log('fillInputsMeeting | meetingGuets: ', meetingGuets);
          setGuests(meetingGuets);
          setCreatedBySelected(`${meeting.created_by.first_name} ${meeting.created_by.last_name}`);
        } else {
          setTypeMeeting('');
          setStartDate(dayjs(new Date()));
          setEndDate(dayjs(new Date()));
          setPlace('');
          setExternalLink('');
          setIsInPerson(true);
          setCoordinatorSelected('');
          setSecretarySelected('');
          setAreaSelected('');
          setParticipantsChecked([]);
          setSubjects([]);
          setGuests([]);
          setCreatedBySelected('');
        }
      } catch (error) {
        console.log('fillInputsMeeting | error: ', error);
      }
    }

    fillInputsMeeting();
  }, [meeting, meetingId]);

  useEffect(() => {
    console.log('useEffect | areaSelected: ', areaSelected);
    const coordinatorFound = members.find(
      (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === coordinatorSelected,
    );
    console.log('useEffect | areaSelected | coordinatorFound: ', coordinatorFound);
    const isFromAreaCoordinator = coordinatorFound !== undefined
      ? coordinatorFound.area.name === areaSelected : false;
    if (!isFromAreaCoordinator) {
      setCoordinatorSelected('');
    }

    const secretaryFound = members.find((memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === secretarySelected);
    console.log('useEffect | areaSelected | secretaryFound: ', secretaryFound);
    const isFromAreaSecretary = secretaryFound !== undefined
      ? secretaryFound.area.name === areaSelected : false;
    if (!isFromAreaSecretary) {
      setSecretarySelected('');
    }

    const membersFromArea = members.filter(
      (memberCycle) => memberCycle.area.name === areaSelected,
    );
    const membersOptionsFromArea = membersFromArea.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
    console.log('useEffect | areaSelected | membersOptionsFromArea: ', membersOptionsFromArea);
    setMembersFilteredByArea(membersOptionsFromArea);
    setMembersToParticipant(membersOptionsFromArea);
    const participantsCheckedFromArea = participantsChecked.filter(
      (participantCheckedTwister) => membersOptionsFromArea.includes(participantCheckedTwister),
    );
    console.log(
      'useEffect | areaSelected | participantsChecked, participantsCheckedFromArea: ',
      participantsChecked,
      participantsCheckedFromArea,
    );
    const participantsAttendedCheckedFromArea = participantsAttendedChecked.filter(
      (participantCheckedTwister) => (membersOptionsFromArea.includes(participantCheckedTwister)),
    );
    console.log(
      'useEffect | areaSelected | participantsAttendedChecked, participantsAttendedCheckedFromArea: ',
      participantsAttendedChecked,
      participantsAttendedCheckedFromArea,
    );
    setParticipantsChecked(participantsCheckedFromArea);
    setParticipantsAttendedChecked(participantsAttendedCheckedFromArea);
  }, [areaSelected]);

  useEffect(() => {
    console.log(
      'useEffect | coordinatorSelected, secretarySelected: ',
      coordinatorSelected,
      secretarySelected,
    );

    const membersFromArea = members.filter(
      (memberCycle) => memberCycle.area.name === areaSelected,
    );
    const membersOptionsFromArea = membersFromArea.map((memberFromAreaLoop) => `${memberFromAreaLoop.first_name} ${memberFromAreaLoop.last_name}`);
    const membersToParticipantWithoutCoordinator = membersOptionsFromArea.filter(
      (memberLoop) => memberLoop !== coordinatorSelected,
    );
    const membersToParticipantWithoutSecretary = membersToParticipantWithoutCoordinator.filter(
      (memberPhase) => memberPhase !== secretarySelected,
    );
    setMembersToParticipant(membersToParticipantWithoutSecretary);
    const participantsCheckedFromArea = participantsChecked.filter(
      (participantCheckedTwister) => membersToParticipantWithoutSecretary.includes(
        participantCheckedTwister,
      ),
    );
    console.log(
      'useEffect | areaSelected | participantsChecked, participantsCheckedFromArea: ',
      participantsChecked,
      participantsCheckedFromArea,
    );
    const participantsAttendedCheckedFromArea = participantsAttendedChecked.filter(
      (participantCheckedTwister) => membersToParticipantWithoutSecretary.includes(
        participantCheckedTwister,
      ),
    );
    console.log(
      'useEffect | areaSelected | participantsAttendedChecked, participantsAttendedCheckedFromArea: ',
      participantsAttendedChecked,
      participantsAttendedCheckedFromArea,
    );
    setParticipantsChecked(participantsCheckedFromArea);
    setParticipantsAttendedChecked(participantsAttendedCheckedFromArea);
  }, [coordinatorSelected, secretarySelected]);

  useEffect(() => {
    console.log('useEffect | meetingId, meeting: ', meetingId, meeting);
    const subjectsHours = subjects.map((subjectLoop) => {
      const subjectDurationHours = (subjectLoop.duration).slice(0, 2);
      // console.log('useEffect | subjectDurationHours: ', subjectDurationHours);
      const subjectDurationHoursInt = parseInt(subjectDurationHours, 10);
      // console.log('useEffect | subjectDurationHoursInt: ', subjectDurationHoursInt);
      return subjectDurationHoursInt;
    });
    const subjectsMinutes = subjects.map((subjectLoop) => {
      const subjectDurationMinutes = (subjectLoop.duration).slice(3, 5);
      // console.log('useEffect | subjectDurationMinutes: ', subjectDurationMinutes);
      const subjectDurationMinutesInt = parseInt(subjectDurationMinutes, 10);
      // console.log('useEffect | subjectDurationMinutesInt: ', subjectDurationMinutesInt);
      return subjectDurationMinutesInt;
    });
    // console.log('useEffect | subjectsHours, subjectsMinutes: ', subjectsHours, subjectsMinutes);

    const sumHoursSubjects = subjectsHours.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual,
      0,
    );
    const sumMinutesSubjects = subjectsMinutes.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual,
      0,
    );
    // console.log(
    //   'useEffect | sumHoursSubjects, sumMinutesSubjects: ',
    //   sumHoursSubjects,
    //   sumMinutesSubjects,
    // );
    const manipuleStartDate = new Date(startDate);
    // console.log('useEffect | manipuleStartDate: ', manipuleStartDate);
    manipuleStartDate.setMinutes(manipuleStartDate.getMinutes() + sumMinutesSubjects);
    // console.log('useEffect | manipuleStartDate: ', manipuleStartDate);
    const manipuleStartDateHours = manipuleStartDate.getHours();
    manipuleStartDate.setHours(manipuleStartDateHours + sumHoursSubjects);
    // console.log('useEffect | manipuleStartDate: ', manipuleStartDate);
    setEndDate(dayjs(manipuleStartDate));
  }, [subjects, startDate]);

  useEffect(() => {
    function fillAreasToSelect() {
      try {
        const areasOptions = areas.map((areaLoop) => areaLoop.name);
        setAreasToSelect(areasOptions);
      } catch (error) {
        console.log('fillAreasToSelect | error: ', error);
      }
    }

    if (areas !== undefined) {
      fillAreasToSelect();
    }
  }, [areas]);

  useEffect(() => {
    function fillMembersToSelect() {
      try {
        const membersOptions = members.map((memberTwister) => `${memberTwister.first_name} ${memberTwister.last_name}`);
        setMembersToSelect(membersOptions);
      } catch (error) {
        console.log('fillMembersToSelect | error: ', error);
      }
    }

    if (members !== undefined) {
      fillMembersToSelect();
    }
  }, [members]);

  useEffect(() => {
    function clearDecisionField() {
      try {
        setDescriptionDecision('');
        setResponsibleDecisionSelected('');
      } catch (error) {
        console.log('clearDecisionField | error: ', error);
      }
    }

    if (messageWarning === 'Decisão criada com sucesso!') {
      clearDecisionField();
    }
  }, [messageWarning]);

  useEffect(() => {
    function clearPendencyField() {
      try {
        setDescriptionPendency('');
        setResponsiblePendencySelected('');
        setSituationPendencySelected('');
        setEndDatePendency(dayjs(new Date()));
        setObservationPendency('');
      } catch (error) {
        console.log('clearPendencyField | error: ', error);
      }
    }

    if (messageWarning === ' Pendência gerada!') {
      clearPendencyField();
    }
  }, [messageWarning]);

  function subtractHours(date, hours) {
    date.setHours(date.getHours() - hours);

    return date;
  }

  function validateMeeting() {
    console.log(
      'validateMeeting | tokenAuthorization, meeting, lastName, email, role: ',
      token,
      typeMeeting,
      place,
      coordinatorSelected,
      secretarySelected,
      areaSelected,
      startDate,
      endDate,
    );
    let message = '';
    const currentDate = new Date().setUTCSeconds(0);
    console.log('validateMeeting | currentDate: ', currentDate);

    if (!typeMeeting) {
      message = 'Preencha o campo tema da reunião';
    } else if (typeMeeting.length < 3) {
      message = 'Campo tema da reunião é inválido';
    } else if (startDate < currentDate) {
      message = 'Campo data de início não pode ser uma data passada';
    } else if (endDate < startDate) {
      message = 'Campo data de termino não pode ser anterior a data de início';
    } else if (isInPerson && !place) {
      message = 'Preencha o campo local';
    } else if (!isInPerson && !externalLink) {
      message = 'Preencha o campo link da reunião';
    } else if (!areaSelected) {
      message = 'Selecione uma área';
    } else if (!coordinatorSelected) {
      message = 'Selecione um coordenador';
    } else if (!secretarySelected) {
      message = 'Selecione um secretário';
    } else if (participantsChecked.length === 0) {
      message = 'Selecione um participante';
    } else if (subjects.length === 0) {
      message = 'Inclua um assunto a sua reunião';
    }
    return { isInvalid: !!message, message };
  }

  async function sendMeetingCreate() {
    try {
      const responseValidateMeeting = await validateMeeting();
      console.log('sendMeetingCreate | responseValidateMeeting: ', responseValidateMeeting);

      if (responseValidateMeeting.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateMeeting.message,
          type: 'warning',
        }));
      } else {
        const coordinatorFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === coordinatorSelected,
        );
        console.log('sendMeetingCreate | coordinatorFound: ', coordinatorFound);
        const secretaryFound = members.find((memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === secretarySelected);
        console.log('sendMeetingCreate | secretaryFound: ', secretaryFound);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        console.log('sendMeetingCreate | areaFound: ', areaFound);
        const participantsManipule = members.filter((memberCycle) => participantsChecked.includes(
          `${memberCycle.first_name} ${memberCycle.last_name}`,
        ));
        console.log('sendMeetingUpdateById | participantsManipule: ', participantsManipule);
        const participantsIds = participantsManipule.map(
          (participantManipuleCycle) => participantManipuleCycle.id,
        );
        console.log('sendMeetingUpdateById | participantsIds: ', participantsIds);
        const subjectsWithResposinbleId = subjects.map((subjectCycle) => {
          // eslint-disable-next-line prefer-const
          let subjectUptaded = subjectCycle;
          subjectUptaded.responsible = subjectUptaded.responsible.id;
          return subjectUptaded;
        });

        const startDatetime = new Date(startDate);
        const endDatetime = new Date(endDate);
        console.log('sendMeetingCreate | startDatetime, endDatetime: ', startDatetime, endDatetime);

        const startDateTimezoneSub = subtractHours(startDatetime, 3);
        const endDateTimezoneSub = subtractHours(endDatetime, 3);
        console.log('sendMeetingCreate | startDateTimezoneSub, endDateTimezoneSub: ', startDateTimezoneSub, endDateTimezoneSub);

        dispatch(MeetingCreateFetch({
          tokenAuthorization: token,
          body: {
            meeting_type: typeMeeting,
            start_date: startDatetime,
            end_date: endDatetime,
            online: !isInPerson,
            place,
            link: externalLink,
            coordinator: coordinatorFound.id,
            secretary: secretaryFound.id,
            area: areaFound.id,
            participants: participantsIds,
            guests,
            subjects: subjectsWithResposinbleId,
          },
          navigateToPage: navigate,
        }));
      }
    } catch (error) {
      console.log('sendMeetingCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function sendMeetingUpdateById() {
    try {
      const responseValidateMeetingUpdate = await validateMeeting();
      console.log('sendMeetingUpdateById | responseValidateMeetingUpdate: ', responseValidateMeetingUpdate);

      if (responseValidateMeetingUpdate.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateMeetingUpdate.message,
          type: 'warning',
        }));
      } else {
        const coordinatorFound = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === coordinatorSelected,
        );
        const secretaryFound = members.find((memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === secretarySelected);
        const areaFound = areas.find((areaCycle) => areaCycle.name === areaSelected);
        const participantsManipule = members.filter((memberCycle) => participantsChecked.includes(
          `${memberCycle.first_name} ${memberCycle.last_name}`,
        ));
        console.log('sendMeetingUpdateById | participantsManipule: ', participantsManipule);
        const participantsIds = participantsManipule.map(
          (participantManipuleCycle) => participantManipuleCycle.id,
        );
        console.log('sendMeetingUpdateById | participantsIds: ', participantsIds);
        const participantsAttendedManipule = members.filter(
          (memberCycle) => participantsAttendedChecked.includes(`${memberCycle.first_name} ${memberCycle.last_name}`),
        );
        console.log('sendMeetingUpdateById | participantsAttendedManipule: ', participantsAttendedManipule);
        const participantsAttendedIds = participantsAttendedManipule.map(
          (participanAttendedCycle) => participanAttendedCycle.id,
        );
        console.log('sendMeetingUpdateById | participantsAttendedIds: ', participantsAttendedIds);
        const subjectsWithResposinbleId = subjects.map((subjectCycle) => {
          const subjectUptaded = {
            description: subjectCycle.description,
            responsible: subjectCycle.responsible.id,
            duration: subjectCycle.duration,
          };
          console.log('sendMeetingUpdateById | subjectUptaded: ', subjectUptaded);
          return subjectUptaded;
        });
        console.log('sendMeetingUpdateById | subjectsWithResposinbleId: ', subjectsWithResposinbleId);

        const startDatetime = new Date(startDate);
        const endDatetime = new Date(endDate);
        console.log('sendMeetingCreate | startDatetime, endDatetime: ', startDatetime, endDatetime);

        const startDateTimezoneSub = subtractHours(startDatetime, 3);
        const endDateTimezoneSub = subtractHours(endDatetime, 3);
        console.log('sendMeetingCreate | startDateTimezoneSub, endDateTimezoneSub: ', startDateTimezoneSub, endDateTimezoneSub);

        dispatch(MeetingUpdateByIdFetch({
          tokenAuthorization: token,
          body: {
            meeting_id: meetingId,
            meeting_type: typeMeeting,
            start_date: startDateTimezoneSub,
            end_date: endDateTimezoneSub,
            online: !isInPerson,
            place,
            link: externalLink,
            coordinator: coordinatorFound.id,
            secretary: secretaryFound.id,
            area: areaFound.id,
            participants: participantsIds,
            participants_attended: participantsAttendedIds,
            guests,
            subjects: subjectsWithResposinbleId,
          },
          navigateToPage: navigate,
        }));
      }
    } catch (error) {
      console.log('sendMeetingUpdateById | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  async function deleteMeetingById() {
    try {
      setIsVisibleDialog(false);
      dispatch(MeetingDeleteByIdFetch(token, meetingId, navigate));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const changeIsVisibleDialog = () => {
    setIsVisibleDialog(!isVisibleDialog);
  };

  const handleSendMeeting = () => {
    if (meetingId !== undefined) {
      sendMeetingUpdateById();
    } else {
      sendMeetingCreate();
    }
  };

  function validateGuest() {
    console.log('validateGuest | guest: ', guest);
    let message = '';

    if (!guest) {
      message = 'Preencha o campo e-mail do convidado';
    } else if (emailIsInvalid(guest)) {
      message = 'E-mail do convidado é inválido';
    } else if (guests.includes(guest)) {
      message = 'E-mail do convidado já foi inserido';
    }

    return { isInvalid: !!message, message };
  }

  async function handleGuestAdd() {
    const responsevalidateGuest = await validateGuest();
    console.log('handleGuestAdd | responsevalidateGuest: ', responsevalidateGuest);

    if (responsevalidateGuest.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateGuest.message,
        type: 'warning',
      }));
    } else {
      const guestsUptaded = [...guests, guest];
      console.log('handleGuestAdd | guestsUptaded: ', guestsUptaded);
      setGuests(guestsUptaded);
      setGuest('');
    }
  }

  const handleGuestDelete = (guestToDelete) => () => {
    console.log('handleGuestDelete | guestToDelete: ', guestToDelete);
    const guestsUptaded = guests.filter((guestCycle) => guestCycle !== guestToDelete);
    setGuests(guestsUptaded);
  };

  function validateSubject() {
    console.log('validateSubject | guest: ', guest);
    let message = '';
    const coordinatorFound = subjects.find(
      (subjectCycle) => subjectCycle.description === subject,
    );

    if (!subject) {
      message = 'Preencha o campo assunto';
    } else if (subject.length < 3) {
      message = 'Campo assunto é inválido';
    } else if (coordinatorFound !== undefined) {
      message = 'assunto já foi inserido';
    } else if (!responsibleSelected) {
      message = 'Selecione um responsável';
    } else if (!durationTime) {
      message = 'Selecione uma duração';
    }

    return { isInvalid: !!message, message };
  }

  const handleSubjectDelete = (subjectToDelete) => () => {
    console.log('handleSubjectDelete | subjectToDelete: ', subjectToDelete);
    const subjectsUptaded = subjects.filter((subjectCycle) => subjectCycle !== subjectToDelete);
    setSubjects(subjectsUptaded);
  };

  async function handleSubjectAdd() {
    const responseValidateSubject = await validateSubject();
    console.log('handleSubjectAdd | responseValidateSubject: ', responseValidateSubject);

    if (responseValidateSubject.isInvalid) {
      dispatch(changeMessageWarning({
        message: responseValidateSubject.message,
        type: 'warning',
      }));
    } else {
      const responsibleFound = members.find(
        (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleSelected,
      );
      const subjectDurationHours = (new Date(durationTime)).getHours();
      const subjectDurationHoursFormated = subjectDurationHours < 10 ? `0${subjectDurationHours}` : subjectDurationHours;
      const subjectDurationMinutes = (new Date(durationTime)).getMinutes();
      const subjectDurationMinutesFormated = subjectDurationMinutes < 10 ? `0${subjectDurationMinutes}` : subjectDurationMinutes;
      const subjectDurationTime = `${subjectDurationHoursFormated}:${subjectDurationMinutesFormated}`;
      const subjectNew = {
        description: subject,
        responsible: responsibleFound,
        duration: subjectDurationTime,
      };
      console.log('handleSubjectAdd | subjectNew: ', subjectNew);
      const subjectsUptaded = [...subjects, subjectNew];
      console.log('handleSubjectAdd | subjectsUptaded: ', subjectsUptaded);
      setSubjects(subjectsUptaded);
      setSubject('');
      setResponsibleSelected('');
      setDurationTime(null);
    }
  }

  async function handleVisibleRecordFromMeeting(isVisible) {
    console.log('handleVisibleRecordFromMeeting | isVisible: ', isVisible);
    setIsVisibleRecordFromMeeting(isVisible);
  }

  function validateDecision() {
    console.log(
      'validateDecision | description, responsibleSelected, situationSelected: ',
      descriptionDecision,
      responsibleDecisionSelected,
    );
    let message = '';

    if (!descriptionDecision) {
      message = 'Preencha o campo descrição';
    } else if (descriptionDecision.length < 3) {
      message = 'Campo descrição é inválido';
    } else if (!responsibleDecisionSelected) {
      message = 'Selecione um responsável';
    }

    return { isInvalid: !!message, message };
  }

  async function sendDecisionCreate() {
    try {
      const responseValidateDecision = await validateDecision();
      console.log('sendDecisionCreate | responseValidateDecision ', responseValidateDecision);

      if (responseValidateDecision.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateDecision.message,
          type: 'warning',
        }));
      } else {
        const responsibleFoundDecision = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsibleDecisionSelected,
        );
        console.log('sendDecisionCreate | responsibleFoundDecision: ', responsibleFoundDecision);

        dispatch(DecisionCreateFetch(
          token,
          descriptionDecision,
          responsibleFoundDecision.id,
          meetingId,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendDecisionCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function validatePendency() {
    console.log(
      'validatePendency | descriptionPendency, responsibleSelected, situationSelected, observation: ',
      descriptionPendency,
      responsiblePendencySelected,
      situationPendencySelected,
      observationPendency,
    );
    let message = '';

    if (!descriptionPendency) {
      message = 'Preencha o campo descrição';
    } else if (descriptionPendency.length < 3) {
      message = 'Campo descrição é inválido';
    } else if (!responsiblePendencySelected) {
      message = 'Selecione um responsável';
    } else if (!situationPendencySelected) {
      message = 'Selecione uma situação';
    }

    return { isInvalid: !!message, message };
  }

  async function sendPendencyCreate() {
    try {
      const responseValidatePendency = await validatePendency();
      console.log('sendPendencyCreate | responseValidatePendency ', responseValidatePendency);

      if (responseValidatePendency.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidatePendency.message,
          type: 'warning',
        }));
      } else {
        const responsibleFoundPendency = members.find(
          (memberCycle) => `${memberCycle.first_name} ${memberCycle.last_name}` === responsiblePendencySelected,
        );
        console.log('sendPendencyCreate | responsibleFoundPendency: ', responsibleFoundPendency);

        const endDatetime = new Date(endDatePendency);

        const endDateEndDateHourLast = new Date(
          endDatetime.getFullYear(),
          endDatetime.getMonth(),
          endDatetime.getDate(),
          23,
          59,
          59,
        );

        dispatch(PendencyCreateFetch(
          token,
          descriptionPendency,
          responsibleFoundPendency.id,
          situationPendencySelected,
          startDatePendency,
          endDateEndDateHourLast,
          observationPendency,
          meetingId,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendPendencyCreate | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const redirectToExternalLink = (urlExternal) => {
    window.open(
      urlExternal,
      '_blank',
    );
  };

  const redirectToMeetingPdf = () => {
    const baseUrl = process.env.REACT_APP_API;
    const redirectUrl = `${baseUrl}/meetings/meeting_pdf_by_id?meeting_id=${meetingId}`;
    window.open(
      redirectUrl,
      '_blank',
    );
  };

  return (
    <MiniDrawer pageTitle={`${meetingId !== undefined ? 'Editar' : 'Criar'} reunião`}>
      <ContainerPaper>
        <HeaderRedirect
          title="Informações da reunião"
          onBack={() => navigate('/meetings', { replace: true })}
          onRedirect={redirectToMeetingPdf}
        />
        {meetingId !== undefined
          ? (
            <>
              <Chip
                variant={isVisibleRecordFromMeeting ? 'outlined' : 'filled'}
                sx={{ mb: 3.5, mr: 2 }}
                label="Convocação"
                color="primary"
                onClick={() => handleVisibleRecordFromMeeting(false)}
              />
              <Chip
                variant={!isVisibleRecordFromMeeting ? 'outlined' : 'filled'}
                sx={{ mb: 3.5 }}
                label="Registro"
                color="primary"
                onClick={() => handleVisibleRecordFromMeeting(true)}
              />
            </>
          ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <InputOutlined
              inputTestID="TextInputTypeID"
              inputPlaceholder="Digite aqui o tema da reunião"
              inputValue={typeMeeting}
              onChangeInput={(textValue) => setTypeMeeting(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PickerDateTimeOutlined
              inputTestID="PickerDateTimeStartDateID"
              inputPlaceholder="Data de Inicío"
              inputValue={startDate}
              onChangeInput={(newValue) => setStartDate(newValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PickerTimeOutlined
              inputTestID="PickerTimeStartDateID"
              inputPlaceholder="Horário de Término"
              inputValue={endDate}
              onChangeInput={(newValue) => setEndDate(newValue)}
              isDisable
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormGroup>
              <InputLabel htmlFor="my-input">A reunião será on-line ou presencial?</InputLabel>
              <div style={{ flexDirection: 'row' }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isInPerson === true}
                      onChange={() => {
                        setIsInPerson(true);
                        setPlace('');
                      }}
                    />
                  )}
                  label="presencial"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isInPerson === false}
                      onChange={() => {
                        setIsInPerson(false);
                        setExternalLink('');
                      }}
                    />
                  )}
                  label="on-line"
                />
              </div>
            </FormGroup>
          </Grid>
          {isInPerson ? (
            <>
              <Grid item xs={12} sm={8.3}>
                <InputOutlined
                  inputTestID="TextInputPlaceID"
                  inputPlaceholder="Digite aqui o local"
                  inputValue={place}
                  onChangeInput={(textValue) => setPlace(textValue)}
                  isSecure={false}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={0.7}>
                <Button
                  variant="text"
                  size="large"
                  endIcon={<LocationOnIcon />}
                  sx={{ marginTop: 1.2, marginBottom: 2.5 }}
                  disabled={!place}
                  onClick={() => redirectToExternalLink(`https://www.google.com/maps/search/?api=1&query=${place}`)}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="text"
                  size="large"
                  endIcon={<GoogleIcon />}
                  sx={{ marginTop: 2, marginBottom: 2.5 }}
                  onClick={() => redirectToExternalLink('https://meet.google.com/')}
                />
                <Button
                  variant="text"
                  size="large"
                  endIcon={<MicrosoftIcon />}
                  sx={{ marginTop: 2, marginBottom: 2.5 }}
                  onClick={() => redirectToExternalLink('https://www.microsoft.com/pt-br/microsoft-teams/group-chat-software')}
                />
                <Button
                  variant="text"
                  size="large"
                  endIcon={<VideocamIcon />}
                  sx={{ marginTop: 2, marginBottom: 2.5 }}
                  onClick={() => redirectToExternalLink('https://explore.zoom.us/pt/products/meetings/')}
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <InputOutlined
                  inputTestID="TextInputLinkMeetingID"
                  inputPlaceholder="Cole aqui o link da reunião on-line"
                  inputValue={externalLink}
                  onChangeInput={(textValue) => setExternalLink(textValue)}
                  isSecure={false}
                  isDisable={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={4}>
            <SelectOutlined
              selectTestID="SelectAreaID"
              selectPlaceholder="Área"
              selectPlaceholderNoItem="Não há áreas"
              selectValue={areaSelected}
              selectOptions={areasToSelect}
              onChangeSelect={(optionValue) => setAreaSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteCoordinatorID"
              selectPlaceholder="Coordenador"
              selectPlaceholderNoItem="Não há membro"
              selectValue={coordinatorSelected}
              selectOptions={membersFilteredByArea}
              onChangeSelect={(optionValue) => setCoordinatorSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteSecretaryID"
              selectPlaceholder="Secretário"
              selectPlaceholderNoItem="Não há membro"
              selectValue={secretarySelected}
              selectOptions={membersFilteredByArea}
              onChangeSelect={(optionValue) => setSecretarySelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          {!isVisibleRecordFromMeeting ? (
            <Grid item xs={12}>
              <CheckboxList
                checkTestID="CheckboxSecretaryID"
                checkPlaceholder="Participantes"
                checkPlaceholderNoItem="Não há membro"
                checkOptions={membersToParticipant}
                checkedOptions={participantsChecked}
                onChangeCheckbox={(itemsValue) => setParticipantsChecked(itemsValue)}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CheckboxList
                checkTestID="CheckboxSecretaryID"
                checkPlaceholder="Participantes que compareceram"
                checkPlaceholderNoItem="Não há membro"
                checkOptions={membersToParticipant}
                checkedOptions={participantsAttendedChecked}
                onChangeCheckbox={(itemsValue) => setParticipantsAttendedChecked(itemsValue)}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={9.8}>
            <InputOutlined
              inputTestID="TextInputGuestID"
              inputPlaceholder="Digite aqui o e-mail do convidado"
              inputValue={guest}
              onChangeInput={(textValue) => setGuest(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleGuestAdd()}
            />
          </Grid>
          {guests.length >= 1
            ? (
              <Grid item xs={12}>
                {guests.map((guestLoop) => (
                  <Chip
                    sx={{ mt: 0.7 }}
                    label={guestLoop}
                    color="primary"
                    onDelete={handleGuestDelete(guestLoop)}
                  />
                ))}
              </Grid>
            ) : null}
          <Grid item xs={12}>
            <FormLabel id="input-subject-label">Assunto</FormLabel>
          </Grid>
          <Grid item xs={12} sm={3.25}>
            <InputOutlined
              inputTestID="TextInputSubjectID"
              inputPlaceholder="Digite aqui o assunto"
              inputValue={subject}
              onChangeInput={(textValue) => setSubject(textValue)}
              isSecure={false}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={3.25}>
            <SelectAutocompleteOutlined
              selectTestID="SelectAutocompleteResponsibleID"
              selectPlaceholder="Responsável"
              selectPlaceholderNoItem="Não há membro"
              selectValue={responsibleSelected}
              selectOptions={membersFilteredByArea}
              onChangeSelect={(optionValue) => setResponsibleSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={3.25}>
            <PickerTimeOutlined
              inputTestID="PickerTimeDurationTimeID"
              inputPlaceholder="Duração"
              inputValue={durationTime}
              onChangeInput={(newValue) => setDurationTime(newValue)}
              isDisable={false}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <ButtonSquareContained
              label="INCLUIR"
              onAction={() => handleSubjectAdd()}
            />
          </Grid>
          {subjects.length >= 1
            ? (
              <Grid item xs={12}>
                {subjects.map((subjectLoop) => {
                  const subjectLabelDescription = subjectLoop.description;
                  const subjectLabelName = subjectLoop.responsible.first_name;
                  const subjectLabelDuration = subjectLoop.duration;
                  const chipLabelSubject = `Assunto: ${subjectLabelDescription} Responsável: ${subjectLabelName} Duração: ${subjectLabelDuration}`;
                  return (
                    <Chip
                      sx={{ mt: 0.7 }}
                      label={chipLabelSubject}
                      color="primary"
                      onDelete={handleSubjectDelete(subjectLoop)}
                    />
                  );
                })}
              </Grid>
            ) : null}
          {meetingId !== undefined
            ? (
              <Grid item xs={12}>
                <InputOutlined
                  inputTestID="TextInputCreatedID"
                  inputPlaceholder="Criado por"
                  inputValue={createdBySelected}
                  onChangeInput={(textValue) => setCreatedBySelected(textValue)}
                  isSecure={false}
                  isDisable
                />
              </Grid>
            ) : null}
          {isVisibleRecordFromMeeting ? (
            <>
              <Grid item xs={12}>
                <FormLabel id="input-description-label">Decisão</FormLabel>
              </Grid>
              {decisions.length >= 1
                ? (
                  <Grid item xs={12}>
                    {decisions.map((decisionLoop) => {
                      const subjectLabelDescription = decisionLoop.description;
                      const subjectLabelCreated = calendarDateMask(decisionLoop.start_date);
                      const subjectLabelResponsible = `${decisionLoop.responsible.first_name} ${decisionLoop.responsible.last_name}`;
                      const chipLabelSubject = `Decisão Tomada: ${subjectLabelDescription} Data de registro: ${subjectLabelCreated} Responsável: ${subjectLabelResponsible}`;
                      return (
                        <Chip
                          sx={{ mt: 0.7 }}
                          label={chipLabelSubject}
                          color="primary"
                        />
                      );
                    })}
                  </Grid>
                ) : null}
              <Grid item xs={12} sm={6}>
                <InputOutlined
                  inputTestID="TextInputDescriptionDecisionID"
                  inputPlaceholder="Digite aqui a descrição"
                  inputValue={descriptionDecision}
                  onChangeInput={(textValue) => setDescriptionDecision(textValue)}
                  isSecure={false}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectAutocompleteOutlined
                  selectTestID="SelectAutocompleteResponsibleDecisionID"
                  selectPlaceholder="Responsável"
                  selectPlaceholderNoItem="Não há membro"
                  selectValue={responsibleDecisionSelected}
                  selectOptions={membersToSelect}
                  onChangeSelect={(optionValue) => setResponsibleDecisionSelected(
                    optionValue,
                  )}
                  isDisable={false}
                />
              </Grid>
              <Box sx={{
                display: 'flex', justifyContent: 'center', width: '100%', marginTop: 2,
              }}
              >
                <ButtonContained
                  label="ADICIONAR DECISÃO"
                  onAction={() => sendDecisionCreate()}
                />
              </Box>
              <Grid item xs={12}>
                <FormLabel id="input-description-pendency-label">Pendência</FormLabel>
              </Grid>
              {pendencies.length >= 1
                ? (
                  <Grid item xs={12}>
                    {pendencies.map((pendencyLoop) => {
                      const subjectLabelDescription = pendencyLoop.description;
                      const subjectLabelEnd = calendarDateMask(pendencyLoop.end_date);
                      const subjectLabelResponsible = `${pendencyLoop.responsible.first_name} ${pendencyLoop.responsible.last_name}`;
                      const subjectLabelStatus = pendencyLoop.situation;
                      const chipLabelSubject = `Pendência: ${subjectLabelDescription} Responsável: ${subjectLabelResponsible} Prazo final: ${subjectLabelEnd} Status: ${subjectLabelStatus}`;
                      return (
                        <Chip
                          sx={{ mt: 0.7 }}
                          label={chipLabelSubject}
                          color="primary"
                        />
                      );
                    })}
                  </Grid>
                ) : null}
              <Grid item xs={12} sm={4}>
                <InputOutlined
                  inputTestID="TextInputDescriptionPendencyID"
                  inputPlaceholder="Digite aqui qual a pendência"
                  inputValue={descriptionPendency}
                  onChangeInput={(textValue) => setDescriptionPendency(textValue)}
                  isSecure={false}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectAutocompleteOutlined
                  selectTestID="SelectResponsiblePendencyID"
                  selectPlaceholder="Responsável"
                  selectPlaceholderNoItem="Não há membro"
                  selectValue={responsiblePendencySelected}
                  selectOptions={membersToSelect}
                  onChangeSelect={(optionValue) => setResponsiblePendencySelected(
                    optionValue,
                  )}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectOutlined
                  selectTestID="SelectSituationID"
                  selectPlaceholder="Situação"
                  selectPlaceholderNoItem="Não há situação"
                  selectValue={situationPendencySelected}
                  selectOptions={situations}
                  onChangeSelect={(optionValue) => setSituationPendencySelected(
                    optionValue,
                  )}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PickerDateOutlined
                  inputTestID="PickerDateEndDatePendencyID"
                  inputPlaceholder="Prazo Final"
                  inputValue={endDatePendency}
                  onChangeInput={(newValue) => setEndDatePendency(newValue)}
                  isDisable={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputOutlined
                  inputTestID="TextInputObservationPendencyID"
                  inputPlaceholder="Digite aqui a observação"
                  inputValue={observationPendency}
                  onChangeInput={(textValue) => setObservationPendency(textValue)}
                  isSecure={false}
                  isDisable={false}
                />
              </Grid>
              <Box sx={{
                display: 'flex', justifyContent: 'center', width: '100%', marginTop: 2,
              }}
              >
                <ButtonContained
                  label="ADICIONAR PENDÊNCIA"
                  onAction={() => sendPendencyCreate()}
                />
              </Box>
            </>
          ) : null }
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: meetingId !== undefined ? 'space-between' : 'center',
            marginTop: 2,
          }}
        >
          {meetingId !== undefined
            ? (
              <ButtonContained
                label="Excluir"
                type="error"
                onAction={changeIsVisibleDialog}
              />
            ) : null}
          <ButtonContained
            label={meetingId !== undefined ? 'ALTERAR' : 'CRIAR'}
            onAction={handleSendMeeting}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad
        isVisible={
          isLoadingUser
          || isLoadingArea
          || isLoadingDecision
          || isLoadingPendency
          || isLoadingMeeting
        }
      />
      <ModalDialog
        isVisible={isVisibleDialog}
        onAction={() => deleteMeetingById()}
        onClose={changeIsVisibleDialog}
        title="Aviso"
        description="Realmente deseja excluir essa reunião?"
      />
    </MiniDrawer>
  );
}
