import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { resultSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  resultLoading,
  recordSheetModelSucess,
  resultsSucess,
  changeResultsCount,
  resultSucess,
  recordsSucess,
  statisticsSucess,
  changeStatistics,
  changeYearSelected,
  changeAreaSelected,
  changeIndicatorsSelected,
} = resultSlice.actions;

export const RecordSheetModelFetch = () => async (dispatch) => {
  console.log('RecordSheetModelFetch');
  const configRequest = {
    method: 'get',
    url: '/sheet/get_sheet',
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('RecordSheetModelFetch | response.data:', response.data);
    dispatch(recordSheetModelSucess({ sheetUrl: response.data.sheet.archive }));
  } catch (error) {
    console.log('RecordSheetModelFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const UploadSheetFetch = (
  tokenAuthorization,
  indicatorId,
  sheets,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'UploadSheetFetch | tokenAuthorization, indicatorId, sheets: ',
    tokenAuthorization,
    indicatorId,
    sheets,
  );

  const data = new FormData();

  data.append('indicator_id', indicatorId);
  sheets.forEach((fileLoop) => {
    data.append('sheet', fileLoop);
  });
  console.log('UploadSheetFetch | data', data);

  const configRequest = {
    method: 'post',
    url: '/results/upload_sheet',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: tokenAuthorization,
    },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));
    console.log('UploadSheetFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('UploadSheetFetch | response.data:', response.data);
    dispatch(resultLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/results/indicators', { replace: true });
  } catch (error) {
    console.log('UploadSheetFetch | error:', error);
    dispatch(resultLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [404, 409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const RecordsInLastYearByIndicatorFetch = (
  tokenAuthorization,
  indicatorId,
) => async (dispatch) => {
  console.log(
    'RecordsInLastYearByIndicatorFetch | tokenAuthorization, indicatorId:',
    tokenAuthorization,
    indicatorId,
  );
  const configRequest = {
    method: 'get',
    url: `/results/records_last_year_by_indicator?indicator_id=${indicatorId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('RecordsInLastYearByIndicatorFetch | response.data:', response.data);
    dispatch(recordsSucess({ records: response.data.records }));
  } catch (error) {
    console.log('RecordsInLastYearByIndicatorFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultsFetch = (tokenAuthorization) => async (dispatch) => {
  console.log('ResultsFetch');
  const configRequest = {
    method: 'get',
    url: '/results/results_by_user',
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultsFetch | response.data:', response.data);

    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultsFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultsByPageFetch = (
  tokenAuthorization,
  page,
  areaID,
  dateStart,
  dateEnd,
) => async (dispatch) => {
  console.log(
    'ResultsByPageFetch | tokenAuthorization, page, areaID, dateStart, dateEnd: ',
    tokenAuthorization,
    page,
    areaID,
    dateStart,
    dateEnd,
  );
  const requestParams = { page };
  if (areaID !== '') {
    requestParams.area_id = areaID;
  }
  if (dateStart !== '' && dateEnd !== '') {
    requestParams.date_start = dateStart;
    requestParams.date_end = dateEnd;
  }
  console.log('ResultsByPageFetch | requestParams:', requestParams);
  const configRequest = {
    method: 'get',
    url: '/results/results_by_page',
    params: requestParams,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultsByPageFetch | response.data:', response.data);

    dispatch(changeResultsCount({ count: response.data.count }));
    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultsByPageFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultsBySearchFetch = (tokenAuthorization, searchText, page) => async (dispatch) => {
  console.log('ResultsBySearchFetch | tokenAuthorization, searchText, page):', tokenAuthorization, searchText, page);
  const configRequest = {
    method: 'get',
    url: `/results/results_by_search?text=${searchText}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultsBySearchFetch | response.data:', response.data);
    dispatch(changeResultsCount({ count: response.data.count }));
    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultsBySearchFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultsByUnitFetch = (tokenAuthorization, unit, page) => async (dispatch) => {
  console.log('ResultsByUnitFetch | tokenAuthorization, unit, page):', tokenAuthorization, unit, page);
  const configRequest = {
    method: 'get',
    url: `/results/results_by_unit?unit=${unit}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultsByUnitFetch | response.data:', response.data);
    dispatch(changeResultsCount({ count: response.data.count }));
    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultsByUnitFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultsByAlphabeticFetch = (tokenAuthorization, sortBy, page) => async (dispatch) => {
  console.log(
    'ResultsByAlphabeticFetch | tokenAuthorization, sortBy, page:',
    tokenAuthorization,
    sortBy,
    page,
  );
  const configRequest = {
    method: 'get',
    url: `/results/results_by_page?ordering=${sortBy}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultsByAlphabeticFetch | response.data:', response.data);
    dispatch(changeResultsCount({ count: response.data.count }));
    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultsByAlphabeticFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const ResultByMonthFetch = (
  tokenAuthorization,
  month,
  page,
) => async (dispatch) => {
  console.log(
    'ResultByMonthFetch | tokenAuthorization, month, page:',
    tokenAuthorization,
    month,
    page,
  );
  const configRequest = {
    method: 'get',
    url: `/results/results_by_month?month=${month}&page=${page}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('ResultByMonthFetch | response.data:', response.data);
    dispatch(changeResultsCount({ count: response.data.count }));
    dispatch(resultsSucess({ results: response.data.results }));
  } catch (error) {
    console.log('ResultByMonthFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const StatisticsByIndicatorsFetch = (
  tokenAuthorization,
  indicatorsId,
) => async (dispatch) => {
  console.log(
    'StatisticsByIndicatorsFetch | tokenAuthorization, indicatorsId:',
    tokenAuthorization,
    indicatorsId,
  );

  let indicatorsQueryUrl = '';
  indicatorsId.forEach((typeSelectedCycle, index) => {
    const isMoreParameter = index > 0 ? '&' : '';
    indicatorsQueryUrl += `${isMoreParameter}indicators_id=${typeSelectedCycle}`;
  });

  const configRequest = {
    method: 'get',
    url: `/results/statistic_by_indicators?${indicatorsQueryUrl}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('StatisticsByIndicatorsFetch | response.data:', response.data);
    dispatch(statisticsSucess({ statistics: response.data.statistics }));
  } catch (error) {
    console.log('StatisticsByIndicatorsFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const StatisticsByDateFetch = (
  tokenAuthorization,
  indicatorsId,
  dateSelected,
) => async (dispatch) => {
  console.log(
    'StatisticsByDateFetch | tokenAuthorization, indicatorsId, dateSelected: ',
    tokenAuthorization,
    indicatorsId,
    dateSelected,
  );

  let indicatorsQueryUrl = '';
  indicatorsId.forEach((typeSelectedCycle, index) => {
    const isMoreParameter = index > 0 ? '&' : '';
    indicatorsQueryUrl += `${isMoreParameter}indicators_id=${typeSelectedCycle}`;
  });

  const configRequest = {
    method: 'get',
    url: `/results/statistic_by_date?${indicatorsQueryUrl}&date=${dateSelected}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(resultLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('StatisticsByDateFetch | response.data:', response.data);
    dispatch(statisticsSucess({ statistics: response.data.statistics }));
  } catch (error) {
    console.log('StatisticsByDateFetch | error:', error.message);
    dispatch(resultLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};
