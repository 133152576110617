/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';

import styles from './styles';

export default function ButtonContained({ label, type = 'primary', onAction }) {
  return (
    <Button
      variant="contained"
      size="large"
      color={type}
      onClick={() => onAction()}
      sx={styles.buttonContained}
    >
      {label}
    </Button>
  );
}
