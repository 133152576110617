/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingPendency: false,
  pendencies: [],
  pendency: null,
};

export const pendencySlice = createSlice({
  name: 'pendency',
  initialState,
  reducers: {
    pendencyLoading(state, action) {
      const draft = state;
      console.log('pendencyLoading | action.payload:', action.payload);
      draft.isLoadingPendency = action.payload.isLoading;
    },
    pendenciesSucess: (state, action) => {
      const draft = state;
      console.log('pendenciesSucess | action.payload:', action.payload);

      draft.pendencies = action.payload.pendencies;
      draft.isLoadingPendency = false;
    },
    pendencySucess: (state, action) => {
      const draft = state;
      console.log('pendenciesSucess | action.payload:', action.payload);

      draft.pendency = action.payload.pendency;
      draft.isLoadingPendency = false;
    },
  },
});

export default pendencySlice.reducer;
