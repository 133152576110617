import Axios from '../../api/Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';
import { areaSlice } from './reducer';

import { changeMessageWarning } from '../user/actions';

export const {
  areaLoading,
  changeAreasCount,
  areasSucess,
  areaSucess,
  rolesSucess,
  roleSucess,
} = areaSlice.actions;

export const AreasFetch = () => async (dispatch) => {
  console.log('AreasFetch');
  const configRequest = {
    method: 'get',
    url: '/areas/all_areas',
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('AreasFetch | response.data:', response.data);

    dispatch(areasSucess({ areas: response.data.areas }));
  } catch (error) {
    console.log('AreasFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const AreasByPageFetch = (page) => async (dispatch) => {
  console.log('AreasByPageFetch | page: ', page);
  const configRequest = {
    method: 'get',
    url: `/areas/areas_by_page?page=${page}`,
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('AreasByPageFetch | response.data:', response.data);

    dispatch(changeAreasCount({ count: response.data.count }));
    dispatch(areasSucess({ areas: response.data.results }));
  } catch (error) {
    console.log('AreasByPageFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const AreaCreateFetch = (
  tokenAuthorization,
  name,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'AreaCreateFetch | tokenAuthorization, firstName, lastName, email, area, role, password: ',
    tokenAuthorization,
    name,
  );

  const configRequest = {
    method: 'post',
    url: '/areas/create_area',
    data: { name },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));
    console.log('AreaCreateFetch | configRequest.data', configRequest.data);
    const response = await Axios(configRequest);
    console.log('AreaCreateFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({
      message: response.data.message,
    }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('AreaCreateFetch | error:', error);
    dispatch(areaLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const AreaByIdFetch = (areaId) => async (dispatch) => {
  console.log('AreaByIdFetch | areaId:', areaId);
  const configRequest = {
    method: 'get',
    url: `/areas/area_by_id?area_id=${areaId}`,
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('AreaByIdFetch | response.data:', response.data);
    dispatch(areaSucess({ area: response.data.area }));
  } catch (error) {
    console.log('AreaByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const AreaUpdateByIdFetch = (
  tokenAuthorization,
  areaId,
  name,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'AreaUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, area, role:',
    tokenAuthorization,
    areaId,
    name,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/areas/update_area_by_id',
    data: {
      area_id: areaId,
      name,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('AreaUpdateByIdFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('AreaUpdateByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.data,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const AreaDeleteByIdFetch = (
  tokenAuthorization,
  areaId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'AreaDeleteByIdFetch | tokenAuthorization, areaId, navigateToPage:',
    tokenAuthorization,
    areaId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/areas/delete_area_by_id',
    data: { area_id: areaId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('AreaDeleteByIdFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('AreaDeleteByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const RoleCreateFetch = (
  tokenAuthorization,
  areaId,
  name,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'RoleCreateFetch | tokenAuthorization, areaId, name: ',
    tokenAuthorization,
    areaId,
    name,
  );

  const configRequest = {
    method: 'post',
    url: '/roles/create_role',
    data: { name, area_id: areaId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));
    console.log('RoleCreateFetch | configRequest.body', configRequest.body);
    const response = await Axios(configRequest);
    console.log('RoleCreateFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({
      message: response.data.message,
      type: 'success',
    }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('RoleCreateFetch | error:', error);
    dispatch(areaLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const RoleByIdFetch = (tokenAuthorization, roleId) => async (dispatch) => {
  console.log('RoleByIdFetch | tokenAuthorization, roleId:', tokenAuthorization, roleId);
  const configRequest = {
    method: 'get',
    url: `/roles/role_by_id?role_id=${roleId}`,
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('RoleByIdFetch | response.data:', response.data);
    dispatch(roleSucess({ role: response.data.role }));
  } catch (error) {
    console.log('RoleByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const RoleUpdateByIdFetch = (
  tokenAuthorization,
  roleId,
  name,
  areaId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'RoleUpdateByIdFetch | tokenAuthorization, firstName, lastName, email, role, role:',
    tokenAuthorization,
    roleId,
    name,
    navigateToPage,
  );

  const configRequest = {
    method: 'patch',
    url: '/roles/update_role_by_id',
    data: {
      role_id: roleId,
      name,
      area_id: areaId,
    },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));
    const response = await Axios(configRequest);
    console.log('RoleUpdateByIdFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('RoleUpdateByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    let message;
    const expectedStatusCodes = [409];
    if (expectedStatusCodes.includes(error.response.status)) {
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    dispatch(changeMessageWarning({ message, type: 'error' }));
  }
};

export const RoleDeleteByIdFetch = (
  tokenAuthorization,
  roleId,
  navigateToPage,
) => async (dispatch) => {
  console.log(
    'RoleDeleteByIdFetch | tokenAuthorization, roleId, navigateToPage:',
    tokenAuthorization,
    roleId,
    navigateToPage,
  );
  const configRequest = {
    method: 'delete',
    url: '/roles/delete_role_by_id',
    data: { role_id: roleId },
    headers: { Authorization: tokenAuthorization },
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('RoleDeleteByIdFetch | response.data:', response.data);
    dispatch(areaLoading({ isLoading: false }));
    dispatch(changeMessageWarning({ message: response.data.message, type: 'success' }));
    navigateToPage('/areas', { replace: true });
  } catch (error) {
    console.log('RoleDeleteByIdFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};

export const RolesFetch = () => async (dispatch) => {
  console.log('RolesFetch');
  const configRequest = {
    method: 'get',
    url: '/roles/all_roles',
  };

  try {
    dispatch(areaLoading({ isLoading: true }));

    const response = await Axios(configRequest);
    console.log('RolesFetch | response.data:', response.data);

    dispatch(rolesSucess({ roles: response.data.roles }));
  } catch (error) {
    console.log('RolesFetch | error:', error.message);
    dispatch(areaLoading({ isLoading: false }));
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers,
    );
    dispatch(changeMessageWarning({
      message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
      type: 'error',
    }));
  }
};
