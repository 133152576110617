/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { changeMessageWarning } from '../../store/user/actions';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function SnackBarWarning({
  isVisible,
  type,
  description,
}) {
  const dispatch = useDispatch();

  const onCloseSnack = () => dispatch(changeMessageWarning({ message: '', type: '' }));

  useEffect(() => {
    console.log('useEffect | isVisible: ', isVisible);
    if (isVisible) {
      setTimeout(() => {
        onCloseSnack();
      }, 5000);
    }
  }, [isVisible]);

  return (
    <Snackbar
      open={isVisible}
      onClose={onCloseSnack}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onCloseSnack}
        severity={type}
        sx={{ width: '100%' }}
      >
        {description}
      </Alert>
    </Snackbar>
  );
}
