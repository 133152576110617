function findGoalByDate(indicatorGoals, dateSelected) {
  const goalByDateFound = indicatorGoals.find(
    (indicatorGoalLoop) => {
      const goalStartDatetime = new Date(indicatorGoalLoop.start_date);
      const goalEndDatetime = new Date(indicatorGoalLoop.end_date);
      // console.log(
      //   'findGoalByDate | goalStartDatetime, goalEndDatetime: ',
      //   goalStartDatetime,
      //   goalEndDatetime,
      // );
      return dateSelected > goalStartDatetime && dateSelected < goalEndDatetime;
    },
  );
    // console.log(
    //   'findGoalByDate | goalByDateFound: ',
    //   goalByDateFound,
    // );
  return goalByDateFound !== undefined ? goalByDateFound : null;
}

export default async function getIndicatorGoal(indicatorGoals) {
  const currentDate = new Date();
  console.log(
    'getIndicatorGoal | indicatorGoals, currentDate: ',
    indicatorGoals,
    currentDate,
  );
  if (indicatorGoals.length >= 1) {
    const indicatorGoalByDateFound = findGoalByDate(
      indicatorGoals,
      currentDate,
    );
    console.log('getIndicatorGoal | indicatorGoalByDateFound: ', indicatorGoalByDateFound);
    if (indicatorGoalByDateFound !== null) {
      return indicatorGoalByDateFound.goal;
    }
    return 0;
  }

  return 0;
}
