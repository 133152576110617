/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import MiniDrawer from '../../components/MiniDrawer';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import CardMeeting from './components/CardMeeting';
import ContainerSearchRedirect from '../../components/ContainerSearchRedirect';

import { TERTIARY_COLOR } from '../../styles/colors';
import themeColors from '../../styles/theme_colors';

import { changeMessageWarning } from '../../store/user/actions';
import { MeetingsByPageFetch, MeetingBySearchFetch } from '../../store/meeting/actions';

export default function Meeting({ history }) {
  const { typeWarning, messageWarning, token } = useSelector((state) => state.user);
  const { isLoadingMeeting, meetings, meetingsCount } = useSelector((state) => state.meeting);

  const [searchText, setSearchText] = useState('');
  const [meetingsPerPage, setMeetingsPerPage] = useState(12);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = createTheme(themeColors.authenticated);

  async function getMeetingsByPage(currentPage) {
    try {
      dispatch(MeetingsByPageFetch(token, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  function clearSearch() {
    setSearchText('');
    setPage(0);
    getMeetingsByPage(0);
  }

  async function getMeetingsBySearch(currentPage) {
    try {
      dispatch(MeetingBySearchFetch(token, searchText, currentPage + 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getMeetingsByPage(page);
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchText) {
      console.log('handleChangePage | newPage, searchText: ', newPage, searchText);
      getMeetingsBySearch(newPage);
    } else {
      console.log('handleChangePage | newPage: ', newPage);
      getMeetingsByPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setMeetingsPerPage(+event.target.value);
    setPage(0);
  };

  function validateMeetingsSearch() {
    console.log('validateMeetingsSearch | searchText: ', searchText);
    let message = '';

    if (!searchText) {
      message = 'Preencha o campo pesquisa de reunião por tema';
    } else if (searchText.length < 3) {
      message = 'Campo pesquisa de reunião por tema é inválido';
    }

    return { isInvalid: !!message, message };
  }

  async function onSearchMeetings() {
    const responsevalidateMeetingsSearch = await validateMeetingsSearch();
    console.log('onSearchMeetings | responsevalidateMeetingsSearch: ', responsevalidateMeetingsSearch);

    if (responsevalidateMeetingsSearch.isInvalid) {
      dispatch(changeMessageWarning({
        message: responsevalidateMeetingsSearch.message,
        type: 'warning',
      }));
    } else {
      setPage(0);
      getMeetingsBySearch(0);
    }
  }

  const onChangeSearchText = (textValue) => {
    if (textValue === '') {
      clearSearch();
    }
    setSearchText(textValue);
  };

  function renderCardMeeting(meetingToRender) {
    return (
      <CardMeeting
        meetingParam={meetingToRender}
        onAction={() => navigate(`/meetings/${meetingToRender.id}/edit`, { replace: true })}
      />
    );
  }

  return (
    <MiniDrawer>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          fixed
          component="main"
          maxWidth={false}
          sx={{ mb: 4, mt: 11 }}
        >
          <Paper elevation={3} sx={{ padding: 3 }}>
            <ContainerSearchRedirect
              placeholderSearch="Pesquisar por reunião"
              valueSearch={searchText}
              onChangeSearch={onChangeSearchText}
              onSearch={() => onSearchMeetings()}
              labelRedirect="CRIAR REUNIÃO"
              onRedirect={() => navigate('/meetings/create', { replace: true })}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              my: { xs: 3, md: 6 },
              p: { xs: 2, md: 0.5 },
              backgroundColor: TERTIARY_COLOR,
            }}
          >
            <Grid item xs={12} sm={10.25}>
              {meetings.length === 0 ? (
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="text.secondary"
                  align="center"
                >
                  Não há registro de reunião
                </Typography>
              ) : null}
            </Grid>

            <Grid container spacing={3}>
              {meetings !== undefined ? (
                meetings.map((meetingLoop) => renderCardMeeting(meetingLoop))
              ) : null}
            </Grid>
            <TablePagination
              rowsPerPageOptions={[meetingsPerPage]}
              component="div"
              count={meetingsCount}
              rowsPerPage={meetingsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Container>
      </ThemeProvider>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingMeeting} />
    </MiniDrawer>
  );
}
