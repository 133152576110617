/* eslint-disable react/prop-types */
import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import itemsByAlphabeticalOrder from '../../utils/itemsByAlphabeticalOrder';

export default function SelectOutlined({
  selectTestID,
  selectPlaceholder,
  selectPlaceholderNoItem,
  selectValue,
  selectOptions,
  onChangeSelect,
  isDisable = true,
}) {
  const onChangeValue = ({ target: { value } }) => {
    onChangeSelect(value);
  };

  function renderSelect() {
    const noItemSelect = (
      <MenuItem value="">
        <em>{selectPlaceholderNoItem}</em>
      </MenuItem>
    );
    if (selectOptions !== undefined) {
      if (selectOptions.length >= 1) {
        const selectOptionsAlphabetical = itemsByAlphabeticalOrder(selectOptions);

        return (
          selectOptionsAlphabetical.map((areaLoop) => (
            <MenuItem value={areaLoop}>
              {areaLoop}
            </MenuItem>
          ))
        );
      }
      return noItemSelect;
    }
    return noItemSelect;
  }

  return (
    <FormControl fullWidth disabled={isDisable}>
      <InputLabel id={`${selectTestID}-label`}>{selectPlaceholder}</InputLabel>
      <Select
        labelId={`${selectTestID}-label`}
        id={selectTestID}
        value={selectValue}
        label={selectPlaceholder}
        onChange={onChangeValue}
      >
        {renderSelect()}
      </Select>
    </FormControl>
  );
}
