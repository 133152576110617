/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
export default function numericDotMask(amount) {
  let decimalCount = 0;
  const decimal = ',';
  const thousands = '.';

  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const amountInteger = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
  const amountLength = (amountInteger.length > 3) ? amountInteger.length % 3 : 0;

  return `${negativeSign + (amountLength ? amountInteger.substr(0, amountLength) + thousands : '') + amountInteger.substr(amountLength).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - amountInteger).toFixed(decimalCount).slice(2) : '')}`;
}
