/* eslint-disable react/prop-types */
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormLabel from '@mui/material/FormLabel';
import 'dayjs/locale/pt-br';

import CheckboxItem from './components/CheckboxItem';

import itemsByAlphabeticalOrder from '../../utils/itemsByAlphabeticalOrder';

export default function CheckboxList({
  checkTestID,
  checkPlaceholder,
  checkPlaceholderNoItem,
  checkOptions,
  checkedOptions,
  onChangeCheckbox,
}) {
  console.log(
    'CheckboxList: ',
    checkTestID,
    checkPlaceholder,
    checkPlaceholderNoItem,
    checkOptions,
    checkedOptions,
  );
  const onAddCheckboxItem = (itemToAdd) => () => {
    const itemsCheckedUptaded = [...checkedOptions, itemToAdd];
    console.log('onAddItem | itemsCheckedUptaded: ', itemsCheckedUptaded);
    onChangeCheckbox(itemsCheckedUptaded);
  };

  const onRemoveCheckboxItem = (itemToRemove) => () => {
    console.log('handleParticipantRemove | itemToRemove: ', itemToRemove);
    const itemsCheckedUptaded = checkedOptions.filter(
      (checkedOptionCycle) => checkedOptionCycle !== itemToRemove,
    );
    onChangeCheckbox(itemsCheckedUptaded);
  };

  function renderSelect() {
    const noItemSelect = (
      <ListItem disablePadding>
        <ListItemText id={0} primary={checkPlaceholderNoItem} />
      </ListItem>
    );
    console.log('onAddItem | checkOptions: ', checkOptions);
    if (checkOptions !== undefined) {
      if (checkOptions.length >= 1) {
        const checkOptionsAlphabetical = itemsByAlphabeticalOrder(checkOptions);
        return (
          checkOptionsAlphabetical.map((checkOptionLoop) => (
            <CheckboxItem
              itemCheckbox={checkOptionLoop}
              isChecked={checkedOptions.includes(checkOptionLoop)}
              onRemove={onRemoveCheckboxItem}
              onAdd={onAddCheckboxItem}
            />
          ))
        );
      }
      return noItemSelect;
    }
    return noItemSelect;
  }

  return (
    <>
      <FormLabel id={checkTestID}>{checkPlaceholder}</FormLabel>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {renderSelect()}
      </List>
    </>
  );
}
