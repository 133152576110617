/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AreaItem from '../AreaItem';

export default function AreaItems({ itemsArea }) {
  const navigate = useNavigate();

  return (
    itemsArea !== undefined ? (itemsArea.map(
      (areaLoop) => (
        <AreaItem
          itemArea={areaLoop}
          onAction={() => navigate(`/areas/${areaLoop.id}/edit`, { replace: true })}
        />
      ),
    )) : null
  );
}
