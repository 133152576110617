/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

import RoleItems from '../RoleItems';

export default function AreaItem({ itemArea, onAction }) {
  const [isCollapse, setIsCollapse] = useState(false);
  const rolesLength = itemArea.roles !== undefined ? (itemArea.roles).length : 0;
  // console.log('AreaItem | itemArea: ', itemArea);
  return (
    <>
      <TableRow
        key={itemArea.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {rolesLength >= 1
          ? (
            <TableCell sx={{ width: 20 }}>
              <IconButton
                aria-label={`button collapse ${itemArea.name}`}
                size="small"
                onClick={() => setIsCollapse(!isCollapse)}
              >
                {isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (<TableCell sx={{ width: 20 }} />)}
        <TableCell sx={{ width: 20 }}>
          <IconButton
            aria-label={`button edit ${itemArea.name}`}
            size="small"
            onClick={() => onAction()}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {itemArea.name}
        </TableCell>
      </TableRow>
      {rolesLength >= 1
        ? (
          <RoleItems
            itemsRole={itemArea.roles}
            isVisible={isCollapse}
          />
        ) : null }
    </>
  );
}
