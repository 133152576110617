/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingUser: false,
  messageWarning: '',
  typeWarning: '',
  token: '',
  avatar: '',
  firstName: '',
  lastName: '',
  email: '',
  area: '',
  role: '',
  type: '',
  members: [],
  membersCount: 0,
  member: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoading(state, action) {
      const draft = state;
      console.log('userLoading | action.payload:', action.payload);
      draft.isLoadingUser = action.payload.isLoading;
    },
    changeMessageWarning(state, action) {
      const draft = state;
      console.log('changeMessageWarning | action.payload:', action.payload);
      draft.messageWarning = action.payload.message;
      draft.typeWarning = action.payload.type;
    },
    signInSuccess: (state, action) => {
      const draft = state;
      console.log('signInSuccess | action.payload:', action.payload);
      const {
        token,
        profile_picture,
        first_name,
        last_name,
        email,
        area,
        role,
        user_type,
      } = action.payload;

      draft.token = token;
      draft.avatar = profile_picture;
      draft.firstName = first_name;
      draft.lastName = last_name;
      draft.email = email;
      draft.area = area.name;
      draft.role = role.name;
      draft.type = user_type;
      draft.isLoadingUser = false;
    },
    profileSuccess: (state, action) => {
      const draft = state;
      // console.log('profileSuccess | action.payload:', action.payload);
      const {
        profile_picture,
        first_name,
        last_name,
        email,
        area,
        role,
        user_type,
      } = action.payload;

      draft.avatar = profile_picture;
      draft.firstName = first_name;
      draft.lastName = last_name;
      draft.email = email;
      draft.area = area.name;
      draft.role = role.name;
      draft.type = user_type;
      draft.isLoadingUser = false;
    },
    membersSucess: (state, action) => {
      const draft = state;
      console.log('membersSucess | action.payload:', action.payload);

      draft.members = action.payload.members;
      draft.isLoadingUser = false;
    },
    memberSucess: (state, action) => {
      const draft = state;
      console.log('membersSucess | action.payload:', action.payload);

      draft.member = action.payload.member;
      draft.isLoadingUser = false;
    },
    changeMembersCount: (state, action) => {
      const draft = state;
      console.log('changeMembersCount | action.payload:', action.payload);

      draft.membersCount = action.payload.count;
    },
    signOut: (state) => {
      const draft = state;
      console.log('signOut');

      draft.token = '';
      draft.name = '';
      draft.email = '';
    },
  },
});

export default userSlice.reducer;
