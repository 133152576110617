/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useMediaQuery from '@mui/material/useMediaQuery';

import MiniDrawer from '../../components/MiniDrawer';
import ContainerPaper from '../../components/ContainerPaper';
import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import Header from '../../components/Header';
import SelectOutlined from '../../components/SelectOutlined';
import ButtonContained from '../../components/ButtonContained';
import InputFile from '../../components/InputFile';
import ButtonTransparent from '../../components/ButtonTransparent';

import { changeMessageWarning } from '../../store/user/actions';
import { UploadSheetFetch, RecordSheetModelFetch } from '../../store/result/actions';
import { IndicatorsFetch } from '../../store/indicator/actions';

export default function RecordForm({ history }) {
  const {
    typeWarning,
    messageWarning,
    token,
    type,
    area,
  } = useSelector((state) => state.user);
  const { isLoadingIndicator, indicators } = useSelector((state) => state.indicator);
  const { isLoadingResult, sheetUrl } = useSelector((state) => state.result);

  const [indicatorSelected, setIndicatorSelected] = useState('');
  const [indicatorsFilteredByArea, setIndicatorsFilteredByArea] = useState([]);
  const [file, setFile] = useState('');
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMatches = useMediaQuery('(min-width:795px)');

  useEffect(() => {
    async function getIndicators() {
      try {
        dispatch(IndicatorsFetch(token));
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    async function getRecordSheetModel() {
      try {
        dispatch(RecordSheetModelFetch());
      } catch (error) {
        dispatch(changeMessageWarning({
          message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
          type: 'error',
        }));
      }
    }

    getIndicators();
    getRecordSheetModel();
  }, [history]);

  useEffect(() => {
    if (type !== 'admin') {
      const indicatorsFromArea = indicators.filter(
        (indicatorCycle) => indicatorCycle.area.name === area,
      );
      const indicatorsOptions = indicatorsFromArea.map(
        (indicatorTwister) => indicatorTwister.name,
      );
      setIndicatorsFilteredByArea(indicatorsOptions);
    } else {
      const indicatorsOptions = indicators.map(
        (indicatorTwister) => indicatorTwister.name,
      );
      setIndicatorsFilteredByArea(indicatorsOptions);
    }
  }, [indicators]);

  function validateAction() {
    console.log(
      'validateAction | indicatorSelected: ',
      indicatorSelected,
    );
    let message = '';

    if (!indicatorSelected) {
      message = 'Selecione um indicador';
    } else if (files.length === 0) {
      message = 'Anexe o arquivo de planilha';
    }

    return { isInvalid: !!message, message };
  }

  async function sendRecordUploadSheet() {
    try {
      const responseValidateAction = await validateAction();
      console.log('sendRecordUploadSheet | responseValidateAction ', responseValidateAction);

      if (responseValidateAction.isInvalid) {
        dispatch(changeMessageWarning({
          message: responseValidateAction.message,
          type: 'warning',
        }));
      } else {
        const indicatorFound = indicators.find(
          (indicatorCycle) => indicatorCycle.name === indicatorSelected,
        );
        console.log('sendRecordUploadSheet | indicatorFound: ', indicatorFound);

        dispatch(UploadSheetFetch(
          token,
          indicatorFound.id,
          files,
          navigate,
        ));
      }
    } catch (error) {
      console.log('sendRecordUploadSheet | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  const handleFileDelete = (fileToDelete) => () => {
    console.log('handleSubjectDelete | subjectToDelete: ', fileToDelete);
    const filesUptaded = files.filter((fileCycle) => fileCycle !== fileToDelete);
    setFiles(filesUptaded);
  };

  const onAddFile = (urlBlobValue, uriValue) => {
    console.log('handleSubjectAdd | urlBlobValue, uriValue: ', urlBlobValue, uriValue);
    const fileNew = uriValue;
    console.log('handleSubjectAdd | subjectNew: ', fileNew);
    const filesUptaded = [...files, fileNew];
    console.log('handlefileAdd | filesUptaded: ', filesUptaded);
    setFiles(filesUptaded);
    setFile('');
  };

  function redirectToRecordModelPdf() {
    window.open(
      sheetUrl,
      '_blank',
    );
  }

  return (
    <MiniDrawer pageTitle="Criar registro">
      <ContainerPaper>
        <Header title="Informações do registro" />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectOutlined
              selectTestID="SelectIndicatorID"
              selectPlaceholder="Indicador"
              selectPlaceholderNoItem="Não há indicador"
              selectValue={indicatorSelected}
              selectOptions={indicatorsFilteredByArea}
              onChangeSelect={(optionValue) => setIndicatorSelected(optionValue)}
              isDisable={false}
            />
          </Grid>
          {files.length >= 1
            ? (
              <Grid item xs={12}>
                {files.map((fileLoop) => {
                  const fileLabelFile = fileLoop.name;
                  return (
                    <Chip
                      sx={{ mt: 0.7, ml: 1 }}
                      color="primary"
                      label={`Arquivo: ${fileLabelFile}`}
                      onDelete={handleFileDelete(fileLoop)}
                    />
                  );
                })}
              </Grid>
            ) : null}
          {' '}
        </Grid>
        <Box sx={isMatches
          ? { display: 'flex', justifyContent: 'flex-start', marginTop: 2 }
          : {
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', height: 90, marginTop: 2,
          }}
        >
          <InputFile
            inputTestID="upload-spreadsheet"
            inputPlaceholder="ANEXAR PLANILHA"
            inputSource={file}
            extensionsFile=".xlsx"
            onChangeInput={onAddFile}
          />
          {sheetUrl !== '' && sheetUrl !== undefined
            ? (
              <ButtonTransparent
                label="MODELO PADRÃO"
                onAction={() => redirectToRecordModelPdf()}
                icon={<ContentCopyIcon />}
              />
            ) : null}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <ButtonContained
            label="CRIAR"
            onAction={() => sendRecordUploadSheet()}
          />
        </Box>
      </ContainerPaper>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingResult || isLoadingIndicator} />
    </MiniDrawer>
  );
}
