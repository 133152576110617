/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';

import { changeMessageWarning, signOut } from '../../../../store/user/actions';
import { NotificationsByPageFetch } from '../../../../store/notification/actions';

import { ON_PRIMARY_COLOR, ON_SECONDARY_COLOR } from '../../../../styles/colors';

export default function DrawerBar({
  title,
  isOpen,
  onClose,
}) {
  const {
    token,
    avatar,
    firstName,
  } = useSelector((state) => state.user);
  const { notifications } = useSelector((state) => state.notification);

  const [countNotReadNotifications, setCountNotReadNotifications] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerWidth = 240;

  async function getNotificationsByPage() {
    try {
      dispatch(NotificationsByPageFetch(token, 1));
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  useEffect(() => {
    getNotificationsByPage();
  }, []);

  useEffect(() => {
    console.log('useEffect | notifications: ', notifications);
    const countNotificationsNotRead = notifications.filter(
      (notificationLoop) => !notificationLoop.is_read,
    );
    console.log('useEffect | countNotificationsNotRead: ', countNotificationsNotRead);
    setCountNotReadNotifications(countNotificationsNotRead.length);
  }, [notifications]);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  function handleVisible() {
    setIsVisible(!isVisible);
  }

  const handleNotification = () => {
    handleVisible();
    navigate('/notifications', { replace: true });
  };

  const handleProfile = () => {
    handleVisible();
    navigate('/profile', { replace: true });
  };

  const handleRedefinePassword = () => {
    handleVisible();
    navigate('/redefine-password', { replace: true });
  };

  const handleSignOut = () => {
    try {
      handleVisible();
      dispatch(signOut());
    } catch (error) {
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  };

  return (
    <AppBar position="fixed" open={isOpen} sx={{ bgcolor: ON_SECONDARY_COLOR, color: ON_PRIMARY_COLOR }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => onClose()}
          edge="start"
          sx={{
            marginRight: 1.5,
            ...(isOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton
          aria-label="notification"
          onClick={handleNotification}
          fontSize="large"
          edge="start"
          sx={{
            marginRight: 3,
            color: '#343845',
          }}
        >
          <Badge badgeContent={countNotReadNotifications} color="error">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <Tooltip title="Open settings">
          <IconButton onClick={() => handleVisible()} sx={{ p: 0 }}>
            <Avatar alt={firstName} src={avatar} />
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isVisible}
          onClose={() => handleVisible()}
          sx={{ marginTop: 8 }}
        >
          <MenuItem onClick={handleProfile}>Perfil</MenuItem>
          <MenuItem onClick={handleRedefinePassword}>Redefinir Senha</MenuItem>
          <MenuItem onClick={handleSignOut}>Sair</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
