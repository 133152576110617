/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import calendarDateMask from '../../../../utils/masks/calendarDateMask';

export default function DecisionItems({
  itemsDecision,
  onAction,
}) {
  return (
    itemsDecision !== undefined ? (itemsDecision.map(
      (decisionLoop) => (
        <TableRow
          key={decisionLoop.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={() => onAction(decisionLoop.id)}
        >
          <TableCell component="th" scope="row">
            {decisionLoop.description}
          </TableCell>
          <TableCell align="left">
            {calendarDateMask(decisionLoop.start_date)}
          </TableCell>
          <TableCell align="left">
            {`${decisionLoop.responsible.first_name} ${decisionLoop.responsible.last_name}`}
          </TableCell>
        </TableRow>
      ),
    )) : null
  );
}
