import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/reducer';
import notificationReducer from './notification/reducer';
import areaReducer from './area/reducer';
import meetingReducer from './meeting/reducer';
import decisionReducer from './decision/reducer';
import pendencyReducer from './pendency/reducer';
import indicatorReducer from './indicator/reducer';
import actionReducer from './action/reducer';
import resultReducer from './result/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  area: areaReducer,
  meeting: meetingReducer,
  decision: decisionReducer,
  pendency: pendencyReducer,
  indicator: indicatorReducer,
  action: actionReducer,
  result: resultReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducerStore = persistReducer(persistConfig, rootReducer);

export default persistedReducerStore;
