/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';

export default function ButtonTransparent({
  label,
  type = 'primary',
  icon = null,
  onAction,
}) {
  return (
    <Button
      variant="text"
      size="large"
      color={type}
      endIcon={icon}
      onClick={() => onAction()}
    >
      {label}
    </Button>
  );
}
