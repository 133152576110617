/* eslint-disable react/prop-types */
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function BackDropLoad({ isVisible }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isVisible}
      onClick={() => console.log('nothing to close...')}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
