/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './styles';

export default function MonthChips({
  chipsMonth,
  chipValue,
  onUpdate,
}) {
  const isMatches = useMediaQuery('(min-width:795px)');

  return (
    <Box sx={isMatches ? {
      display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0px',
    } : { display: 'flex', flexWrap: 'wrap', margin: '10px 0px' }}
    >
      {chipsMonth.map((monthLoop) => (
        <Chip
          variant={chipValue === monthLoop ? 'outlined' : 'filled'}
          onClick={() => onUpdate(monthLoop)}
          label={monthLoop}
          sx={{
            backgroundColor: chipValue === monthLoop ? 'orange' : '#575E6C',
            ...styles.containerChipMonth,
          }}
          size="medium"
        />
      ))}
    </Box>
  );
}
