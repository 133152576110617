/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingAction: false,
  actions: [],
  actionsCount: 0,
  action: null,
};

export const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    actionLoading(state, action) {
      const draft = state;
      //   console.log('actionLoading | action.payload:', action.payload);
      draft.isLoadingAction = action.payload.isLoading;
    },
    actionsSucess: (state, action) => {
      const draft = state;
      //   console.log('actionsSucess | action.payload:', action.payload);

      draft.actions = action.payload.actions;
      draft.isLoadingAction = false;
    },
    changeActionsCount: (state, action) => {
      const draft = state;
      //   console.log('changeActionsCount | action.payload:', action.payload);

      draft.actionsCount = action.payload.count;
    },
    actionSucess: (state, action) => {
      const draft = state;
      //   console.log('actionsSucess | action.payload:', action.payload);

      draft.action = action.payload.action;
      draft.isLoadingAction = false;
    },
  },
});

export default actionSlice.reducer;
