const styles = {
  containerInputAvatar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  containerAvatar: {
    width: 80,
    height: 80,
    marginBottom: 4,
    marginRight: 2,
  },
};

export default styles;
