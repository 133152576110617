/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoadingArea: false,
  areas: [],
  areasCount: 0,
  area: null,
  roles: [],
  role: null,
};

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    areaLoading(state, action) {
      const draft = state;
      // console.log('areaLoading | action.payload:', action.payload);
      draft.isLoadingArea = action.payload.isLoading;
    },
    changeAreasCount: (state, action) => {
      const draft = state;
      // console.log('changeAreasCount | action.payload:', action.payload);

      draft.areasCount = action.payload.count;
    },
    areasSucess: (state, action) => {
      const draft = state;
      // console.log('areasSucess | action.payload:', action.payload);

      draft.areas = action.payload.areas;
      draft.isLoadingArea = false;
    },
    areaSucess: (state, action) => {
      const draft = state;
      // console.log('areaSucess | action.payload:', action.payload);

      draft.area = action.payload.area;
      draft.isLoadingArea = false;
    },
    rolesSucess: (state, action) => {
      const draft = state;
      // console.log('rolesSucess | action.payload:', action.payload);

      draft.roles = action.payload.roles;
      draft.isLoadingArea = false;
    },
    roleSucess: (state, action) => {
      const draft = state;
      // console.log('rolesSucess | action.payload:', action.payload);

      draft.role = action.payload.role;
      draft.isLoadingArea = false;
    },
  },
});

export default areaSlice.reducer;
