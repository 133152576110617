/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import calendarDateMask from '../../../../utils/masks/calendarDateMask';

const situations = require('../../../../utils/resources/situations.json');
const situationColors = require('../../../../utils/resources/situationColors.json');

export default function PendencyItems({
  itemsPendency,
  onAction,
}) {
  return (
    itemsPendency !== undefined ? (itemsPendency.map(
      (pendencyLoop) => (
        <TableRow
          key={pendencyLoop.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={() => onAction(pendencyLoop.id)}
        >
          <TableCell align="left">
            {pendencyLoop.description}
          </TableCell>
          <TableCell align="left">{`${pendencyLoop.responsible.first_name} ${pendencyLoop.responsible.last_name}`}</TableCell>
          <TableCell align="left">{calendarDateMask(pendencyLoop.end_date)}</TableCell>
          <TableCell align="left" sx={{ fontWeight: 'bold', color: situationColors[situations.indexOf(pendencyLoop.situation)] }}>
            {pendencyLoop.situation}
          </TableCell>
        </TableRow>
      ),
    )) : null
  );
}
