/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxItem({
  itemCheckbox,
  isChecked,
  onRemove,
  onAdd,
}) {
  const labelId = `checkbox-list-label-${itemCheckbox}`;

  return (
    <ListItem disablePadding>
      <ListItemButton
        role={undefined}
        onClick={isChecked ? onRemove(itemCheckbox) : onAdd(itemCheckbox)}
        dense
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={itemCheckbox} />
      </ListItemButton>
    </ListItem>
  );
}
