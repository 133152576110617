/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';

import calendarWithHourAndMinutesMask from '../../../../utils/masks/calendarWithHourAndMinutesMask';

export default function NotificationItem({ itemNotification }) {
  return (
    <TableRow>
      {!itemNotification.is_read ? (
        <TableCell component="th" scope="row">
          <CircleIcon color="error" fontSize="small" />
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell component="th" scope="row">
        {itemNotification.message}
      </TableCell>
      <TableCell align="right">{calendarWithHourAndMinutesMask(itemNotification.created)}</TableCell>
    </TableRow>
  );
}
