export default function calendarDateMask(date) {
  try {
    // console.log('calendarDateMask | date: ', date);
    const day = date.substring(8, 10);
    // console.log('calendarDateMask | day: ', day);
    const month = date.substring(5, 7);
    // console.log('calendarDateMask | month: ', month);
    const year = date.substring(0, 4);
    // console.log('calendarDateMask | year: ', year);

    return `${day}/${month}/${year}`;
  } catch (error) {
    return '--/--/----';
  }
}
