const styles = {
  containerCardMeeting: {
    borderRadius: '12px',
    backgroundColor: '#FFF',
  },
  containerCardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  containerCardContentSpacing: { marginRight: 8 },
  textCardContentLabel: { fontSize: 11, fontWeight: 'bold' },
  textCardContentValue: { fontSize: 10 },
};

export default styles;
