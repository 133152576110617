import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';

import themeColors from '../../styles/theme_colors';
import globalStyles from '../../styles/global_styles';

import SnackBarWarning from '../../components/SnackBarWarning';
import BackDropLoad from '../../components/BackDropLoad';
import InputRounded from '../../components/InputRounded';

import { ForgotPasswordFetch, changeMessageWarning } from '../../store/user/actions';

const imageLogoAlvoAzul = require('../../assets/logo_alvo_azul.png');

export default function ForgotPassword() {
  const [email, setEmail] = useState('');

  const {
    isLoadingUser,
    typeWarning,
    messageWarning,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = createTheme(themeColors.unauthenticated);

  function validateForgotPassword() {
    let message = '';

    if (!email) {
      message = 'Preencha o e-mail';
    }

    return { isInvalid: !!message, message };
  }

  async function sendForgotPassword() {
    try {
      const responseValidateForgotPassword = await validateForgotPassword();
      console.log('sendForgotPassword | responseValidateForgotPassword: ', responseValidateForgotPassword);

      if (responseValidateForgotPassword.isInvalid) {
        dispatch(changeMessageWarning({ message: responseValidateForgotPassword.message, type: 'warning' }));
      } else {
        dispatch(ForgotPasswordFetch(email, navigate));
      }
    } catch (error) {
      console.log('sendForgotPassword | error: ', error);
      dispatch(changeMessageWarning({
        message: 'no momento esse recurso está indisponível, tente novamente mais tarde.',
        type: 'error',
      }));
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container component="main" maxWidth="sm" sx={globalStyles.containerUnauthenticated}>
          <Paper variant="elevation" elevation={0} sx={globalStyles.containerPaper}>
            <Grid container spacing={3}>
              <Grid item xs={12} marginBottom={3}>
                <CardMedia
                  component="img"
                  height="213"
                  sx={{ objectFit: 'contain' }}
                  image={imageLogoAlvoAzul}
                  alt="alvo logo blue"
                />
              </Grid>
            </Grid>
            <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
              Recuperar senha
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom sx={{ marginBottom: 2 }}>
              Para recuperar sua senha,
              digite abaixo o e-mail que você utiliza para acessar sua Conta Alvo!
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputRounded
                  inputTestID="TextInputEmailID"
                  inputPlaceholder="Digite aqui seu e-mail"
                  inputValue={email}
                  onChangeInput={(textValue) => setEmail(textValue)}
                  isSecure={false}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate('/', { replace: true })}
                sx={{ mt: 3, borderRadius: '20px' }}
              >
                VOLTAR
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => sendForgotPassword()}
                sx={{ mt: 3, ml: 1, borderRadius: '20px' }}
              >
                ENVIAR
              </Button>
            </Box>
            <Typography
              variant="subtitle1"
              align="center"
              gutterBottom
              sx={{ marginBottom: 2, marginTop: 2 }}
            >
              Enviaremos um e-mail com as instruções.
              Se você não utiliza mais o e-mail cadastrado na Conta Alvo,
              fale com o time responsável pelo sistema Alvo.
            </Typography>
          </Paper>
        </Container>
      </ThemeProvider>
      <SnackBarWarning
        isVisible={!!messageWarning}
        type={typeWarning}
        description={messageWarning}
      />
      <BackDropLoad isVisible={isLoadingUser} />
    </>
  );
}
