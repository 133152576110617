/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@mui/material/Typography';
import {
  ComposedChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import calculeProgressColorBiggerThen from '../../utils/calculeProgressColorBiggerThen';
import calculeProgressColorLessThen from '../../utils/calculeProgressColorLessThen';
import numericDotMask from '../../utils/masks/numericDotMask';

export default function RecordChartBar({
  indicatorParam,
  dataParam,
  chartWith,
  chartMonth,
}) {
  const isMatches = useMediaQuery('(min-width:795px)');

  // console.log(
  //   'RecordChartBar | indicatorParam, dataParam, chartWith, chartMonth',
  //   indicatorParam,
  //   dataParam,
  //   chartWith,
  //   chartMonth,
  // );

  function calculateSumRecord(indicatorRecords, recordPropertyName) {
    const sumIndicatorRecords = (indicatorRecords).reduce(
      (accumulator, currentValue) => accumulator + currentValue[recordPropertyName],
      0,
    );

    return numericDotMask(sumIndicatorRecords);
  }

  function calculateAverageRecord(indicatorRecords, recordPropertyName) {
    const sumIndicatorRecords = (indicatorRecords).reduce(
      (accumulator, currentValue) => accumulator + currentValue[recordPropertyName],
      0,
    );

    const averageIndicatorRecords = (sumIndicatorRecords / indicatorRecords.length);

    return numericDotMask(Math.round(averageIndicatorRecords));
  }

  function renderBarCell(dataCell) {
    // console.log(
    //   'renderBarCell | criterion, dataCell.goal, dataCell.done_percentage, dataCell.name: ',
    //   indicatorParam.criterion,
    //   dataCell.goal,
    //   dataCell.done_percentage,
    //   dataCell.name,
    // );
    let currentProgressColor = '#A6A6A6';
    if (indicatorParam.criterion === 'MAIOR QUE' && dataCell.goal > 0) {
      currentProgressColor = calculeProgressColorBiggerThen(
        dataCell.done_percentage,
      );
      // console.log(
      //   'calculeProgressColorBiggerThen | currentProgressColor: ',
      //   currentProgressColor,
      // );
    }
    if (indicatorParam.criterion === 'MENOR QUE' && dataCell.goal > 0) {
      currentProgressColor = calculeProgressColorLessThen(
        dataCell.done_percentage,
      );
      // console.log(
      //   'calculeProgressColorLessThen | currentProgressColor: ',
      //   currentProgressColor,
      // );
    }
    // console.log(
    //   'renderBarCell | currentProgressColor: ',
    //   currentProgressColor,
    // );
    return (
      <Cell key={`cell-${dataCell.name}`} fill={currentProgressColor} />
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginRight: 5,
    }}
    >
      <Typography sx={{ fontSize: chartWith * 0.027, fontWeight: 'bold', marginBottom: 1.5 }} align="center">
        {indicatorParam.name}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMatches ? 'row' : 'column',
          justifyContent: 'space-between',
          margin: '15px 0px',
        }}
      >
        <Box>
          <Typography sx={{ fontSize: chartWith * 0.027, fontWeight: 'bold' }} align="center">
            ACUMULADO META
          </Typography>
          <Typography sx={{ fontSize: chartWith * 0.027 }} align="center">
            {indicatorParam.unit_measurement !== '%'
              ? calculateSumRecord(chartMonth === 12 ? (dataParam) : (dataParam).slice(0, chartMonth), 'goal')
              : calculateAverageRecord(chartMonth === 12 ? (dataParam) : (dataParam).slice(0, chartMonth), 'goal')}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: chartWith * 0.027, fontWeight: 'bold' }} align="center">
            ACUMULADO ANO ATUAL
          </Typography>
          <Typography sx={{ fontSize: chartWith * 0.027 }} align="center">
            {indicatorParam.unit_measurement !== '%'
              ? calculateSumRecord(dataParam, 'value')
              : calculateAverageRecord(dataParam, 'value')}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: chartWith * 0.027, fontWeight: 'bold' }} align="center">
            ACUMULADO ANO ANTERIOR
          </Typography>
          <Typography sx={{ fontSize: chartWith * 0.027 }} align="center">
            {indicatorParam.unit_measurement !== '%'
              ? calculateSumRecord(dataParam, 'value_last_year')
              : calculateAverageRecord(dataParam, 'value_last_year')}
          </Typography>
        </Box>
      </Box>
      <ComposedChart
        width={chartWith}
        height={chartWith * 0.55}
        data={dataParam}
        // style={{ backgroundColor: 'yellow' }}
        margin={{ left: chartWith < 720 ? -26 : 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          style={{ fontSize: chartWith * 0.021 }}
        />
        <YAxis style={{ fontSize: chartWith * 0.012 }} />
        <Tooltip />
        <Bar name="REAL" dataKey="value" fill="#666">
          {dataParam.map((dataLoop) => renderBarCell(dataLoop))}
        </Bar>
        <Line name="META" type="monotone" dataKey="goal" stroke="#000" strokeWidth={2.5} />
        <Line name="ANO ANTERIOR" type="monotone" dataKey="value_last_year" stroke="#4d0099" strokeWidth={2.5} />
      </ComposedChart>
    </Box>
  );
}
