/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Typography from '@mui/material/Typography';

export default function Header({ title }) {
  return (
    <Typography component="h1" variant="h4" align="center" sx={{ mb: 4 }}>
      {title}
    </Typography>
  );
}
