/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import IndicatorItem from '../IndicatorItem';

export default function IndicatorItems({ itemsIndicator }) {
  const navigate = useNavigate();

  return (
    itemsIndicator !== undefined ? (itemsIndicator.map(
      (indicatorLoop) => (
        <IndicatorItem
          itemIndicator={indicatorLoop}
          onAction={() => navigate(`/indicators/${indicatorLoop.id}/edit`, { replace: true })}
        />
      ),
    )) : null
  );
}
